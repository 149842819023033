/**
 * ProTIS Core owner-related requests
 *
 * Owner record includes:
 * id		                    int (omit if creating or unknown)
 * name		                    string (internal name)
 * title		                string (visible name)
 */
import EntityRequest from "./EntityRequest";

export default class OwnerRequest extends EntityRequest {
    constructor (coreRequest) {
        super(coreRequest, "owner", true);
    }
}