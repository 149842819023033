import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/ProjectsDefinitions.js';

const projectsView = constDefinitions.projectsView;

class Projects extends GenericScreen {
    constructor(props) {
        super(props, projectsView);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    changeProject = async () => {
        let selectedProjectID = this.state.selectedRowData[this.formGridDesignation(0)].id;
        this.core.setCurrentProjectId(selectedProjectID);

        this.setState({dialogOpen: false});
    };

    handleButtonClick = async (buttonName) => {
        switch (buttonName) {
            case "open":
                if (this.pageDefinitions[0].entityName === "project") {
                    this.openProjectPrompt();
                }
                break;
            default:
                await super.handleButtonClick(buttonName);
                break;
        }
    };

    openProjectPrompt = () => {
        this.promptActionTracker = "open_project";
        this.dialogTitle = "Change Project";
        this.dialogText = this.labelTranslation("Switch current project to ") + this.state.selectedRowData["project/list"].project_name + " ?";
        this.errorType = "warning";
        this.promptCancelOnly = false;

        this.setState({dialogOpen: true});
    };

    handleDialogSubmit = async (response) => {
        if (response === true) {
            switch (this.promptActionTracker) {
                case "open_project":
                    await this.changeProject();
                    break;
                default:
                    await super.handleDialogSubmit();
                    break;
            }
        }
    };

    screenSpecificSubmitInfo = () => {
        return {
            developer_id: this.core.userInfo.id,
        };
    };

    removeItemName() {
        return this.state.selectedRowData["project/list"].project_name;

    }

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default Projects;