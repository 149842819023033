import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/menuProject';

const document_type_data = constDefinitions.documentTypesView;

class DocumentTypeData extends GenericScreen {
    constructor(props) {
        super(props, document_type_data);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        return this.state.selectedRowData["document_type/list"].document_type_name;
    }

    screenSpecificSubmitInfo = () => {
        return {
            project_id: this.core.dbInfo.projectId,
        };
    };

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default DocumentTypeData;
