// Usage example
// let myConnectStr = "oracle://protis:wsxedc88@oracledb:1521/xe"
// let connect = parseConnectString(myConnectStr);
//
// alert(JSON.stringify(connect));

export default class ConnectString {

    static parseConnectString(connectStr) {
        const parts = connectStr.match(/^(oracle|mssql|mysql|postgresql):\/\/((\S+):(\S*)@)?([^\s:@/]+)(:\d+)?(\/\S+)?$/);
        const port = parts[6] ? parseInt(parts[6].substr(1)) : null;
        const dbname = parts[7] ? parts[7].substr(1) : null;
        return {
            dbtype: parts[1],
            username: parts[3],
            password_db: parts[4],
            dbhost: parts[5],
            port: port,
            dbname: dbname
        };
    }

}