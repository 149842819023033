import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/ProjectsDefinitions.js';

const projectInfoView = constDefinitions.projectInfoView;

class ProjectStatus extends GenericScreen {
    constructor(props) {
        super(props, projectInfoView);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }
    
    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default ProjectStatus;