/**
 * ProTIS Core project-related requests
 *
 * The record includes:
 * id		                    int (omit if creating or unknown)
 * project_name		            string
 * started		                dateTime
 * completed		            dateTime
 * developer_id		            int
 *
 * The developer_id is the ID of the user that created project
 */
import EntityRequest from "./EntityRequest";

export default class ProjectRequest extends EntityRequest {
    constructor (coreRequest) {
        super(coreRequest, "project", true);
    }
}