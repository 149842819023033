import React from 'react';
import {Menu, MenuDivider, MenuItem, SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

const tooltipStyle = {
    position: "absolute",
    pointerEvents: "none",
    backgroundColor: "#D7D7A6",
    border: "1px solid",
    padding: "1px 8px",
    whiteSpace: "nowrap",
    zIndex: 200,
};

class TopMenuDropdown extends React.Component {

    state = {
        pos: {x: 0, y: 0},
        tooltip: '',
        show: false,
    };

    createTooltip = (tooltip) => ({
        onMouseEnter: ({clientX, clientY}) => {
            this.setState(
                {
                    pos: {x: clientX, y: clientY},
                    tooltip: tooltip,
                    show: true,
                },
            );
        },
        onMouseLeave: () => {
            this.setState(
                {
                    show: false,
                },
            );
        },
    });

    handleSelect = ({value}) => {
        this.props.onClick(value);
    };

    renderMenuItem(menuAnchor, menuItems, isSubMenu) {
        if (isSubMenu) {
            return (
                <SubMenu key={menuAnchor.attributes.id}
                         label={
                             <div
                                 key={menuAnchor.attributes.title}
                                 {...this.createTooltip(menuAnchor.attributes.title)}
                             >
                                 {menuAnchor.value}
                             </div>
                         }
                >
                    {this.menuGeneration(menuItems, menuAnchor)}
                </SubMenu>
            );
        }

        return (
            <React.Fragment key={menuAnchor.attributes.id}>
                <Menu
                    style={{display: 'flex', float: 'left'}}
                    key={menuAnchor.attributes.title}
                    menuButton={
                        <button
                            style={{border: "none", margin: "2px 2px 2px 0"}}
                            key={menuAnchor.id}
                            {...this.createTooltip(menuAnchor.attributes.title)}>
                            {menuAnchor.value}
                        </button>
                    }
                    onChange={({open}) => !open && this.setState({show: false})}
                >

                    {this.menuGeneration(menuItems, menuAnchor)}
                </Menu>
                {this.state.show && (
                    <div
                        key={menuAnchor.id}
                        style={{
                            ...tooltipStyle,
                            left: this.state.pos.x,
                            top: this.state.pos.y,
                        }}
                    >
                        {this.state.tooltip}
                    </div>
                )}
            </React.Fragment>

        );
    }

    menuGeneration(menuItems, menuAnchor) {
        let counter = 0;

        if (menuItems === undefined) {
            return <React.Fragment key={menuAnchor.id}/>;
        }

        if (!Array.isArray(menuItems)) {
            menuItems = [menuItems];
        }

        return (
            menuItems.map(({a, _attributes, ul}) => {
                    if (ul !== undefined) {
                        return (
                            this.renderMenuItem(a, ul, true)
                        );
                    }

                    if (a === undefined) {

                        let keyGen = "Divider" + counter;
                        counter++;

                        return (
                            <MenuDivider key={keyGen}/>
                        );
                    }

                    return (
                        <MenuItem
                            key={a.attributes.id}
                            value={a.attributes.id}
                            onClick={(id) => this.handleSelect(id)}
                            {...this.createTooltip(a.attributes.title)}
                        >
                            {a.value}
                        </MenuItem>);

                },
            )
        );
    }

    render() {

        if (!this.props.topMenu.hasOwnProperty('div')) {
            return null;
        }

        const menuItemRendering = this.props.topMenu.div.ul.map(({a, ul}) => {
            return this.renderMenuItem(a, ul, false);
        });

        return (
            <div style={{display: 'flex', margin: "5px 0px 0px 5px"}}>
                {menuItemRendering}
            </div>
        );
    }
}

export default TopMenuDropdown;