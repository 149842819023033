import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/menuProject';

const node_data = constDefinitions.nodesView;

class NodeScreen extends GenericScreen {
    constructor(props) {
        super(props, node_data);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }


    removeItemName() {
        return this.state.selectedRowData["node/list"].node_name;
    }

    screenSpecificSubmitInfo = () => {
        return {
            project_id: this.core.dbInfo.projectId,
        };
    };

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: 250,
            gridWidth: "100%",
        };
    }
}

export default NodeScreen;