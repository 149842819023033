import EntityRequest from "../../ProTIS/EntityRequest";
import PrepareBarcode from "../PrepareBarcode";
import DocumentRequest from "../../ProTIS/DocumentRequest";

class GenericScreenController {
    constructor(core, topGridEntity, topGridEntityMethod, pageDefinitions) {

        this.selectedRowId = [];
        this.selectedRowData = [];

        this.entityData = [];

        this.core = core;
        this.topGridEntity = topGridEntity;
        this.topGridEntityMethod = topGridEntityMethod;
        this.pageDefinitions = pageDefinitions;
        this.topGridDesignation = topGridEntity + "/" + topGridEntityMethod;
    }

    formGridDesignation = (indexOfDefinitions) => {
        return indexOfDefinitions.entityName + "/" + indexOfDefinitions.entityMethod;
    };

    updateEntityData = (newEntityData) => {
        this.entityData = newEntityData;

    };

    updateEntityList = async (filterValue) => {
        let entityRequest = new EntityRequest(this.core, this.topGridEntity);
        let filter = filterValue ? filterValue : [];
        let newEntityData = await entityRequest.getList(filter, this.topGridEntityMethod);
        let updatedEntityData = {...this.entityData};
        updatedEntityData[this.topGridDesignation] = newEntityData ? newEntityData.records
            : console.error("Entity update list failed: Returned value is NULL");

        this.updateEntityData(updatedEntityData);
        return updatedEntityData;
    };

    removeEntityFromList = async (selectedRowId, filterValue) => {
        let updatedEntityData = {...this.entityData};
        let entityRequest = new EntityRequest(this.core, this.topGridEntity);
        await entityRequest.remove(selectedRowId);

        let filter = filterValue ? filterValue : [];
        let newEntityData = await entityRequest.getList(filter, this.topGridEntityMethod);

        updatedEntityData[this.topGridDesignation] = newEntityData ? newEntityData.records
            : console.error("Entity remove list update failed: Returned value is NULL");

        this.updateEntityData(updatedEntityData);
        return updatedEntityData;
    };

    bottomGridDataRequest = async (entityData) => {
        let entityRequest = new EntityRequest(this.core, this.pageDefinitions[1].entityName);
        let filter = {};
        filter[this.pageDefinitions[1].entityName + "_id"] = entityData.id;
        return entityRequest.getList(filter, this.pageDefinitions[1].entityMethod);
    };

    handleRowSelect = async (selectedRow, parentEntity) => {
        let entityRequest = new EntityRequest(this.core, parentEntity);
        let filter = {};
        filter[parentEntity + "_id"] = selectedRow.id;
        return entityRequest.getList(filter, this.pageDefinitions[1].entityMethod);
    };

    initialDataRequest = async (entityName, selectedRowId, entityMethod) => {
        let entityRequest = new EntityRequest(this.core, entityName);
        let filter = {};
        filter[entityName + "_id"] = selectedRowId;
        return entityRequest.getList(filter, entityMethod);
    };

    buttonDataRequest = async (selectedRowData, command, core) => {
        let requestedData;
        if (command === "view") {
            let dataRequest = new EntityRequest(this.core, this.topGridEntity);
            requestedData = await dataRequest.getList({id: selectedRowData.id});
        } else if (command === "barcode") {
            let barcodeRequest = selectedRowData.barcode;
            let newBarcode = new PrepareBarcode(core.dbInfo.projectId);
            requestedData = newBarcode.printBarcode(barcodeRequest);
        } else if (command === 'receive') {
            let receive = new DocumentRequest(this.core, this.topGridEntity);
            requestedData = await receive.receiveDocument(selectedRowData.id);
        } else if (command === 'dispatch') {
            let dispatch = new DocumentRequest(this.core, this.topGridEntity);
            requestedData = await dispatch.dispatchDocument(selectedRowData.id);
        } else if (command === 'return') {
            let returnReq = new DocumentRequest(this.core, this.topGridEntity);
            requestedData = await returnReq.returnDocument(selectedRowData.id);
        } else {
            console.error("Request Data for button request no found.", command);
        }
        return requestedData;
    };

}

export default GenericScreenController;