import React from "react";
import {Link} from "react-router-dom";
import homeButton from "../../ImageResources/protis/buttons/home.png";
import previousButton from "../../ImageResources/protis/buttons/prior.png";
import nextButton from "../../ImageResources/protis/buttons/next.png";
import submitButton from "../../ImageResources/protis/buttons/ok.png";
import addButton from "../../ImageResources/protis/buttons/add.png";
import removeButton from "../../ImageResources/protis/buttons/remove.png";
import ProjectRequest from "../../ProTIS/ProjectRequest";
import DataGrid_Function from "../Tables/DataGrid_Function";
import ScreenTranslation from "../../ProTIS/ScreenTranslation";
import MuiCustomPrompt from "../PopupAndComponents/MuiCustomPrompt";

const columns = [
    {field: 'project_name', headerName: 'Project Name', width: 200},
    {field: 'project_developer', headerName: 'Developer', width: 200},
    {field: 'role_name', headerName: 'Role', width: 100},
];

const gridColumns = [
    {label: "id", name: "id", width: 60, hidden: true, type: "hidden"},
    {label: "Project Name", name: "project_name", width: 200, type: "text", mandatory: true},
];

const projectView = {
    popupWindow: {
        title: "Project",
        height: 100,
        width: 500,
    },
    gridColumns: gridColumns,
};


class LoginProjectSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectID: this.props.selectedProject,
            popupOpen: false,
            projectEditState: null,
            projectData: this.props.projectData,
            currentTranslationCode: "en",
            open: false,
            openLayer2: false,
        };

        /** Generate Project list to begin with **/
        let projectExists = false;

        this.props.projectData.map((project) => {
            if (project.id === this.state.projectID) {
                projectExists = true;
            }
            return project;
        });

        /** Set initial selected row for Datagrid**/
        if (!projectExists && this.props.projectData.length > 0) {
            let firstProject = this.props.projectData[0];
            this.state.projectID = firstProject.id;
            this.state.projectRowData = firstProject;
        }
        this.core = props.core;

        this.translatedLabels = [];

        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
        this.requireInputField = false;
        this.dialogSubmittedInput = "";

        this.dialogTitleLayer2 = "";
        this.dialogTextLayer2 = "";
        this.errorTypeLayer2 = "";

        this.promptActionTracker = "";
    }

    translateLabel = (label) => {
        if (this.props.translationCode !== "en") {
            if (this.translatedLabels.hasOwnProperty(label)) {
                return this.translatedLabels[label];
            }
        }
        return label;
    };

    async componentDidMount() {
        await this.getTranslatedLabels();
    }

    async componentDidUpdate() {
        if (this.props.translationCode !== this.state.currentTranslationCode) {
            await this.getTranslatedLabels();
        }
    }

    async getTranslatedLabels() {
        this.translatedLabels = await ScreenTranslation.translateLoginScreenLabels(this.props.translationCode);
        this.setState({currentTranslationCode: this.props.translationCode});
    }

    returnToDatabase = () => {
        this.props.history.push("/login/database");
    };

    dataSubmitted = () => {
        this.core.setCurrentProjectId(this.state.projectID);
        this.props.history.push("/main/");
    };

    handleProjectSelect(selectedRow) {
        let newSelectedRow = selectedRow.row ? selectedRow.row : selectedRow;

        this.setState({
            projectID: newSelectedRow.id,
            projectRowData: newSelectedRow,
        });
        this.props.onChange(this.state.projectID);
    }

    generateProjectMenu() {
        return (
            <div style={{width: '650px'}}>
                <div style={{display: 'flex', width: '650px', marginBottom: '40px', paddingRight: '50px'}}>
                    <span style={{width: '10%'}}>{this.translateLabel("Project:")} </span>
                    <span style={{width: '90%'}}>
                        {this.generateTableOptions()}
                    </span>
                </div>
            </div>
        );

    }

    generateTableOptions() {

        /** Grid generation based on data from:
         * projectData: data from the database selected previously
         * displayColumns :
         *      field (internal name),
         *      headerName (custom name for internal name),
         *      width (width of the column for display)
         **/

        let usableColumns = columns;

        return (
            <div key={this.state.projectData.id} style={{height: 300, width: '100%'}}>
                <DataGrid_Function
                    sourceData={this.state.projectData}
                    displayColumns={usableColumns}
                    onRowClick={(selectedRow) => this.handleProjectSelect(selectedRow)}
                    hideFooter={true}
                    firstSelection={this.state.projectID}
                    headerHeight={30}
                    rowHeight={20}
                />
            </div>


        );

    }

    handleNewProject() {
        /** Prepare for new Project creation **/
        this.setState({
            popupOpen: true,
            projectEditState: 'newProject',
        });
    }


    /** Remove Project Section error here as well. **/

    async handleRemoveProject() {
        /** Project removal prompt **/
        const projectRequest = new ProjectRequest(this.core);
        await projectRequest.remove(this.state.projectID);
        let refresh = await projectRequest.getList();


        this.handleProjectSelect(refresh.records[0]);

        this.setState({
            open: false,
            projectData: refresh.records,
        });

        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
        this.requireInputField = false;
        this.dialogSubmittedInput = "";
    }

    handleCreateNewProject = async (response) => {

        const projectRequest = new ProjectRequest(this.core);
        const developerID = this.core.userInfo.id;

        let newRecord = {};
        newRecord.project_name = response;
        newRecord.developer_id = developerID;

        let requestError = null;
        let result = await projectRequest.create(newRecord,
            (error) => {
                requestError = error;
            },
        );

        if (result === null) {
            if (requestError === null) {
                requestError = "Invalid or empty input";
            }
            this.dialogTitleLayer2 = "Error";
            this.dialogTextLayer2 = requestError;
            this.errorTypeLayer2 = "failure";
            this.setState({openLayer2: true});
        } else if (result) {

            newRecord.id = result.result.new_record_id;

            this.handleProjectSelect(newRecord);

            const projectUpdate = new ProjectRequest(this.core);
            let newProjectData = await projectUpdate.getList();

            if (newProjectData) {
                this.setState({
                    open: false,
                    projectEditState: null,
                    projectData: newProjectData.records,
                });

                this.dialogTitle = "";
                this.dialogText = "";
                this.errorType = "";
                this.requireInputField = false;
                this.dialogSubmittedInput = "";
            } else {
                console.error("Project creation failed: Returned value is NULL");
            }
        }
    };

    generatePromptMessage = (type) => {
        this.promptActionTracker = type;
        switch (type) {
            case "remove_project":
                this.dialogTitle = "Remove Project";
                this.dialogText = "Do you want to delete selected project?";
                this.errorType = "warning";
                this.requireInputField = false;
                this.openPrompt();
                break;
            case "new_project":
                this.dialogTitle = "New Project";
                this.dialogText = "Name of new project:";
                this.errorType = "information";
                this.requireInputField = true;
                this.openPrompt();
                break;
            default:
                return null;
        }
    };

    handleDialogClose = (confirmation) => {
        this.setState({open: confirmation});

        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
        this.requireInputField = false;
        this.dialogSubmittedInput = "";
    };

    handleDialogSubmit = async (response) => {
        if (response === true) {
            if (this.promptActionTracker === "remove_project") {
                await this.handleRemoveProject();
            } else {
                return null;
            }
        } else if (response !== "") {
            if (this.promptActionTracker === "new_project") {
                await this.handleCreateNewProject(response);
            } else {
                return null;
            }
        } else {
            this.activateLayer2Error();
        }
    };

    openPrompt = () => {
        if (this.state.open === false) {
            this.setState({open: true});
        }
    };

    closePrompt = () => {
        if (this.state.open === true) {
            this.setState({open: false});
        }
    };

    activateLayer2Error = () => {
        this.dialogTitleLayer2 = "Error";
        this.dialogTextLayer2 = "Invalid or empty input";
        this.errorTypeLayer2 = "failure";
        this.setState({openLayer2: true});
    };

    handleDialogCloseLayer2 = () => {
        this.dialogTitleLayer2 = "";
        this.dialogTextLayer2 = "";
        this.errorTypeLayer2 = "";
        this.setState({openLayer2: false});
    };

    render() {

        const generateProjects = this.generateProjectMenu();

        const buttonStyling = {
            width: '50px',
            margin: '1%',
            height: '38px',
        };

        const mainElementStyling = {
            display: 'grid',
            gridTemplateColumns: '650px 1fr',
            gridGap: ' 20px 10px',
            height: '350px',
            marginLeft: "20px",
        };

        const navPosition = {
            display: 'flex',
            width: '700px',
            justifyContent: 'center',
        };

        const navButtons = {
            width: '100px',
            height: '34px',
            margin: '0 5px 0 5px',
        };

        const right2Buttons = {
            width: '55px',
            marginBottom: '40px',
            marginTop: '20px',
        };

        const spanSelectProjectStyle = {
            display: 'grid',
            textAlign: 'right',
            width: '200px',
            margin: '10px 0 10px 0',
        };

        const topDivStyle = {
            display: 'grid',
            gridTemplateRows: "1fr",
        };

        return (
            <div style={topDivStyle}>
                <span style={spanSelectProjectStyle}>
                    {this.translateLabel("Create or Select Project")}
                </span>
                <div style={mainElementStyling}>
                    <div style={{paddingRight: '20px', marginTop: '20px'}}>
                        {generateProjects}
                    </div>

                    <div style={right2Buttons}>
                        <button
                            style={buttonStyling}
                            onClick={() => this.generatePromptMessage("new_project")}
                        >
                            <img src={addButton} alt="add project"/>
                        </button>
                        <button
                            style={buttonStyling}
                            onClick={() => this.generatePromptMessage("remove_project")}
                        >
                            <img src={removeButton} alt="remove project"/>
                        </button>
                    </div>
                </div>

                <nav style={navPosition}>
                    <Link to="/login/user">
                        <button style={navButtons}>
                            <img src={homeButton} alt="Home Button"/>
                        </button>
                    </Link>
                    <button
                        style={navButtons}
                        onClick={this.returnToDatabase}
                    >
                        <img src={previousButton} alt="Previous Button"/>
                    </button>
                    <button
                        style={navButtons}
                        disabled={true}
                    >
                        <img src={nextButton} alt="Next Button"/>
                    </button>
                    <button
                        style={navButtons}
                        onClick={this.dataSubmitted}
                    >
                        <img src={submitButton} alt="Submit Button"/>
                    </button>
                </nav>

                <MuiCustomPrompt
                    open={this.state.open}
                    title={this.translateLabel(this.dialogTitle)}
                    displayedText={this.translateLabel(this.dialogText)}
                    buttonTheme={this.props.themeName}
                    cancelOnly={false}
                    showContextImage={this.errorType}
                    requireInputField={this.requireInputField}
                    inputObjectValue={this.dialogSubmittedInput}
                    onCancel={(confirmation) => this.handleDialogClose(confirmation)}
                    onSubmit={(response) => this.handleDialogSubmit(response)}
                />

                <MuiCustomPrompt
                    open={this.state.openLayer2}
                    title={this.translateLabel(this.dialogTitleLayer2)}
                    displayedText={this.translateLabel(this.dialogTextLayer2)}
                    buttonTheme={this.props.themeName}
                    cancelOnly={true}
                    showContextImage={this.errorTypeLayer2}
                    onCancel={(confirmation) => this.handleDialogCloseLayer2(confirmation)}
                />
            </div>
        );
    }
}

export default LoginProjectSelect;