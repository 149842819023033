/**
 * ProTIS Core entity requests
 */
import CoreRequest from "./CoreRequest";

export default class EntityRequest {

    /**
     * @brief   Constructor
     * @details May also accept a single parameter, that's then considered
     *          as entity.
     * @param coreRequest       Reference to Requester' Core
     * @param entity            Entity name, i.e. 'project'
     * @param noProjectCheck    Don't expect project login completed
     */
    constructor(coreRequest, entity, noProjectCheck) {
        if (entity === null) {
            this.core = CoreRequest.Instance;
            this.entity = coreRequest;
        } else {
            this.core = coreRequest;
            this.entity = entity;
        }

        this.core.noProjectCheck = noProjectCheck !== undefined;
    }

    /**
     * Return list of the objects of this entity available for the user
     * @param filter Optional filter
     * @param func   Optional function name, the default is "list"
     * @returns {Promise<null>}
     */
    async getList(filter, func = "list") {

        let objectData = null;
        let parameters = {};
        if (filter) {
            if (filter.hasOwnProperty("filter")) {
                parameters.filter = filter.filter;
            } else {
                parameters.filter = filter;
            }
        }

        // Users are special. The filter is ignored except for record id.
        if (this.entity === "user" && func === "list" && filter.hasOwnProperty("id")) {
            parameters.record_id = filter.id;
            delete parameters["filter"];
        }

        await this.core.send(false, this.entity + "/" + func, parameters,
            (jsonData) => {
                objectData = jsonData;
            },
            (error) => {
                this.handleRequestError(error);
                objectData = {};
            });

        if (!objectData.hasOwnProperty("records")) {
            objectData["records"] = [];
        }

        if (objectData.records.length > 0 && !objectData.records[0].hasOwnProperty("id")) {
            // If records don't have 'id' field, generate fake id
            let id = 1;
            for (let record of objectData.records) {
                record.id = id;
                id++;
            }
        }

        return objectData;
    }

    /**
     * Create new object
     *
     * New record must populate everything, but the id field.
     * @param recordData        Entity object data, i.e. project record
     * @param errorCallback     Optional errorCallback
     * @returns {Promise<null>}
     */
    async create(recordData, errorCallback) {
        let objectData = null;
        await this.core.send(false, this.entity + "/create", {record: recordData},
            (jsonData) => {
                objectData = jsonData;
            },
            errorCallback);
        return objectData;
    }

    /**
     * Update exiting object
     *
     * The record must populate everything, and the id field.
     * @param recordData        Object data, i.e. project record
     * @returns {Promise<null>}
     */
    async update(recordData) {
        let objectData = null;
        await this.core.send(false, this.entity + "/update", {record: recordData},
            (jsonData) => {
                objectData = jsonData;
            },
            null);
        return objectData;
    }

    /**
     * Remove exiting object
     * @param id                The id of the object to remove
     * @returns {Promise<null>}
     */
    async remove(id) {

        if (!this.core.noProjectCheck && !this.core.isLoggedIn()) {
            return null;
        }

        let objectData = null;
        let recordData = {record_id: id};
        await this.core.send(false, this.entity + "/remove", recordData,
            (jsonData) => {
                objectData = jsonData;
            },
            null);
        return objectData;
    }

    handleRequestError(error) {
        alert(error);
    }
}
