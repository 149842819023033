import {createTheme} from "@material-ui/core/styles";

export const protisTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#c5dbec',
            paper: '#c5dbec',
        },
    },
    overrides: {
        MuiDataGrid: {
            root: {
                backgroundColor: "#fff",
            },
        },
        MuiCheckbox: {
            colorPrimary: {
                color: "#3f51b5",
                '&$checked': {
                    color: '#3f51b5',
                },
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: "#2c9cf2",
                height: "20px",
                fontSizeAdjust: 0.4,
                "& .MuiTypography-h6": {
                    textAlign: "left",
                    lineHeight: "1px",
                },
            },
        },
    },
});

export const BlackNWhiteTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#ffffff',
            dark: '#ffffff',
        },
        secondary: {
            main: '#000000',
            light: '#000000',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#000000',
            secondary: '#000000',
            disabled: '#000000',
            hint: '#000000',
        },
        error: {
            main: '#000000',
            light: '#000000',
            dark: '#000000',
        },
        warning: {
            main: '#a4a4a4',
        },
        info: {
            main: '#a4a4a4',
        },
        success: {
            main: '#a4a4a4',
        },
    },
    overrides: {
        MuiCheckbox: {
            colorPrimary: {
                color: "#000",
                '&$checked': {
                    color: '#000',
                },
            },
        },
        MuiDataGrid: {
            root: {
                "& .MuiDataGrid-row": {
                    "&:hover": {
                        color: "#fff",
                        backgroundColor: "#000",
                    },
                    "&.Mui-selected": {
                        "&:hover": {
                            color: "#fff",
                            backgroundColor: "#000",
                        },
                        color: "#fff",
                        backgroundColor: "#000",
                    },
                },
                backgroundColor: "#fff",
            },
        },
    },
});
