import CoreConfig from "../ProTIS/CoreConfig";

class PrepareBarcode {
    constructor(projectId) {
        this.projectId = projectId;
    }

    // function
    createBarcode() {
        const dt = new Date();
        let pid = this.projectId;
        while (pid.length < 4)
            pid = "0" + pid;
        return pid + "-" + dt.getTime();
    }


// Creates window with image and prints it
//     function

    printImage(imageURL, horizontalAlign, verticalAlign, imageStyle) {
        if (!horizontalAlign) {
            horizontalAlign = "left";
        }

        if (!verticalAlign) {
            verticalAlign = "top";
        }

        let style = '';
        if (imageStyle) {
            style = 'style="' + imageStyle + '"';
        }

        const w = 800;
        const h = 600;
        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        const left = ((width / 2) - (w / 2));
        const top = ((height / 2) - (h / 2));

        let myWindow = window.open("", "BarcodeWindow", "width=" + w + ",height=" + h + ",left=" + left + ",top=" + top);
        myWindow.document.write('<html lang="en"><head><title></title>');
        myWindow.document.write('<script>function loadImage() { window.print(); window.close(); } </script>');
        myWindow.document.write('</head><body onload="setTimeout(function(){loadImage()},100);">');
        myWindow.document.write('</head><body>');
        myWindow.document.write('<table style="width: 100%; height: 100%; border: 0;"><tr><td align="' + horizontalAlign + '" valign="' + verticalAlign + '">');
        myWindow.document.write('<img src="' + imageURL + '" alt="Print Image" ' + style + '/>');
        myWindow.document.write('</td></tr></table>');
        myWindow.document.write('</body></html>');
        myWindow.document.close();
    }


    // Creates window with barcode and prints it
    printBarcode(barcode, horizontalAlign, verticalAlign) {
        this.printImage(CoreConfig.ServiceProtocol + "://" + CoreConfig.ServiceHost + "/request/create_barcode_image.php?code=" + barcode + "&type=png&showtext=1", horizontalAlign, verticalAlign, "width:7.0cm; height:1.0cm;");
    }

}

export default PrepareBarcode;