/**
 * ProTIS Main Menu related classes
 */
export default class MenuRequest {

    constructor(coreRequest) {
        this.core = coreRequest;
    }

    /**
     * Get main menu translation in selected destination language
     * @param destinationLanguage   Destination language 'en', 'ru', etc
     * @param translationEngine     Translation language, {Google, Bing}
     * @returns {Promise<null>}
     */
    async getTranslation(destinationLanguage="en", translationEngine="Google") {
        let requestData = {
            json_output: true,
            record: {
                src_language: "en",
                dst_language: destinationLanguage,
                engine: translationEngine,
                object_domain: "protis",
                object_class: "menu: main_menu"
            }
        };

        let menuData = null;
        await this.core.send(false, "menu_translation/list", requestData,
            (jsonData) => { menuData = jsonData; },
            null);
        return menuData;
    }
}