import React from 'react';
import DataCombobox from './Components/DataCombobox';
import CoreRequest from "./ProTIS/CoreRequest";
import CoreConfig from "./ProTIS/CoreConfig";
import LanguageRequest from "./ProTIS/LanguageRequest";
import MenuRequest from "./ProTIS/Menu";
import {Redirect, Route, Switch} from 'react-router-dom';
import LoginScreen from "./Components/LoginSequence/LoginScreen";
import MainScreen from "./Components/MainScreen/MainScreen";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {protisTheme, BlackNWhiteTheme} from "./Themes";

/** import {themes} from "./themes" **/


class App extends React.Component {
    state = {
        language: 'en',
        languageList: [],
        topMenuArray: {},
        authenticated: false,
        // selectedThemeName: "theme_protis",
        selectedThemeName: "theme_protis",
    };

    core = new CoreRequest(CoreConfig.ServiceURL);

    async login() {
        let result = await this.core.login("davidy", "qazwsx");
        if (!result.success) {
            /** If login failed **/
            this.setState({authenticated: false});
        } else {
            this.setState({authenticated: true});
        }
    }

    async getMenu(language) {
        if (!this.state.authenticated) {
            await this.login();
        }

        const menu = new MenuRequest(this.core);
        let menuData = await menu.getTranslation(language);
        return JSON.parse(menuData.menu);
    }

    async componentDidMount() {
        const languages = new LanguageRequest(this.core);
        let languagesData = await languages.getList();
        const menuData = await this.getMenu();
        this.setState({
            languageList: languagesData.records,
            topMenuArray: menuData,
        });
    }


    async handleLanguageChange(language_code) {
        const menuData = await this.getMenu(language_code);
        this.setState({
            language: language_code,
            topMenuArray: menuData,
        });
    }

    finalizeLogin = (data) => {
        this.setState({finalLoginData: data});
    };

    selectActiveTheme = (themeName) => {
        this.setState({selectedThemeName: themeName});
    };

    themeSelection = () => {
        if (this.state.selectedThemeName === "theme_protis") {
            return protisTheme;
        } else if (this.state.selectedThemeName === "theme_black_n_white") {
            return BlackNWhiteTheme;
        }
    };

    render() {
        const headerStyle = {
            display: 'grid',
            gridTemplateColumns: "1fr 300px",
            marginRight: "10px",
        };

        const languageComboStyle = {
            display: 'grid',
            alignItems: "right",
            height: "20px",
            marignRight: "10px",
        };

        const topDivStyle = {
            display: 'grid',
            height: "20px",
            gridTemplateRows: "100%",
        };

        return (
            <MuiThemeProvider theme={this.themeSelection()}>
                <CssBaseline>
                    <div style={topDivStyle}>
                        <div style={headerStyle}>
                            <div>
                                ProTIS REACT
                            </div>
                            <div style={languageComboStyle}>
                                <DataCombobox
                                    dropdownOptions={this.state.languageList}
                                    value={this.state.language}
                                    valueField="language_code"
                                    textField="language_full_name"
                                    onChange={(value) => this.handleLanguageChange(value.language_code)}
                                />
                            </div>
                        </div>

                        <Switch>
                            <Route path="/login" render={(props) => <LoginScreen
                                core={this.core}
                                languageSelectionCode={this.state.language}
                                {...props}
                                finalLoginData={(data) => this.finalizeLogin(data)}
                                themeName={this.state.selectedThemeName}
                            />}/>
                            <Route path="/main" render={(props) => <MainScreen
                                topMenuData={this.state.topMenuArray}
                                languageSelectionCode={this.state.language}
                                projectLoginData={this.state.finalLoginData}
                                core={this.core}
                                changeTheme={(themeName) => this.selectActiveTheme(themeName)}
                                {...props}
                            />}/>
                            <Redirect path="/" exact to="/login"/>
                            <Redirect path="/protis-react" exact to="/login"/>
                        </Switch>
                    </div>
                </CssBaseline>
            </MuiThemeProvider>
        );
    }
}

export default App;