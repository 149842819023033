import React from 'react';
import "./index.css";
import ReactDOM from 'react-dom';
import App from './App.js';
import {BrowserRouter} from "react-router-dom";
import DateAdapter from '@mui/lab/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';

ReactDOM.render(
    <LocalizationProvider dateAdapter={DateAdapter}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </LocalizationProvider>,
    document.getElementById('ReactRoot'),
);