import React from 'react';

import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import passRevealButton from "../../ImageResources/protis/buttons/icons8-eye-25.png";
import DataCombobox from "../DataCombobox";
import EntityRequest from "../../ProTIS/EntityRequest";
import DateTimePickerComponent from "./DateTimePickerComponent";
import LanguageRequest from "../../ProTIS/LanguageRequest";
import DocumentUploadDialog from "./DocumentUploadDialog";
import MuiCustomPrompt from "./MuiCustomPrompt";

class UniversalPopupComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            positionOf: '',
            popupDataContainer: [],
            datacomboList: [],
            dateTime: '',
            passwordVisible: [],
            popupDefinitions: [],
            defaultComboValue: [],
            fileUploaderOpen: false,
            passwordChanged: [],
            dialogOpen: false,
        };

        if (this.props.core) {
            this.core = props.core;
        }

        this.documentUploadData = "";
        this.documentExists = false;
        this.stateSnapshot = null;
        this.nullValues = false;
        this.passwordVisibilityReset = false;

        this.upload = {
            DocumentFileName: "",
            DocumentTitle: "",
            Presentation: "",
            DocumentLanguage: "",
            DocumentLanguageFullName: "",
            DocumentLanguageID: null,
        };

        this.state.finalRowDefinition = this.digestPopupDefinition(this.props.popupDefinition.gridColumns);

        /** Prepare containers for incoming data, to keep things within state containers **/
        let dataContainer = {};
        let passwordVisible = {};
        let passwordChanged = {};

        /** Check if popup window has a value, if not, assign default**/
        let popupSize = this.props.popupDefinition.popupWindow > 0 ? this.props.popupDefinition.popupWindow : {
            title: "",
            width: "500px",
            height: "400px",
        };

        /** Generate the state within the data container, using the Name, and an Empty field **/
        this.props.popupDefinition.gridColumns.map((data) => {

            if (data.type === "password") {
                passwordVisible[data.name] = false;
                passwordVisible[data.name + "_confirmation"] = false;
                passwordChanged[data.name] = false;
                dataContainer[data.name + "_confirmation"] = "";
            }
            if (data.type === "checkbox") {
                dataContainer[data.name] = null;
            } else {
                dataContainer[data.name] = '';
            }
            return null;
        });

        /** Finalize all initial state containers and item names with empty values **/
        this.state.passwordVisible = {...passwordVisible};
        this.state.popupDataContainer = {...dataContainer};
        this.state.popupSize = {...popupSize};
        this.state.passwordChanged = {...passwordChanged};

        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
    }

    labelTranslation = (label) => {
        if (this.props.translatedLabels && this.props.translatedLabels.hasOwnProperty(label)) {
            return this.props.translatedLabels[label];
        }
        return label;
    };

    handleChange = (event, password) => {
        /** Track input change **/
        const target = event.target;
        const fieldName = target.name;
        const value = target.value;
        let passwordChange = {...this.state.passwordChanged};

        if (password && this.state.passwordChanged[fieldName] !== true) {
            passwordChange[fieldName] = true;
        }

        let currentDataContainer = {...this.state.popupDataContainer};
        currentDataContainer[fieldName] = value;
        this.setState({
            popupDataContainer: currentDataContainer,
            passwordChanged: passwordChange,
        });
    };

    handleNumericChange = (event) => {
        /** Track filtered numeric Change, only allowing numbers to be input **/
        const target = event.target;
        const fieldName = target.name;
        let value = target.value;
        const filter = /^\d*$/;


        let currentDataContainer = {...this.state.popupDataContainer};

        if (!filter.test(event.target.value)) {
            value = currentDataContainer[fieldName];
        }

        currentDataContainer[fieldName] = value;
        this.setState({
            popupDataContainer: currentDataContainer,
        });
    };

    async componentDidMount() {
        await this.generateMountUpdateCombo();
    }

    async componentDidUpdate(prevProps, _prevState, _snapshot) {
        /** Check if popup is open, and if so, update popup with new data based
         * on whether the popup contains values, or is empty fields
         * **/
        if (prevProps.popupDefinitions !== this.props.popupDefinition) {
            this.state.finalRowDefinition = this.digestPopupDefinition(this.props.popupDefinition.gridColumns);
        }

        if (this.state.open !== this.props.open) {
            console.log("Null values:", this.nullValues);
            if (this.props.popupValues === null && this.nullValues === true) {
                console.log("Open Null");
                await this.generateMountUpdateCombo();
            }
            if (prevProps.popupDefinition.gridColumns !== this.props.popupDefinition.gridColumns) {
                await this.generateMountUpdateCombo();
            }
            if (this.passwordVisibilityReset === false) {
                this.resetPasswordVisibility();
            }


            this.setState({open: this.props.open});

            if (this.props.popupValues !== null) {
                this.setState({popupDataContainer: {...this.props.popupValues}});
            }
        }
    }

    /** Reset password visibility when called, for each item type of password in definition.
     *  */
    resetPasswordVisibility = (fullReset) => {
        let visibilityReset = {};
        let passwordChanged = {};
        for (let item of this.props.popupDefinition.gridColumns) {
            if (item.type === "password") {
                if (fullReset === true) {
                    passwordChanged[item.name] = false;
                }
                visibilityReset[item.name] = "password";
                visibilityReset[item.name + "_confirmation"] = "password";
            }
        }
        this.setState({
            passwordVisible: visibilityReset,
            passwordChanged: passwordChanged,
        });
        this.passwordVisibilityReset = true;
    };

    generateMountUpdateCombo = async () => {
        if (this.props.popupDefinition.gridColumns) {

            let updatedPopupValues = {...this.props.popupValues};
            let updatedComboList = {...this.state.datacomboList};

            for (let item of this.props.popupDefinition.gridColumns) {
                if (item.type === 'hidden') {
                    if (item.name === 'project_id') {
                        updatedPopupValues[item.name] = this.core.getCurrentProjectId();
                    }
                    if (item.name && item.defaultValue) {
                        updatedPopupValues[item.name] = item.defaultValue;
                    }
                }

                if (item.type === "number") {
                    updatedPopupValues[item.name] = item.default;
                }

                if (item.type === "datetime" && this.props.popupValues === null) {
                    updatedPopupValues[item.name] = new Date();
                }

                if (item.type === "datacombo") {
                    await this.getInitialComboBoxValues(item, updatedComboList, updatedPopupValues);
                    console.log("Datacombo update:", updatedPopupValues, updatedComboList);
                }

                if (item.type === "checkbox" && this.props.popupValues === null) {
                    updatedPopupValues[item.name] = null;
                }

                if (item.type === "text" && this.props.popupValues === null) {
                    updatedPopupValues[item.name] = '';
                }

                if (item.type === "password") {
                    updatedPopupValues[item.name + "_confirmation"] = "";
                }

                if (!updatedPopupValues.hasOwnProperty(item.name)) {
                    updatedPopupValues[item.name] = '';
                }
            }

            this.setState({
                datacomboList: updatedComboList,
                popupDataContainer: updatedPopupValues,
            });

            this.nullValues = this.props.popupValues !== null;
        }
    };

    async getInitialComboBoxValues(item, updatedComboList, updatedPopupValues) {
        if (item.definition && item.definition.entityName) {
            let entityRequest = item.definition.entityName;
            let list = await this.getNewComboList(entityRequest);
            updatedComboList[item.name] = list;

            if (this.props.popupValues === null) {
                if (item.definition && item.definition.entityName && item.definition.entityMethod) {
                    if (item.definition.entityName === "language" && item.definition.entityMethod === "list") {
                        updatedPopupValues[item.name] = list[0][item.definition.valueField];
                        updatedPopupValues[item.name + "_full_name"] = list[0][item.definition.labelField];
                    }
                }
                if (list[0]) {
                    if (list[0][item.definition.valueField]) {
                        console.log("Definition field");
                        updatedPopupValues[item.name] = list[0][item.definition.valueField];
                    } else if (list[0].value) {
                        console.log("Default value field");
                        updatedPopupValues[item.name] = list[0].value;
                    }
                }
                // updatedPopupValues[item.name] = list[0][item.definition.valueField]
                //     ? list[0][item.definition.valueField]
                //     : list[0].value;
            }
            console.log("List of combo:", list, updatedPopupValues[item.name], item.name);
        }

        if (item.definition && item.definition.values) {
            console.log("Values hit");
            updatedComboList[item.name] = item.definition.values;

            if (this.props.popupValues === null) {
                if (item.defaultValue) {
                    updatedPopupValues[item.name] = item.defaultValue;
                } else {
                    updatedPopupValues[item.name] = item.definition.values[0].value;
                }
            }

        }
    }

    digestPopupDefinition(inputDefinition) {
        let rowsDefinition = [];
        let tailedData = [];

        inputDefinition.map((inputData) => {
            if (inputData.tailed) {
                tailedData.push(inputData);
            } else if (tailedData.length > 0) {
                tailedData.push(inputData);
                rowsDefinition.push(tailedData);
                tailedData = [];
            } else {
                rowsDefinition.push([inputData]);
            }
            return null;

        });

        return rowsDefinition;
    }

    handleComboSelect(value, fieldName) {
        /** Combobox selection tracker **/


        this.stateSnapshot = {...this.state.popupDataContainer};

        let currentDataContainer = this.stateSnapshot;
        if (value) {
            if (value.language_code) {
                currentDataContainer[fieldName] = value.language_code;
                currentDataContainer[fieldName + "_full_name"] = value.language_full_name;
            } else if (value.id) {
                currentDataContainer[fieldName] = value.id;
            } else {
                console.log("value", value);
                currentDataContainer[fieldName] = value;
            }
        }


        this.setState({
            popupDataContainer: currentDataContainer,
        });
    }


    handleDateTimeSelection(time, fieldName) {
        let currentDataContainer = {...this.state.popupDataContainer};
        currentDataContainer[fieldName] = time;


        this.setState({
            popupDataContainer: currentDataContainer,
        });

    }

    handlePasswordVisibility = (dataName, _dataType) => {
        /** Set initial password visibility **/
        let currentPasswordStatus = {...this.state.passwordVisible};
        if (this.state.passwordVisible[dataName] === "password") {
            currentPasswordStatus[dataName] = "text";
        } else if (this.state.passwordVisible[dataName] === "text") {
            currentPasswordStatus[dataName] = "password";
        }

        this.setState({
            passwordVisible: currentPasswordStatus,
        });
    };

    handleSubmitInfo = (_event) => {
        /** Callback function for final submission, and the data passed to parent**/
        if (!this.validateData()) {
            return;
        }
        let confirmation = false;
        let containsPassword = false;

        for (let data of this.props.popupDefinition.gridColumns) {
            if (data.type === "password") {
                containsPassword = true;
                confirmation = this.comparePasswordFields(data.name);
                if (confirmation === false) {
                    this.dialogTitle = "Passwords Mismatch";
                    this.dialogText = "New password for " + data.label + " does not match";
                    this.errorType = "failure";
                    this.promptCancelOnly = true;

                    this.setState({dialogOpen: true});
                }
            }
        }

        if (confirmation === true) {
            this.catchOnSubmitError();
        } else if (confirmation === false && containsPassword === false) {
            this.catchOnSubmitError();
        } else {
            console.error("Unhandled Submission error.");
        }
    };

    catchOnSubmitError = () => {
        try {
            this.props.onSubmit(this.state.popupDataContainer);
        } catch (exception) {
            console.error("Exception in popup", exception);
        }
        this.nullValues = false;
    };

    comparePasswordFields = (passwordField) => {
        let passwordVisible = {...this.state.passwordVisible};
        let passwordChanged = {...this.state.passwordChanged};
        let finalizedDataContainer = {...this.state.popupDataContainer};

        if (this.state.passwordChanged[passwordField] === true) {
            if (this.state.popupDataContainer[passwordField]
                === this.state.popupDataContainer[passwordField + "_confirmation"]) {

                passwordVisible[passwordField + "_confirmation"] = false;
                passwordChanged[passwordField] = false;
                delete finalizedDataContainer[passwordField + "_confirmation"];

                this.setState({
                    passwordChanged: passwordChanged,
                    passwordVisible: passwordVisible,
                    popupDataContainer: finalizedDataContainer,
                });
                return true;
            } else {
                return false;
            }
        } else if (this.state.passwordChanged[passwordField] === false) {
            return true;
        }
    };

    handleDialogClose = (confirmation) => {
        this.setState({dialogOpen: confirmation});

        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
    };

    handleClickClose = () => {
        this.resetPasswordVisibility(true);
        this.nullValues = false;
        this.passwordVisibilityReset = false;
        this.props.onCancel(false);
    };

    validateData() {
        return true;
    }

    getNewComboList = async (entityName) => {
        let newComboList;
        let list;
        if (entityName === "language") {
            newComboList = new LanguageRequest(this.core);
            list = await newComboList.getList();
        } else {
            newComboList = new EntityRequest(this.core, entityName);
            list = await newComboList.getList({});
        }
        return list.records;
    };


    /** Checkbox field generation.
     * @item - provided definition item that matches 'checkbox' type.
     * @style - used to set grid pattern consisten with rest of popup
     * */
    createCheckboxField = (item, style) => {
        let fieldValue = this.state.popupDataContainer[item.name]
            ? this.state.popupDataContainer[item.name] : false;

        //TMP solution for disabling study checkbox. Always false, unless old record with true
        let disabled = item.disabled ? item.disabled : false;

        return (
            <div style={style} key={item.name}>
                {item.label}
                <div>
                    <Checkbox
                        disabled={disabled}
                        checked={fieldValue}
                        onChange={(event) => this.handleCheckboxState(event, item.name)}
                        color="primary"
                    />
                </div>
            </div>);
    };


    /** Check box handler for the MUI Checkbox.
     * @itemField = field name of individual checkbox to set state for.
     * */
    handleCheckboxState = (event, itemField) => {
        let field = this.state.popupDataContainer[itemField];
        let state = event.target.checked;
        let updatedDataContainer = {...this.state.popupDataContainer};
        if (state !== field) {
            updatedDataContainer[itemField] = state;
            this.setState({
                popupDataContainer: updatedDataContainer,
            });

        }
    };

    createPopupTextField = (item, style) => {
        let fieldValue = this.state.popupDataContainer[item.name];
        if (fieldValue === undefined) {
            fieldValue = '';
        }
        return (
            <div style={style} key={item.name}>
                {this.labelTranslation(item.label)}
                <div style={{display: "flex", alignItems: "center"}}>
                    <input
                        name={item.name}
                        type={item.type}
                        placeholder={this.labelTranslation(item.label)}
                        onChange={this.handleChange}
                        value={fieldValue}
                        style={{width: item.width}}
                    />
                </div>
            </div>
        );
    };

    createPopupPasswordField = (item, style) => {
        let passwordField = item.name;
        let confirmationField = passwordField + "_confirmation";
        let fieldValue = this.state.popupDataContainer[passwordField];
        let confirmationValue = this.state.popupDataContainer[confirmationField];
        if (fieldValue === undefined) {
            fieldValue = '';
        }

        return (
            <div key={item.name}>
                <div style={style}>
                    {this.labelTranslation(item.label)}
                    <div>
                    <span>
                        <input
                            name={passwordField}
                            type={this.state.passwordVisible[item.name]}
                            placeholder={this.labelTranslation(item.label)}
                            onChange={(event) => this.handleChange(event, true)}
                            value={fieldValue}
                            style={{width: item.width}}
                        />
                        <img
                            onClick={() => this.handlePasswordVisibility(item.name)}
                            style={{width: "24px", height: "24px"}}
                            align={'top'}
                            src={passRevealButton} alt="Reveal"/>
                    </span>
                    </div>
                </div>
                <div style={style}>
                    &nbsp;
                    <div hidden={!this.state.passwordChanged[passwordField]}>
                    <span>
                        <input
                            name={confirmationField}
                            type={this.state.passwordVisible[confirmationField]}
                            onChange={this.handleChange}
                            value={confirmationValue}
                            style={{width: item.width}}
                        />
                        <img
                            onClick={() => this.handlePasswordVisibility(confirmationField)}
                            style={{width: "24px", height: "24px"}}
                            align={'top'}
                            src={passRevealButton} alt="Reveal"/>
                    </span>
                    </div>
                </div>
            </div>
        );
    };


    createPopupPortField = (item, style) => {
        let fieldValue = this.state.popupDataContainer[item.name];
        if (fieldValue === undefined) {
            fieldValue = '';
        }
        return (
            <div style={style} key={item.name}>
                {this.labelTranslation(item.label)}
                <div style={{display: "flex", alignItems: "center"}}>
                    <input
                        name={item.name}
                        type="number"
                        placeholder={this.labelTranslation(item.label)}
                        onChange={this.handleNumericChange}
                        value={fieldValue}
                        style={{width: item.width}}
                    />
                </div>
            </div>
        );
    };


    /** Numeric input, will most likely replace port input in future.
     * @item - information about definition matching "number" type
     * @style - provided styling for popup grid.
     * */
    createNumericInput = (item, style) => {
        let fieldValue = this.state.popupDataContainer[item.name];
        if (fieldValue === undefined) {
            fieldValue = "";
        }
        return (
            <div style={style} key={item.name}>
                {this.labelTranslation(item.label)}
                <div style={{display: "flex", alignItems: "center"}}>
                    <input
                        name={item.name}
                        type="number"
                        placeholder={this.labelTranslation(item.label)}
                        onChange={this.handleNumericChange}
                        value={fieldValue}
                        style={{width: item.width}}
                    />
                </div>
            </div>
        );

    };

    createPopupDataComboBox = (data, style, itemLabel, itemName) => {
        let comboBoxValue = this.state.popupDataContainer[itemName];
        let displayName = data.textField ? data.textField : data.labelField;
        return (
            <div style={style} key={itemName}>
                {itemLabel}
                <div style={{display: "flex", alignItems: "center"}}>
                    <DataCombobox
                        dropdownOptions={data.items}
                        value={comboBoxValue}
                        valueField={data.valueField}
                        textField={displayName}
                        onChange={(value) => this.handleComboSelect(value, itemName)}
                    />
                </div>
            </div>
        );
    };

    createEntityPopupDataComboBox = (data, style, comboBoxValue, valueField, labelField, dropdownOptions) => {


        let datacomboOptions = dropdownOptions ? dropdownOptions : this.state.datacomboList[data.name];
        let comboValue = comboBoxValue !== "" ? comboBoxValue : data.defaultValue;

        return (
            <div style={style} key={data.name}>
                {this.labelTranslation(data.label)}
                <div style={{display: "flex", alignItems: "center"}}>
                    <DataCombobox
                        dropdownOptions={datacomboOptions}
                        value={comboValue}
                        valueField={valueField}
                        textField={labelField}
                        onChange={(value) => this.handleComboSelect(value, data.name)}
                    />
                </div>
            </div>
        );
    };

    createDateTimePicker = (data, style, dateTimeValue) => {
        return (
            <div style={style} key={data.name}>
                {this.labelTranslation(data.label)}
                <div>
                    <DateTimePickerComponent
                        initialValue={dateTimeValue}
                        label={this.labelTranslation(data.label)}
                        onChange={(date) => this.handleDateTimeSelection(date, data.name)}
                    />
                </div>
            </div>
        );
    };

    getFormatFileType = () => {
        switch (this.state.popupDataContainer.document_format_id) {
            case 2:
                return "Text";
            case 3:
                return "PDF";
            case 4:
                return "PNG";
            case 5:
                return "JPEG";
            case 1:
            default:
                return null;
        }
    };

    handleFileUploaderOpenState = (action) => {
        if (action === "open") {
            this.setState({
                fileUploaderOpen: true,
            });
        } else if (action === "close") {
            this.setState({
                fileUploaderOpen: false,
            });
        }
    };

    submitUploadedDocument = (data) => {
        this.upload = {
            DocumentFileName: data.document_filename,
            DocumentTitle: data.document_title,
            Presentation: data.presentation,
            DocumentLanguage: data.source_language,
            DocumentLanguageFullName: data.source_language_full_name,
            DocumentLanguageID: data.source_language_id,
        };

        let newUploadContainer = {...this.state.popupDataContainer};
        newUploadContainer.document_filename = this.upload.DocumentFileName;
        newUploadContainer.document_data = this.upload.Presentation;
        newUploadContainer.document_language_id = this.upload.DocumentLanguageID;
        newUploadContainer.document_language_code = this.upload.DocumentLanguage;
        newUploadContainer.document_language_full_name = this.upload.DocumentLanguageFullName;

        this.setState({
            popupDataContainer: newUploadContainer,
            fileUploaderOpen: false,
        });

    };

    createFileUploader = (data, style) => {

        let fileType;
        if (this.state.popupDataContainer.document_format_id && this.state.popupDataContainer.document_format_id !== 1) {
            fileType = this.getFormatFileType() + "        ";
        }

        let fallbackFilename = this.state.popupDataContainer.document_filename ? this.state.popupDataContainer.document_filename
            : "";
        let filename = this.upload.DocumentFileName ? this.upload.DocumentFileName
            : fallbackFilename;


        return (
            <div style={style} key={data.name}>
                {this.labelTranslation(data.label)}
                <div>
                    {fileType}
                    <button onClick={() => this.handleFileUploaderOpenState("open")}> Open Document Uploader</button>
                    {filename}
                    <DocumentUploadDialog
                        open={this.state.fileUploaderOpen}
                        buttonTheme={this.props.buttonTheme}
                        onCancel={() => this.handleFileUploaderOpenState("close")}
                        onSubmit={(incomingData) => this.submitUploadedDocument(incomingData)}
                        translatedLabels={this.props.translatedLabels}
                        existingTitle={""}
                        uiLanguage={this.props.languageCode}
                        document_language={null}
                    />

                </div>
            </div>
        );
    };


    createPopupFields = () => {
        /** Initiate generation and population of fields to display within the popup
         *  based on what type of data is passed in from parent
         *  **/

        if (this.props.popupDefinition.length === 0) {
            return null;
        }
        const popupDivStyling = {
            display: 'grid',
            gridTemplateColumns: "120px 1fr",
            gridGap: "10px 10px",
            margin: "5px 0px",
        };


        let counter = 0;

        return (
            this.state.finalRowDefinition.map((row) => {
                let innerCounter = 0;
                let rowElements = row.map((data) => {
                    if (data.showInPopup === false) {
                        return null;
                    } else {

                        if (data.hidden === true) {
                            if (data.name === "project_id") {
                                this.createPopupTextField(data, popupDivStyling);
                            } else {
                                return null;
                            }
                        }

                        if (data.scope === "view") {
                            return null;
                        }

                        innerCounter++;
                        switch (data.type) {
                            case "file":

                                if (this.state.popupDataContainer[data.name] &&
                                    this.state.popupDataContainer[data.name].length > 0) {
                                    this.documentExists = true;
                                }
                                return this.createFileUploader(data, popupDivStyling);
                            case "text":
                                return this.createPopupTextField(data, popupDivStyling);
                            case "password":
                                return this.createPopupPasswordField(data, popupDivStyling);
                            case "port":
                                return this.createPopupPortField(data, popupDivStyling);
                            case "number":
                                return this.createNumericInput(data, popupDivStyling);
                            case "datacombo":
                                let datacombo = this.dataComboGeneration(data, popupDivStyling);
                                if (datacombo) {
                                    return datacombo;
                                } else {
                                    console.error("Generated datacombo returned a null value");
                                    return null;
                                }
                            case "datetime":
                                let dateTimeValue;
                                if (this.props.popupValues === null) {
                                    dateTimeValue = new Date();
                                } else {
                                    dateTimeValue = this.state.popupDataContainer[data.name];
                                }
                                return this.createDateTimePicker(data, popupDivStyling, dateTimeValue);
                            case "checkbox":
                                return this.createCheckboxField(
                                    data, popupDivStyling,
                                );
                            default:
                                innerCounter--;
                                return null;
                        }

                    }
                });
                counter++;

                return (
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: "repeat(" + innerCounter + ",1fr)",
                        gridGap: '5px',
                    }} key={counter}>
                        {rowElements}
                    </div>
                );
            })
        );
    };

    dataComboGeneration = (data, popupDivStyling) => {
        let popupContainerComboBoxValue = this.state.popupDataContainer[data.name];
        let defaultValue = data.defaultValue ? data.defaultValue : this.state.defaultComboValue[data.name];
        let comboBoxValue =
            popupContainerComboBoxValue !== '' ? popupContainerComboBoxValue : defaultValue;

        let itemLabel = data.label;
        let itemName = data.name;
        if (data.items && data.items.length > 0) {
            return this.createPopupDataComboBox(
                data, popupDivStyling, itemLabel, itemName);

        } else if (data.definition && data.definition.items && data.definition.items.length > 0) {
            return this.createPopupDataComboBox(
                data.definition, popupDivStyling, itemLabel, itemName);

        } else if (data.definition && data.definition.entityName) {
            return this.createEntityPopupDataComboBox(
                data, popupDivStyling, comboBoxValue,
                data.definition.valueField, data.definition.labelField);

        } else if (data.definition && data.definition.values) {

            let dropdownOptions = data.definition.values;
            let textField = "label";
            let valueField = "value";
            return this.createEntityPopupDataComboBox(
                data, popupDivStyling, comboBoxValue,
                valueField, textField, dropdownOptions,
            );
        } else {
            return null;
        }
    };


    render() {
        let buttonTheme;
        if (this.props.buttonTheme) {
            if (this.props.buttonTheme === "theme_protis") {
                buttonTheme = 'protis';
            } else if (this.props.buttonTheme === "theme_black_n_white") {
                buttonTheme = "black-n-white";
            }
        } else {
            buttonTheme = 'protis';
        }

        const gridData = this.createPopupFields();

        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClickClose}
            >
                <DialogTitle>
                    {this.labelTranslation(this.props.popupDefinition.popupWindow.title)}
                </DialogTitle>
                <DialogContent style={{
                    height: this.props.popupDefinition.popupWindow.height,
                    width: this.props.popupDefinition.popupWindow.width,
                }}
                >
                    {gridData}
                </DialogContent>
                <DialogActions>
                    <button
                        onClick={this.handleClickClose}
                    >
                        <img
                            src={require(`../../ImageResources/${buttonTheme}/dialog_buttons/cancel.png`).default}
                            alt="Close"
                        />
                    </button>
                    <button
                        onClick={this.handleSubmitInfo}
                    >
                        <img
                            src={require(`../../ImageResources/${buttonTheme}/dialog_buttons/ok.png`).default}
                            alt="Close"
                        />
                    </button>
                </DialogActions>
                <MuiCustomPrompt
                    open={this.state.dialogOpen}
                    title={this.labelTranslation(this.dialogTitle)}
                    displayedText={this.labelTranslation(this.dialogText)}
                    buttonTheme={this.props.themeName}
                    cancelOnly={this.promptCancelOnly}
                    showContextImage={this.errorType}
                    onCancel={(confirmation) => this.handleDialogClose(confirmation)}
                />
            </Dialog>

        );
    }

}

export default UniversalPopupComponent;
