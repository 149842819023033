import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/menuProject';


const client_data = constDefinitions.clientsView;

class ClientData extends GenericScreen {
    constructor(props) {
        super(props, client_data);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        return this.state.selectedRowData["client/list"].client_name;
    }

    myPopupSubmitted = async (popupFieldData) => {
        let currentDate = new Date();
        if (popupFieldData.registered === "") {
            popupFieldData.registered = currentDate.toISOString();
        }
        await this.myPopupSubmittedHandler(popupFieldData);
    };

    screenSpecificSubmitInfo = () => {
        return {
            project_id: this.core.dbInfo.projectId,
        };
    };

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default ClientData;
