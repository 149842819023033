import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/menuProject';

const route_data = constDefinitions.routesView;

class RoutesView extends GenericScreen {
    constructor(props) {
        super(props, route_data);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        let item1 = this.state.selectedRowData["route/list"].route_number;
        let item2 = this.state.selectedRowData["route/list"].node_name;

        return "Node " + item1 + " and Order " + item2;
    }

    screenSpecificSubmitInfo = () => {
        if (this.state.filterValue && this.state.filterValue.document_type_id) {
            return {document_type_id: this.state.filterValue.document_type_id};
        } else {
            return {};
        }

    };

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default RoutesView;
