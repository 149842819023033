// Auto-generated file, please don't modify

export const projectInfoView = [
    {
        buttons: [],
        filters: [],
        title: "Project Information:",
        entityName: "system_info",
        entityMethod: "project_summary",
        viewColumns: [
            {label: "parameter", name: "parameter", width: 250, type: "text"},
            {label: "information", name: "value", width: 150, type: "text"},
        ],
    },
];

export const projectsView = [
    {
        buttons: ["Add", "Edit", "Remove", "Open", "Refresh"],
        filters: [],
        title: "Projects:",
        entityName: "project",
        entityMethod: "list",
        viewColumns: [
            {label: "id", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "project name",
                name: "project_name",
                width: 200,
                type: "text",
                mandatory: true,
            },
            {
                label: "project developer",
                name: "project_developer",
                width: 120,
                type: "text",
                mandatory: true,
                showInPopup: false,
            },
            {
                label: "developer id",
                name: "developer_id",
                width: 120,
                type: "text",
                mandatory: true,
                showInPopup: false,
            },
            {label: "your role", name: "role_name", width: 100, type: "text", mandatory: true, showInPopup: false},
        ],
    },
];
