import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import LoginUserPass from "./loginUserPass";
import LoginDatabaseSelect from "./loginDatabaseSelect";
import LoginProjectSelect from "./loginProjectSelect";
import OwnerRequest from "../../ProTIS/OwnerRequest";


class LoginScreen extends React.Component {

    state = {
        ownerArray: [],
        databaseID: '',
        databaseProjects: [],
        projectID: '',
    };


    constructor(props) {
        super(props);
        this.handleUpdateOwner = this.handleUpdateOwner.bind(this);
    }

    handleDatabaseSelect(value) {
        this.setState({
            databaseID: value,
        });
    }

    handleProjectSelect(value) {
        this.setState({
            projectID: value,
        });
        this.props.finalLoginData(this.state.projectID);
    }

    async handleUpdateOwner() {
        const ownerRequest = new OwnerRequest(this.props.core);
        let resultOwner = await ownerRequest.getList();

        this.setState({ownerArray: resultOwner.records});
    }

    render() {
        const placeholder = {height: "22px", paddingTop: '5px'};

        const topDivStyle = {
            display: 'grid',
            gridTemplateRows: "1fr",
        };

        return (
            <div style={topDivStyle}>
                <div style={placeholder}/>
                <Route
                    path="/login/user"
                    render={(props) =>
                        <LoginUserPass
                            translationCode={this.props.languageSelectionCode}
                            core={this.props.core}
                            onUserAuth={(owners) => {
                                this.setState({ownerArray: owners});
                            }}
                            themeName={this.props.themeName}
                            {...props}
                        />
                    }
                />
                <Route path="/login/database"
                       render={(props) =>
                           <LoginDatabaseSelect
                               translationCode={this.props.languageSelectionCode}
                               ownerData={this.state.ownerArray}
                               onUpdateOwner={() => this.handleUpdateOwner()}
                               core={this.props.core} {...props}
                               onChange={(value) => this.handleDatabaseSelect(value)}
                               onProjectDataReceived={(projects) => {
                                   this.setState({databaseProjects: projects});
                               }}
                               selectedDatabase={this.state.databaseID}
                               themeName={this.props.themeName}
                           />
                       }
                />
                <Route path="/login/project"
                       render={(props) =>
                           <LoginProjectSelect
                               translationCode={this.props.languageSelectionCode}
                               projectData={this.state.databaseProjects}
                               core={this.props.core} {...props}
                               onChange={(value) => this.handleProjectSelect(value)}
                               selectedProject={this.state.projectID}
                               themeName={this.props.themeName}
                           />}
                />

                <Redirect path="/login" exact to="/login/user"/>
            </div>
        );
    }
}

export default LoginScreen;