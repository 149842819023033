import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DataCombobox from "../DataCombobox";
import LanguageRequest from "../../ProTIS/LanguageRequest";
import CoreRequest from "../../ProTIS/CoreRequest";

/** Props used:
 *  open - Regulates open state
 *  buttonTheme - theme regulator for image buttons
 *  onCancel - callback to close popup
 *  onConfirm - callback for confirmation
 *  translatedLabels - pass-through for translations
 *  existingTitle - If Document has a title already and is not null
 *  uiLanguage - used to set initial language value of interface, if no language present
 *  document_language - if a language is already set for document, check and use instead of UI as default.
 *
 * */

class DocumentUploadDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document_title: "",
            documentDataContainer: [
                {document_title: ""},
                {source_language: ""},
                {source_language_full_name: ""},
                {source_language_id: null},
            ],
            dataComboList: [],
        };
        this.core = CoreRequest.Instance;
        this.stateSnapshot = null;

        this.layoutStyling = {
            display: 'grid',
            gridTemplateColumns: "120px 1fr",
            gridGap: "10px 10px",
            margin: "5px 0px",
        };
    }

    componentDidMount = async () => {
        await this.getInitialValues();
    };

    labelTranslation = (label) => {

        if (this.props.translatedLabels && this.props.translatedLabels.hasOwnProperty(label)) {
            return this.props.translatedLabels[label];
        } else if (this.translatedLabels && this.translatedLabels.hasOwnProperty(label)) {
            return this.translatedLabels[label];
        }

        return label;
    };


    getNewComboList = async () => {
        let newComboList = new LanguageRequest(this.core);
        let list = await newComboList.getList();
        return list.records;
    };

    getInitialValues = async () => {

        let comboList = await this.getNewComboList("language");
        let initialFieldValues = [];
        let title = this.props.existingTitle !== null ? this.props.existingTitle : "";

        if (this.props.uiLanguage && this.props.document_language === null) {
            for (let initialLang of comboList) {
                if (initialLang.language_code === this.props.uiLanguage) {
                    initialFieldValues = {
                        source_language: initialLang.language_code,
                        source_language_full_name: initialLang.language_code_full_name,
                        document_title: title,
                    };
                    break;
                }
            }
        } else if (this.props.document_language) {
            for (let initialLang of comboList) {
                if (initialLang.id === this.props.document_language) {
                    initialFieldValues = {
                        source_language: initialLang.language_code,
                        source_language_full_name: initialLang.language_code_full_name,
                        document_title: title,
                    };
                    break;
                }
            }
        }

        this.setState({
            dataComboList: comboList,
            documentDataContainer: initialFieldValues,
        });
    };


    handleClickCancel = () => {
        this.props.onCancel(false);
    };

    handleClickSubmit = () => {
        this.props.onSubmit(this.state.documentDataContainer);
    };

    renderConfirmationButtons = (buttonTheme) => {
        return (
            <div>
                <button key={"ok"} style={this.props.buttonStyle} onClick={this.handleClickSubmit}>
                    <img
                        src={require(`../../ImageResources/${buttonTheme}/dialog_buttons/ok.png`).default}
                        alt={"ok"}
                    />
                </button>
                <button key={"cancel"} style={this.props.buttonStyle} onClick={this.handleClickCancel}>
                    <img
                        src={require(`../../ImageResources/${buttonTheme}/dialog_buttons/cancel.png`).default}
                        alt="Close"
                    />
                </button>
            </div>
        );
    };

    documentTitleInput = () => {
        return (
            <div style={this.layoutStyling} key={"doc_title"}>
                {this.labelTranslation("Document Title")}
                <div style={{display: "flex", alignItems: "center"}}>
                    <input
                        name={"document_title"}
                        type={"text"}
                        onChange={this.handleChange}
                        value={this.state.documentDataContainer["document_title"]}
                        style={{width: "100%"}}
                    />
                </div>
            </div>
        );
    };

    handleChange = (event) => {
        /** Track input change **/
        const target = event.target;
        const fieldName = target.name;
        const value = target.value;

        let currentDataContainer = {...this.state.documentDataContainer};
        currentDataContainer[fieldName] = value;
        this.setState({
            documentDataContainer: currentDataContainer,
        });
    };

    handleComboSelect(value, fieldName) {
        /** Combobox selection tracker **/


        if (this.stateSnapshot === null) {
            this.stateSnapshot = {...this.state.documentDataContainer};
        }
        let currentDataContainer = this.stateSnapshot;
        currentDataContainer[fieldName] = value.language_code;
        currentDataContainer[fieldName + "_full_name"] = value.language_full_name;
        currentDataContainer[fieldName + "_id"] = value.id;

        this.setState({
            documentDataContainer: currentDataContainer,
        });

    }

    generateLanguageSelectDataCombo = () => {
        let comboValue = this.state.documentDataContainer["source_language"];

        return (
            <div style={this.layoutStyling} key="source_language">
                {this.labelTranslation("Language")}
                <div style={{display: "flex", alignItems: "center"}}>
                    <DataCombobox
                        dropdownOptions={this.state.dataComboList}
                        value={comboValue}
                        valueField="language_code"
                        textField="language_full_name"
                        onChange={(value) => this.handleComboSelect(value, "source_language")}
                    />
                </div>
            </div>
        );
    };

    createFileUploader = () => {
        return (
            <div style={this.layoutStyling}>
                {this.labelTranslation("Document")}
                <div>
                    <input
                        style={this.layoutStyling}
                        name={"presentation_uploader"}
                        type={"file"}
                        onChange={this.onFileChange}
                    />
                </div>
            </div>
        );
    };

    onFileChange = event => {
        const self = this;
        const file = event.target.files[0];
        let reader = new FileReader();
        let targetName = event.target.name.replace("_uploader", "");

        reader.onload = (_theFile => {
            return function (e) {
                let newDataContainer = {...self.state.documentDataContainer};
                newDataContainer[targetName] = e.target.result;

                newDataContainer.document_filename = file.name;
                /** Get file name from above section, into a temporary state to use in case no title present.         *
                 */

                self.setState({documentDataContainer: newDataContainer});
            };
        })(file);

        /** Read in the image file as a data URL. */
        reader.readAsDataURL(file);

    };


    render() {
        let buttonTheme;
        if (this.props.buttonTheme) {
            if (this.props.buttonTheme === "theme_protis") {
                buttonTheme = 'protis';
            } else if (this.props.buttonTheme === "theme_black_n_white") {
                buttonTheme = "black-n-white";
            }
        } else {
            buttonTheme = 'protis';
        }
        let title = this.props.title ? this.props.title : "Select document and language";

        return (
            <div>
                <Dialog
                    open={this.props.open}
                >
                    <DialogTitle>
                        {this.labelTranslation(title)}
                    </DialogTitle>
                    <DialogContent
                        style={{
                            minHeight: "100px",
                            minWidth: "200px",
                        }}
                    >
                        {this.documentTitleInput()}
                        {this.generateLanguageSelectDataCombo()}
                        {this.createFileUploader()}
                    </DialogContent>
                    <DialogActions>
                        {this.renderConfirmationButtons(buttonTheme)}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default DocumentUploadDialog;