import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import TopMenuDropdown from "../TopMenuDropdown";
import NodeScreen from "./Project/NodeScreen";
import ProjectUserScreen from "./Project/ProjectUserScreen";
import DocumentTypeData from "./Project/DocumentTypeData";
import ClientData from "./Project/ClientData";
import RoleData from "./Project/RoleData";
import UserActionData from "./Project/UserActionData";
import DocumentSetData from "./Project/DocumentSetData";
import RoutesView from "./Project/RoutesView";
import AllDocuments from "./Documents/AllDocuments";
import NodeDocuments from "./Documents/NodeDocuments";
import ClientDocuments from "./Documents/ClientDocuments";
import DocumentEditor from "../Editor/DocumentEditor";
import SystemUsers from "./ProTIS/SystemUsers";
import ProjectStatus from "./Project/ProjectStatus";
import Projects from "./Project/Projects";


class MainScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTheme: "theme_protis",
        };


        this.core = props.core;
    }

    handleTopMenuSelection = (pageName) => {

        /** Fallback as sometimes logout does not trigger properly under specific situations **/

        if (pageName === "protis_logout") {
            this.props.history.push("/login/");
        }

        /** Initiate theme picking here **/

        else if (pageName === "theme_protis" || pageName === "theme_black_n_white") {
            this.props.changeTheme(pageName);
            this.setState({activeTheme: pageName});
        } else {
            this.props.history.push("/main/" + pageName);
        }
    };

    render() {
        return (
            <div>
                <div>
                    <TopMenuDropdown
                        topMenu={this.props.topMenuData}
                        // onClick={(value) => alert(JSON.stringify(value))}
                        onClick={(value) => this.handleTopMenuSelection(value)}
                    />

                    <Route path="/main/protis_logout">
                        <Redirect to="/login/user"/>
                    </Route>

                    <Route path="/main/nodes_view"
                           render={(props) => <NodeScreen
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/users_view"
                           render={(props) => <ProjectUserScreen
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/document_types_view"
                           render={(props) => <DocumentTypeData
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/clients_view"
                           render={(props) => <ClientData
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/roles_view"
                           render={(props) => <RoleData
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/user_action_history_view"
                           render={(props) => <UserActionData
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/client_document_sets_view"
                           render={(props) => <DocumentSetData
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/routes_view"
                           render={(props) => <RoutesView
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/documents_view"
                           render={(props) => <AllDocuments
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/node_documents_view"
                           render={(props) => <NodeDocuments
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/client_documents_view"
                           render={(props) => <ClientDocuments
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/documentEditor"
                           render={(props) => <DocumentEditor
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/protis_users"
                           render={(props) => <SystemUsers
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/project_status"
                           render={(props) => <ProjectStatus
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                    <Route path="/main/projects_view"
                           render={(props) => <Projects
                               {...props}
                               core={this.core}
                               themeName={this.state.activeTheme}
                               languageCode={this.props.languageSelectionCode}
                           />}
                    />
                </div>

                <Redirect path="/main" exact to="/main"/>
            </div>
        );
    }
}


export default MainScreen;