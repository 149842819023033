function PrepareColumnsFromDefinitions(rawDefinitionColumns, labelTranslations) {
    let convertedColumns = [];
    if (rawDefinitionColumns.length > 0) {
        for (let column of rawDefinitionColumns) {
            if (column.hidden === true || column.type === "hidden" || column.scope === "edit") {
                continue;
            } else {
                let columnLabel;
                if (labelTranslations.hasOwnProperty(column.label)) {
                    columnLabel = labelTranslations[column.label];
                } else {
                    columnLabel = column.label;
                }

                let newColumn = {};
                newColumn.field = column.name;
                newColumn.headerName = columnLabel;
                newColumn.width = column.width;
                convertedColumns.push(newColumn);
            }
        }
    }
    return convertedColumns;
}


export default PrepareColumnsFromDefinitions;