import React from "react";
import EntityRequest from "../../../ProTIS/EntityRequest";
import DataCombobox from "../../DataCombobox";
import SmartInput from "../../SmartInput";

class GenericScreenFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterList: {},
            filterValue: {},
            filteredData: [],
        };

        this.core = props.core;

        this.translatedLabels = this.props.translatedLabels ? this.props.translatedLabels : {};

        this.filters = props.filterData;

        this.topGridName = this.props.topGridEntityRef.entityName;
        this.topGridMethod = this.props.topGridEntityRef.entityMethod;
    }

    onNewFilterValue = async () => {
        this.props.onNewFilterValue(this.state.filterValue);
    };

    formGridDesignation = (entity, method) => {
        if (this.props.topGridEntityRef) {
            return entity + "/" + method;
        }
    };

    labelTranslation = (label) => {
        if (this.translatedLabels.hasOwnProperty(label)) {
            return this.translatedLabels[label];
        }
        return label;
    };

    async componentDidMount() {
        let allStateChanges = {};
        if (this.filters && this.filters.length > 0) {

            let newFilterList = {...this.state.filterList};
            let firstFilterValue = {...this.state.filterValue};

            for (let filter of this.filters) {

                if (filter.definition && filter.definition.entityName && filter.type === "datacombo") {

                    let entityName = filter.definition.entityName;
                    let entityMethod = filter.definition.entityMethod;

                    let list = await this.getNewComboList(entityName, entityMethod);

                    newFilterList[filter.name] = list;
                    firstFilterValue[filter.name] = list[0][filter.definition.valueField];

                } else if (filter.type && filter.type === "text") {
                    firstFilterValue[this.createNewFilterName(filter.name, filter.type)] = "";
                }
                allStateChanges.filterList = newFilterList;
                allStateChanges.filterValue = firstFilterValue;

            }
        }

        this.setState(allStateChanges);

        await this.onNewFilterValue();
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.translatedLabels !== this.props.translatedLabels) {
            this.translatedLabels = this.props.translatedLabels;
            this.updateStateToRerender();
        }
    }

    updateStateToRerender = () => {
        const date = new Date();
        let updated = date.getHours() + ":" + date.getMinutes() + ":" + date.getMilliseconds();
        this.setState({updatedState: updated});
    };

    requestEntityData = async (entityName, entityMethod, manualFilter) => {
        let entityRequest = new EntityRequest(this.core, entityName);
        let receivedEntityData;
        if (manualFilter && manualFilter !== false) {
            receivedEntityData = await entityRequest.getList(manualFilter, entityMethod);
        } else {
            receivedEntityData = await entityRequest.getList();
        }
        return receivedEntityData;
    };


    createNewFilterName = (filterName, filterType) => {
        return filterName + ":" + filterType;
    };

    getNewComboList = async (entityName, entityMethod) => {
        let newComboList = new EntityRequest(this.core, entityName);
        let list = await newComboList.getList([], entityMethod);
        return list ? list.records : console.error("Get Combo List failed: Returned value is NULL");
    };

    generateFilterComboBox = (filter, style, valueField, labelField, entityName, entityMethod) => {
        if (this.state.filterList && this.state.filterValue && this.state.filterList[filter.name]) {
            let comboOptions = this.state.filterList[filter.name];
            let initialValue = this.state.filterValue[filter.name];

            return (
                <div style={style} key={filter.name}>
                    {this.labelTranslation(filter.label)}
                    <div style={{display: "flex", alignItems: 'center'}}>
                        <DataCombobox
                            dropdownOptions={comboOptions}
                            value={initialValue}
                            valueField={valueField}
                            textField={labelField}
                            onChange={(value) => this.handleComboSelect(
                                value[valueField],
                                filter,
                                entityName,
                                entityMethod,
                                true)}
                        />
                    </div>
                </div>
            );
        }
    };

    generateTextFilter = (filter, style) => {
        if (this.state.filterValue) {
            let inputFieldName = this.createNewFilterName(filter.name, filter.type);
            let fieldValue = this.state.filterValue[inputFieldName];
            return (
                <div style={style} key={filter.name}>
                    {this.labelTranslation(filter.label)}
                    <div style={{display: "flex", alignItems: "center"}}>
                        <SmartInput
                            mask={null}
                            inputDelay={2000}
                            onChange={(text) => this.handleTextFilterInput(text, true, inputFieldName)}
                            name={inputFieldName}
                            type={filter.type}
                            placeholder={filter.name}
                            value={fieldValue}
                            style={{width: filter.width, height: '16px'}}
                        />
                    </div>
                </div>
            );
        }
    };

    handleTextFilterInput = async (event, isSmartInput, inputName) => {
        /** Track input change **/
        let target;
        let fieldName;
        let value;

        if (isSmartInput !== true) {
            target = event.target;
            fieldName = target.name;
            value = target.value;
        } else if (isSmartInput === true) {
            fieldName = inputName;
            value = event;
        }

        let newFilterValues = {...this.state.filterValue};
        newFilterValues[fieldName] = value;

        this.setState({
            filterValue: newFilterValues,
        });

        await this.onNewFilterValue();
    };

    generateFilterComboRow = () => {

        let renderedFilters = [];

        const elementStyle = {
            display: 'grid',
            gridTemplateColumns: "120px 1fr",
            gridGap: "10px 10px",
            paddingTop: "5px",
            marginBottom: "5px",
        };

        if (this.filters) {
            for (let filter of this.filters) {
                if (filter.type === "datacombo") {
                    let entityName = filter.definition.entityName;
                    let entityMethod = filter.definition.entityMethod;
                    renderedFilters.push(
                        this.generateFilterComboBox(
                            filter,
                            elementStyle,
                            filter.definition.valueField,
                            filter.definition.labelField,
                            entityName,
                            entityMethod,
                        ),
                    );
                }
                if (filter.type === "text") {
                    renderedFilters.push(
                        this.generateTextFilter(
                            filter,
                            elementStyle,
                        ),
                    );
                }
            }
            return renderedFilters;
        }
    };

    async handleComboSelect(value, filter, _entityName, _entityMethod, _ignoreFilters) {

        let newFilteredGridData = {...this.state.filteredData};

        let currentValue = {...this.state.filterValue};
        currentValue[filter.name] = value;

        let filteredData = await this.requestEntityData(this.topGridName, this.topGridMethod, currentValue);

        newFilteredGridData[this.formGridDesignation(this.topGridName, this.topGridMethod)] = filteredData.records ? filteredData.records : [];

        this.setState({
            filteredData: newFilteredGridData,
            filterValue: currentValue,
        });
        await this.onNewFilterValue();
    }

    render() {

        const generateFilterRow = this.generateFilterComboRow();

        return (
            <div>
                {generateFilterRow}
            </div>
        );
    }

}

export default GenericScreenFilter;