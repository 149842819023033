import CoreRequest from "./CoreRequest";

/**
 * Translation requests
 */
class ScreenTranslation {
    static _allTranslations = {}; // Translations cache: all screens, all languages

    /**
     * Translate screen labels
     *
     * Labels are grouped together as a collection of {text, translation} pairs,
     * by the same objectClass. Such collections are cached as an object.
     * If you don't need caching of such type, pass an empty string for objectClass
     * parameter.
     *
     * @param sourceLanguage        Document Source/Original language.
     * @param destinationLanguage   Destination language
     * @param objectClass           Object class, like "view:Nodes"
     * @param labels                Array of labels
     * @param documentId            Optional related document id
     * @returns {Promise<*>}        Promise of translated labels, if the form of JS object
     */
    static async translateLabels(sourceLanguage, destinationLanguage, objectClass, labels, documentId) {
        if (!ScreenTranslation._allTranslations.hasOwnProperty(destinationLanguage)) {
            ScreenTranslation._allTranslations[destinationLanguage] = {};
        }

        let requestType = "translate/object";
        if (objectClass === "screen:login") {
            // Special case, Login Screen, doesn't use labels array
            requestType = "translate/login_screen";
            labels = [];
        }

        let objectTranslations = {};

        // If object class is not defined, don't use translation cache
        if (objectClass !== "") {
            objectTranslations = ScreenTranslation._allTranslations[destinationLanguage];
            if (objectTranslations.hasOwnProperty(objectClass)) {
                return objectTranslations[objectClass];
            }
        }

        objectTranslations[objectClass] = {};

        let translationRequest = {
            dst_language: destinationLanguage,  // Destination language from language combo
            src_language: sourceLanguage,       // Source language - always en
            engine: "Google",                   // Translation engine
            object_class: objectClass,          // Name of the screen
            object_domain: "protis",            // Constant
            source: labels,                     // Strings to translate
            document_id: documentId             // Optional related document id
        };

        let core = CoreRequest.Instance;
        let labelTranslations = objectTranslations[objectClass];
        await core.send(false, requestType, translationRequest,
            (jsonData) => {
                for (let stringTranslation of jsonData.translation) {
                    labelTranslations[stringTranslation.source] = stringTranslation.translation;
                }

                return labelTranslations;
            },
            null);
        return labelTranslations;
    }

    /**
     * Translate login screen labels
     * @param destinationLanguage   Destination language
     * @returns {Promise<*>}        Promise of translated labels, if the form of JS object
     */
    static async translateLoginScreenLabels(destinationLanguage) {
        return ScreenTranslation.translateLabels("en", destinationLanguage, "screen:login", []);
    }

    /**
     * Translate text paragraphs
     *
     * @param sourceLanguage        Document Source/Original language.
     * @param destinationLanguage   Destination language
     * @param paragraphs            Array of text paragraphs
     * @returns {Promise<*>}        Promise of translated labels, if the form of JS object
     */
    static async translateTextParagraphs(sourceLanguage, destinationLanguage, paragraphs) {
        return ScreenTranslation.translateLabels(sourceLanguage, destinationLanguage, "", paragraphs);
    }
}

export default ScreenTranslation;