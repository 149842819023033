// Auto-generated file, please don't modify

export const allDocumentsView = [
    {
        buttons: ["Add", "Edit", "Remove", "View", "Print Barcode", "Refresh"],
        filters: [
            {
                label: "Barcode:",
                name: "barcode",
                width: 150,
                type: "text",
            },
        ],
        title: "Documents",
        entityName: "document",
        entityMethod: "list",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "project_id",
                name: "project_id",
                width: 60,
                hidden: true,
                type: "hidden",
                defaultValue: "{current_project_id}",
            },
            {label: "route_id", name: "route_id", width: 60, hidden: true, type: "hidden"},
            {label: "node_id", name: "node_id", width: 60, hidden: true, type: "hidden"},
            {
                label: "document_state_id",
                name: "document_state_id",
                width: 60,
                hidden: true,
                type: "hidden",
                defaultValue: 2,
            },
            {label: "route_start", name: "route_start", width: 60, hidden: true, type: "hidden"},
            {label: "route_end", name: "route_end", width: 60, hidden: true, type: "hidden"},
            {label: "title", name: "document_title", width: 200, type: "text", mandatory: true},
            {label: "barcode", name: "barcode", width: 150, type: "text", mandatory: false, disabled: true},
            {label: "type", name: "document_type_name", width: 200, type: "text", scope: "view"},
            {
                label: "Type:",
                name: "document_type_id",
                width: 200,
                type: "datacombo",
                scope: "edit",
                mandatory: true,
                disabled: "edit",
                definition: {
                    entityName: "document_type", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "document_type_name",
                },
            },
            {
                label: "Client:",
                name: "client_name",
                width: 200,
                type: "text",
                scope: "view",
            },
            {label: "client_id", name: "client_id", width: 60, hidden: true, type: "hidden", scope: "view"},
            {
                label: "client_id", name: "client_id", width: 200, type: "datacombo", scope: "edit", mandatory: true,
                definition: {
                    entityName: "client", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "client_name",
                    // onChange: function (client_id) {
                    //     documentEditorClientChange(dataView);
                    // }
                },
            },
            {label: "Folder:", name: "document_set_name", width: 200, type: "text", scope: "view"},
            {
                label: "Folder:",
                name: "document_set_id",
                width: 200,
                type: "datacombo",
                scope: "edit",
                mandatory: false,
                definition: {
                    entityName: "document_set", entityMethod: "list",
                    requestParameters: {client_id: 0},
                    valueField: "id",
                    labelField: "document_set_name",
                    wsCompatible: true,
                },
            },
            {label: "Status:", name: "document_state_name", width: 200, type: "text", scope: "view"},
            {label: "Node:", name: "node_name", width: 200, type: "text", scope: "view"},
            {
                label: "Registered:",
                name: "registered",
                width: 150,
                type: "datetime",
                defaultValue: "current_timestamp",
                mandatory: true,
            },
            {
                label: "Updated:",
                name: "updated",
                width: 150,
                type: "datetime",
                defaultValue: "current_timestamp",
            },
            {
                label: "presentation_format_id",
                name: "document_format_id",
                width: 150,
                type: "text",
                mandatory: false,
                hidden: true,
            },
            {
                label: "Presentation:", name: "document_data",
                type: "file",
                scope: "edit",
            },
            {label: "language_id", name: "document_language_id", type: "hidden", scope: "edit", defaultValue: 1},
        ],
    },
    {
        buttons: [],
        filters: [],
        title: "Document Changes History",
        entityName: "document",
        entityMethod: "list_history",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {label: "Started:", name: "start_timestamp", width: 150, type: "text"},
            {label: "Ended:", name: "end_timestamp", width: 150, type: "text"},
            {label: "Status:", name: "document_state_name", width: 200, type: "text"},
            {label: "Node:", name: "node_name", width: 150, type: "text"},
            {label: "User Name:", name: "user_name", width: 150, type: "text"},
        ],
    },
];

export const nodeDocumentsView = [
    {
        buttons: ["Add", "Edit", "Remove", "View", "Receive", "Dispatch", "Return", "Print Barcode", "Refresh"],
        filters: [
            {
                label: "Barcode:",
                name: "barcode",
                width: 150,
                type: "text",
            },
            {
                label: "Node:",
                name: "node_id",
                width: 150,
                type: "datacombo",
                definition: {
                    entityName: "node",
                    entityMethod: "list",
                    valueField: "id",
                    labelField: "node_name",
                },
            },
        ],
        title: "Documents",
        entityName: "document",
        entityMethod: "list",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "project_id",
                name: "project_id",
                width: 60,
                hidden: true,
                type: "hidden",
                defaultValue: "{current_project_id}",
            },
            {label: "route_id", name: "route_id", width: 60, hidden: true, type: "hidden"},
            {label: "node_id", name: "node_id", width: 60, hidden: true, type: "hidden"},
            {
                label: "document_state_id",
                name: "document_state_id",
                width: 60,
                hidden: true,
                type: "hidden",
                defaultValue: 2,
            },
            {label: "route_start", name: "route_start", width: 60, hidden: true, type: "hidden"},
            {label: "route_end", name: "route_end", width: 60, hidden: true, type: "hidden"},
            {label: "Title:", name: "document_title", width: 200, type: "text", mandatory: true},
            {label: "Barcode:", name: "barcode", width: 150, type: "text", mandatory: false, disabled: true},
            {label: "Type:", name: "document_type_name", width: 200, type: "text", scope: "view"},
            {
                label: "Type:",
                name: "document_type_id",
                width: 200,
                type: "datacombo",
                scope: "edit",
                mandatory: true,
                disabled: "edit",
                definition: {
                    entityName: "document_type", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "document_type_name",
                },
            },
            {
                label: "Client:",
                name: "client_name",
                width: 200,
                type: "text",
                scope: "view",
            },
            {label: "client_id", name: "client_id", width: 60, hidden: true, type: "hidden", scope: "view"},
            {
                label: "client_id", name: "client_id", width: 200, type: "datacombo", scope: "edit", mandatory: true,
                definition: {
                    entityName: "client", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "client_name",
                    // onChange: function (client_id) {
                    //     documentEditorClientChange(dataView);
                    // }
                },
            },
            {label: "Folder:", name: "document_set_name", width: 200, type: "text", scope: "view"},
            {
                label: "Folder:",
                name: "document_set_id",
                width: 200,
                type: "datacombo",
                scope: "edit",
                mandatory: false,
                definition: {
                    entityName: "document_set", entityMethod: "list",
                    requestParameters: {client_id: 0},
                    valueField: "id",
                    labelField: "document_set_name",
                    wsCompatible: true,
                },
            },
            {label: "Status:", name: "document_state_name", width: 200, type: "text", scope: "view"},
            {label: "Node:", name: "node_name", width: 200, type: "text", scope: "view"},
            {
                label: "Registered:",
                name: "registered",
                width: 150,
                type: "datetime",
                defaultValue: "current_timestamp",
                mandatory: true,
            },
            {label: "Updated:", name: "updated", width: 150, type: "datetime", defaultValue: "current_timestamp"},
            {
                label: "presentation_format_id",
                name: "document_format_id",
                width: 150,
                type: "text",
                mandatory: false,
                hidden: true,
            },
            {
                label: "Presentation:", name: "document_data",
                type: "file",
                scope: "edit",
            },
            {label: "language_id", name: "document_language_id", type: "hidden", scope: "edit", defaultValue: 1},
        ],
    },
    {
        buttons: [],
        filters: [],
        title: "Document Changes History",
        entityName: "document",
        entityMethod: "list_history",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {label: "Started:", name: "start_timestamp", width: 150, type: "text"},
            {label: "Ended:", name: "end_timestamp", width: 150, type: "text"},
            {label: "Status:", name: "document_state_name", width: 200, type: "text"},
            {label: "Node:", name: "node_name", width: 150, type: "text"},
            {label: "User Name:", name: "user_name", width: 150, type: "text"},
        ],
    },
];

export const clientDocumentsView = [
    {
        buttons: ["Add", "Edit", "Remove", "View", "Print Barcode", "Refresh"],
        filters: [
            {
                label: "Barcode:",
                name: "barcode",
                width: 150,
                type: "text",
            },
            {
                label: "Client:",
                name: "client_id",
                width: 150,
                type: "datacombo",
                definition: {
                    entityName: "client",
                    entityMethod: "list",
                    valueField: "id",
                    labelField: "client_name",
                },
            },
            {
                label: "Folder:",
                name: "document_set_id",
                width: 150,
                type: "datacombo",
                definition: {
                    entityName: "document_set",
                    entityMethod: "list",
                    valueField: "id",
                    labelField: "document_set_name",
                },
            },
        ],
        title: "Documents",
        entityName: "document",
        entityMethod: "list",
        viewColumns: [
            {label: "id", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "project_id",
                name: "project_id",
                width: 60,
                hidden: true,
                type: "hidden",
                defaultValue: "{current_project_id}",
            },
            {label: "route_id", name: "route_id", width: 60, hidden: true, type: "hidden"},
            {label: "node_id", name: "node_id", width: 60, hidden: true, type: "hidden"},
            {
                label: "document_state_id",
                name: "document_state_id",
                width: 60,
                hidden: true,
                type: "hidden",
                defaultValue: 2,
            },
            {label: "route_start", name: "route_start", width: 60, hidden: true, type: "hidden"},
            {label: "route_end", name: "route_end", width: 60, hidden: true, type: "hidden"},
            {label: "title", name: "document_title", width: 200, type: "text", mandatory: true},
            {label: "barcode", name: "barcode", width: 150, type: "text", mandatory: false, disabled: true},
            {label: "type", name: "document_type_name", width: 200, type: "text", scope: "view"},
            {
                label: "Type:",
                name: "document_type_id",
                width: 200,
                type: "datacombo",
                scope: "edit",
                mandatory: true,
                disabled: "edit",
                definition: {
                    entityName: "document_type", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "document_type_name",
                },
            },
            {
                label: "Client:",
                name: "client_name",
                width: 200,
                type: "text",
                scope: "view",
            },
            {label: "client_id", name: "client_id", width: 60, hidden: true, type: "hidden", scope: "view"},
            {
                label: "client_id", name: "client_id", width: 200, type: "datacombo", scope: "edit", mandatory: true,
                definition: {
                    entityName: "client", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "client_name",
                    // onChange: function (client_id) {
                    //     documentEditorClientChange(dataView);
                    // }
                },
            },
            {label: "Folder:", name: "document_set_name", width: 200, type: "text", scope: "view"},
            {
                label: "Folder:",
                name: "document_set_id",
                width: 200,
                type: "datacombo",
                scope: "edit",
                mandatory: false,
                definition: {
                    entityName: "document_set", entityMethod: "list",
                    requestParameters: {client_id: 0},
                    valueField: "id",
                    labelField: "document_set_name",
                    wsCompatible: true,
                },
            },
            {label: "Status:", name: "document_state_name", width: 200, type: "text", scope: "view"},
            {label: "Node:", name: "node_name", width: 200, type: "text", scope: "view"},
            {
                label: "Registered:",
                name: "registered",
                width: 150,
                type: "datetime",
                defaultValue: "current_timestamp",
                mandatory: true,
            },
            {label: "Updated:", name: "updated", width: 150, type: "datetime", defaultValue: "current_timestamp"},
            {
                label: "presentation_format_id",
                name: "document_format_id",
                width: 150,
                type: "text",
                mandatory: false,
                hidden: true,
            },
            {
                label: "Presentation:", name: "document_data",
                type: "file",
                scope: "edit",
            },
            {label: "language_id", name: "document_language_id", type: "hidden", scope: "edit", defaultValue: 1},
        ],
    },
    {
        buttons: [],
        filters: [],
        title: "Document Changes History",
        entityName: "document",
        entityMethod: "list_history",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {label: "Started:", name: "start_timestamp", width: 150, type: "text"},
            {label: "Ended:", name: "end_timestamp", width: 150, type: "text"},
            {label: "Status:", name: "document_state_name", width: 200, type: "text"},
            {label: "Node:", name: "node_name", width: 150, type: "text"},
            {label: "User Name:", name: "user_name", width: 150, type: "text"},
        ],
    },
];

