import React from 'react';

class ButtonGenerator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            buttons: this.props.buttonsToGenerate,
            style: this.props.buttonStyle,

        };
    }

    buttonTranslation = (buttonName) => {
        if (this.props.buttonTextTranslation && this.props.buttonTextTranslation.hasOwnProperty(buttonName)) {
            return this.props.buttonTextTranslation[buttonName];
        }
        return buttonName;
    };

    handleButtonClick = (buttonName) => {
        this.props.onClickEvent(buttonName);
    };

    render() {
        const spacer = {width: '5px'};
        let buttonTheme;
        if (this.props.buttonTheme === "theme_protis") {
            buttonTheme = 'protis';
        } else if (this.props.buttonTheme === "theme_black_n_white") {
            buttonTheme = "black-n-white";
        }
        const generatedButtons = this.props.buttonsToGenerate.map((buttonName) => {
            let disabledStatus = false;
            if (buttonName.length > 0) {
                if (this.props.disabledButtons) {
                    for (let disabledButton of this.props.disabledButtons) {
                        if (disabledButton === buttonName) {
                            disabledStatus = true;
                        }
                    }
                }
                let buttonText = this.buttonTranslation(buttonName);

                let buttonLowerCase = buttonName.toLowerCase().replaceAll(" ", "_");
                return <button
                    key={buttonName}
                    disabled={disabledStatus}
                    style={this.props.buttonStyle}
                    onClick={this.handleButtonClick.bind(this, buttonLowerCase)}
                >
                    <img
                        src={require(`../ImageResources/${buttonTheme}/buttons/${buttonLowerCase}.png`).default}
                        alt={buttonName}
                    />
                    <span style={spacer}/>
                    {buttonText}
                </button>;
            }
            return null;
        });

        return (
            <div style={this.props.divParameters}>
                {generatedButtons}
            </div>
        );

    }

}

export default ButtonGenerator;