/**
 * ProTIS Core document-related requests
 *
 * The record includes:
 * id                            int (omit if creating or unknown)
 * project_id                    int
 * document_type_id                int
 * route_id                        int
 * node_id                        int
 * client_id                    int
 * document_title                string
 * barcode                        string
 * registered                   dateTime
 * updated                      dateTime
 * document_state_id            int
 * document_set_id              int
 * document_data                string
 * document_format_id           int
 * document_language_id         int
 */
import EntityRequest from "./EntityRequest";

export default class DocumentRequest extends EntityRequest {
    constructor(coreRequest) {
        super(coreRequest, "document");
    }

    /**
     * Dispatch document to the next node in route
     * @param id            Document id
     * @returns {Promise<null>}
     */
    async dispatchDocument(id) {
        let objectData = null;
        await this.core.send(false, this.entity + "/dispatch", {document_id: id},
            (jsonData) => {
                objectData = jsonData;
            },
            null);
        return objectData;
    }

    /**
     * Receive document in the node
     * @param id            Document id
     * @returns {Promise<null>}
     */
    async receiveDocument(id) {
        let objectData = null;
        await this.core.send(false, this.entity + "/receive", {document_id: id},
            (jsonData) => {
                objectData = jsonData;
            },
            null);
        return objectData;
    }

    /**
     * Convert document from word/OpenOffice format to html
     * @param  base64DocumentData       Incoming Base 64 Document Data.
     * @param   documentType            Document format/type (docx/odt)
     * @returns {Promise<null>}
     */
    async convertDocumentToHTML(base64DocumentData, documentType) {
        let objectData = null;
        await this.core.send(false, this.entity + "/import", {
                document_data_b64: base64DocumentData,
                document_file_type: documentType,
            },
            (jsonData) => {
                objectData = jsonData;
            },
            null);
        return objectData;
    }


    /**
     * Dispatch document to the next node in route
     * @param id            Document id
     * @returns {Promise<null>}
     */
    async returnDocument(id) {
        let objectData = null;
        await this.core.send(false, this.entity + "/return", {document_id: id},
            (jsonData) => {
                objectData = jsonData;
            },
            null);
        return objectData;
    }
}