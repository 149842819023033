/**
 * ProTIS Core language-related requests
 */

export default class LanguageRequest {
    constructor (coreRequest) {
        this.core = coreRequest;
    }

    async getList(completedCallback=null) {
        let languageData = null;
        await this.core.send(true, "language/list", {},
            (jsonData) => {
                if (completedCallback)
                    completedCallback(jsonData);
                else
                    languageData = jsonData;
            },
            null);
        return languageData;
    }
}