import React from 'react';
import {Link} from "react-router-dom";
import OwnerRequest from "../../ProTIS/OwnerRequest";
import homeButton from "../../ImageResources/protis/buttons/home.png";
import previousButton from "../../ImageResources/protis/buttons/prior.png";
import nextButton from "../../ImageResources/protis/buttons/next.png";
import submitButton from "../../ImageResources/protis/buttons/ok.png";
import passRevealButton from "../../ImageResources/protis/buttons/icons8-eye-25.png";
import ScreenTranslation from "../../ProTIS/ScreenTranslation";
import MuiCustomPrompt from "../PopupAndComponents/MuiCustomPrompt";


class LoginUserPass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Username: '',
            Password: '',
            // Username: 'davidy',
            // Password: 'qazwsx',
            passwordVisible: 'password',
            currentTranslationCode: "en",
            open: false,
        };
        this.core = props.core;

        this.translatedLabels = [];
        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";

    }

    translateLabel = (label) => {
        if (this.props.translationCode !== "en") {
            if (this.translatedLabels.hasOwnProperty(label)) {
                return this.translatedLabels[label];
            }
        }
        return label;
    };

    async componentDidMount() {
        await this.getTranslatedLabels();
    }

    async componentDidUpdate() {
        if (this.props.translationCode !== this.state.currentTranslationCode) {
            await this.getTranslatedLabels();
        }
    }

    async getTranslatedLabels() {
        this.translatedLabels = await ScreenTranslation.translateLoginScreenLabels(this.props.translationCode);
        this.setState({currentTranslationCode: this.props.translationCode});
    }

    handleDialogClose = (confirmation) => {
        if (confirmation === false) {
            this.setState({open: false});
        }
    };

    login = async () => {
        let result = await this.core.login(this.state.Username, this.state.Password);

        if (!result.success) {
            /** If login failed **/
            this.dialogTitle = "Login Error";
            this.dialogText = "Invalid username or password";
            this.errorType = "failure";
            this.setState({
                authenticated: false,
                open: true,
            });
            return;
        }

        this.setState({authenticated: true});

        /** Awaiting data for authenticated user **/
        const ownerRequest = new OwnerRequest(this.core);
        let resultOwner = await ownerRequest.getList();

        /** Data is passed to higher module prior to switching to next stage  **/
        if (resultOwner) {
            this.props.onUserAuth(resultOwner.records);

            /** Now that data is in state ready for use, switch to next module, with data ready to be loaded. **/
            this.props.history.push("/login/database", this.state.currentTranslationCode);
        } else {
            console.error("Login error: Returned value is NULL");
        }

    };

    handleChange = (event) => {
        const target = event.target;
        const fieldName = target.name;
        const value = target.value;

        this.setState({
            [fieldName]: value,
        });
    };


    handleSubmit = (event) => {
        event.preventDefault();
        const target = event.target;
        const fieldName = target.name;
        const value = target.value;

        this.setState({
            [fieldName]: value,
        });
    };

    handlePasswordVisibility = () => {
        if (this.state.passwordVisible === 'text') {
            this.setState({passwordVisible: 'password'});
        } else {
            this.setState({passwordVisible: 'text'});
        }
    };


    render() {
        const mainElementStyling = {
            display: 'grid',
            gridTemplateColumns: '650px',
            gridGap: ' 20px 50px',
        };

        const navPosition = {
            display: 'flex',
            width: '700px',
            justifyContent: 'center',
        };

        const navButtons = {
            width: '100px',
            height: '34px',
            margin: '0 5px 0 5px',
        };

        const topDivStyle = {
            display: 'grid',
            gridTemplateRows: "1fr 350px",
        };

        return (
            <div style={topDivStyle}>
                <span style={{display: 'grid', margin: '10px 0 10px 40px', fontWeight: 'bold'}}>
                    {this.translateLabel("Entrance to the Program-technological Information Systems (ProTIS)")}
                </span>
                <div style={mainElementStyling}>
                    <form style={{marginTop: '20px'}} onSubmit={this.handleSubmit}>
                        <div style={{display: 'flex', width: '1fr', marginBottom: '20px', marginLeft: "30px"}}>
                            <span style={{width: '150px', textAlign: 'right', paddingRight: '20px'}}>
                                <label>{this.translateLabel("Username:")}</label>
                            </span>
                            <span style={{width: '150px'}}>
                                <input
                                    name="Username"
                                    type="text"
                                    placeholder={this.translateLabel("Username")}
                                    onSubmit={this.handleSubmit}
                                    onChange={this.handleChange}
                                />
                            </span>
                        </div>

                        <div style={{display: 'flex', width: '600px', marginBottom: '20px', marginLeft: "30px"}}>
                            <span style={{width: '150px', textAlign: 'right', paddingRight: '20px'}}>
                                <label>{this.translateLabel("Password:")}</label>
                            </span>
                            <span style={{width: '160px'}}>
                                <input
                                    name="Password"
                                    type={this.state.passwordVisible}
                                    placeholder={this.translateLabel("Password")}
                                    onSubmit={this.handleSubmit}
                                    onChange={this.handleChange}
                                />
                                </span>
                            <span>
                                <img onClick={this.handlePasswordVisibility}
                                     style={{width: "24px", height: "24px"}}
                                     src={passRevealButton} alt="Reveal"/>
                            </span>
                        </div>
                    </form>
                </div>

                <nav style={navPosition}>
                    <Link to="/login/user">
                        <button
                            style={navButtons}
                            disabled={true}
                        >
                            <img src={homeButton} alt="Home Button"/>
                        </button>
                    </Link>
                    <Link to="/login/user">
                        <button
                            style={navButtons}
                            disabled={true}
                        >
                            <img src={previousButton} alt="Previous Button"/>
                        </button>
                    </Link>
                    <button
                        style={navButtons}
                        onClick={this.login}
                    >
                        <img src={nextButton} alt="Next Button"/>
                    </button>
                    <button style={navButtons}>
                        <img src={submitButton} alt="Submit Button"/>
                    </button>
                </nav>

                <MuiCustomPrompt
                    open={this.state.open}
                    title={this.translateLabel(this.dialogTitle)}
                    displayedText={this.translateLabel(this.dialogText)}
                    buttonTheme={this.props.themeName}
                    cancelOnly={true}
                    onCancel={(confirmation) => this.handleDialogClose(confirmation)}
                    showContextImage={this.errorType}
                />
            </div>
        );
    }
}

export default LoginUserPass;