import * as constDefinitions from '../../LocalDefinitions/menuDocument';
import GenericDocuments from "./GenericDocuments";

const clientDocumentsView = constDefinitions.clientDocumentsView;

class ClientDocuments extends GenericDocuments {
    constructor(props) {
        super(props, clientDocumentsView);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        let item1 = this.state.selectedRowData["document/list"].document_title;
        let item2 = this.state.selectedRowData["document/list"].client_name;
        return item1 + " of " + item2;
    }

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default ClientDocuments;
