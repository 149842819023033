// Auto-generated file, please don't modify

const unitsDataComboDefinition = {
    values: [
        {value: "seconds", label: "seconds"},
        {value: "minutes", label: "minutes"},
        {value: "hours", label: "hours"},
        {value: "days", label: "days"},
    ],
};

export const clientsView = [
    {
        buttons: ["Add", "Edit", "Remove", "Refresh"],
        filters: [],
        title: "Clients",
        entityName: "client",
        entityMethod: "list",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, sorttype: "int", hidden: true, type: "hidden"},
            {
                label: "project id",
                name: "project_id",
                width: 60,
                sorttype: "int",
                hidden: true,
                type: "hidden",
                scope: "edit",
            },
            {label: "Client Name:", name: "client_name", width: 200, type: "text", mandatory: true},
            {
                label: "Registered:",
                name: "registered",
                width: 160,
                type: "datetime",
                scope: "edit",
                defaultValue: "current_timestamp",
                mandatory: true,
            },
            {label: "E-mail:", name: "email", width: 350, type: "text"},
            {label: "Street:", name: "address_street", width: 200, type: "text"},
            {label: "City:", name: "address_city", width: 200, type: "text"},
            {label: "State:", name: "address_state", width: 100, type: "text"},
            {label: "Postcode:", name: "address_postcode", width: 100, type: "text"},
            {label: "Country:", name: "country_name", width: 150, type: "text", scope: "view"},
            {
                label: "country", name: "address_country_id", width: 150, type: "datacombo", scope: "edit",
                definition: {
                    entityName: "country", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "country_name",
                },
            },
        ],
    },
];

export const usersView = [
    {
        buttons: ["Add", "Edit", "Remove", "Refresh"],
        filters: [],
        title: "Project Users",
        entityName: "user_role",
        entityMethod: "list",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "project_id",
                name: "project_id",
                width: 60,
                hidden: true,
                type: "hidden",
                defaultValue: "{current_project_id}",
            },
            {label: "First Name:", name: "first_name", width: 200, type: "text", scope: "view"},
            {label: "Last Name:", name: "last_name", width: 200, type: "text", scope: "view"},
            {label: "User Name:", name: "user_name", width: 200, type: "text", scope: "view"},
            {
                label: "User Name:",
                name: "user_id",
                width: 200,
                type: "datacombo",
                scope: "edit",
                mandatory: true,
                disabled: "edit",
                definition: {
                    entityName: "user", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "user_name",
                },
            },
            {label: "Role Name:", name: "role_name", width: 200, type: "text", scope: "view"},
            {
                label: "Role Name:", name: "role_id", width: 200, type: "datacombo", scope: "edit", mandatory: true,
                definition: {
                    entityName: "role", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "role_name",
                },
            },
            {label: "Start date:", name: "start_date", width: 150, type: "datetime"},
            {label: "End date:", name: "end_date", width: 150, type: "datetime"},
        ],
    },
];

export const rolesView = [
    {
        buttons: ["Add", "Edit", "Remove", "Refresh"],
        filters: [],
        title: "Roles",
        entityName: "role",
        entityMethod: "list",
        viewColumns: [
            {label: "role_id", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "project_id",
                name: "project_id",
                width: 60,
                hidden: true,
                type: "hidden",
                defaultValue: "{current_project_id}",
            },
            {label: "Role Name:", name: "role_name", width: 300, type: "text", mandatory: true},
            {
                label: "",
                html: "<label id='permission_label' class='translate' title='Permissions:'>Permissions:</label>",
                width: 100,
                height: 20,
                type: "html",
                scope: "edit",
            },
            {
                label: "", name: "permissions", width: 560, height: 250, type: "datagrid", scope: "edit",
                definition: {
                    extern: true,                      // Data is not a part of the record
                    entityName: "role_permissions", entityMethod: "list",
                    requestParameters: {role_id: "0${id}"},
                    parentObjectType: "permissions",
                    wsCompatible: true,
                    columns: [
                        {label: "Entity:", name: "entity_name", width: 150, type: "text"},
                        {label: "View:", name: "can_view", width: 60, type: "option"},
                        {label: "Create:", name: "can_create", width: 60, type: "option"},
                        {label: "Modify:", name: "can_modify", width: 60, type: "option"},
                        {label: "Remove:", name: "can_remove", width: 60, type: "option"},
                        {label: "Process:", name: "can_process", width: 60, type: "option"},
                    ],
                },
            },
        ],
    },
];

export const nodesView = [
    {
        buttons: ["Add", "Edit", "Remove", "Refresh"],
        filters: [],
        title: "Nodes",
        entityName: "node",
        entityMethod: "list",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "project_id",
                name: "project_id",
                width: 60,
                hidden: true,
                type: "hidden",
                value: "{current_project_id}",
            },
            {label: "Node Name:", name: "node_name", width: 200, type: "text", mandatory: true},
            {label: "E-mail:", name: "email", width: 350, type: "text"},
            {label: "Street:", name: "address_street", width: 200, type: "text"},
            {label: "City:", name: "address_city", width: 200, type: "text"},
            {label: "State:", name: "address_state", width: 100, type: "text"},
            {label: "Postcode:", name: "address_postcode", width: 100, type: "text"},
            {label: "Country:", name: "country_name", width: 150, type: "text", scope: "view"},
            {
                label: "Country:", name: "address_country_id", width: 150, type: "datacombo", scope: "edit",
                definition: {
                    entityName: "country", entityMethod: "list",
                    requestParameters: {},
                    valueField: "id",
                    labelField: "country_name",
                },
            },
            {label: "Timezone:", name: "timezone", width: 150, type: "text", scope: "view"},
            {
                label: "Timezone:", name: "timezone", width: 150, type: "datacombo", scope: "edit",
                definition: {
                    requestType: "timezone/list",
                    requestParameters: {},
                    valueField: "name",
                    labelField: "name",
                },
            },
            {label: "Work Hours:", name: "work_hours", width: 150, type: "text"},
        ],
    },
];

export const documentTypesView = [
    {
        buttons: ["Add", "Edit", "Remove", "Refresh"],
        filters: [],
        title: "Document Types",
        entityName: "document_type",
        entityMethod: "list",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "project_id",
                name: "project_id",
                width: 60,
                hidden: true,
                type: "hidden",
                value: "{current_project_id}",
            },
            {label: "Document Type", name: "document_type_name", width: 200, type: "text", mandatory: true},
        ],
    },
];

export const clientDocumentSetsView = [
    {
        buttons: ["Add", "Edit", "Remove", "Refresh"],
        filters: [
            {
                label: "Client:",
                name: "client_id",
                width: 150,
                type: "datacombo",
                definition: {
                    entityName: "client",
                    entityMethod: "list",
                    valueField: "id",
                    labelField: "client_name",
                },
            },
        ],
        title: "Document Folders",
        entityName: "document_set",
        entityMethod: "list",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {label: "Client ID:", name: "client_id", width: 60, hidden: true, type: "hidden", scope: "edit"},
            {label: "Folder:", name: "document_set_name", width: 200, type: "text", mandatory: true},
            {label: "Created:", name: "created", width: 160, type: "datetime", scope: "view"},
            {label: "Modified:", name: "modified", width: 160, type: "datetime", scope: "view"},
        ],
    },
];

export const routesView = [
    {
        buttons: ["Add", "Edit", "Remove", "Refresh"],
        filters: [
            {
                label: "Document Type:",
                name: "document_type_id",
                width: 150,
                type: "datacombo",
                definition: {
                    entityName: "document_type",
                    entityMethod: "list",
                    valueField: "id",
                    labelField: "document_type_name",
                },
            },
        ],
        title: "Routes",
        entityName: "route",
        entityMethod: "list",
        viewColumns: [
            {label: "ID:", name: "id", width: 60, hidden: true, type: "hidden"},
            {
                label: "document_type_id",
                name: "document_type_id",
                width: 60,
                hidden: true,
                type: "hidden",
                scope: "edit",
            },
            {
                label: "Order:",
                name: "route_number",
                sorttype: "int",
                width: 50,
                type: "text",
                mandatory: true,
                mask: "\\d+",
            },
            {label: "Node Name:", name: "node_name", width: 200, type: "text", scope: "view"},
            {
                label: "Node Name:", name: "node_id", width: 150, type: "datacombo", scope: "edit", mandatory: true,
                definition: {
                    entityName: "node", entityMethod: "list",
                    requestParameters: {filter: {project_id: "{current_project_id}"}},
                    valueField: "id",
                    labelField: "node_name",
                },
            },
            {
                label: "Average processing:",
                name: "average_processing_time",
                sorttype: "int",
                width: 150,
                type: "text",
                tailed: "true",
                mandatory: true,
                mask: "\\d*",
                defaultValue: 5,
            },
            {
                label: "", name: "average_processing_units", width: 120, type: "datacombo", scope: "edit",
                definition: unitsDataComboDefinition, defaultValue: "minutes",
            },
            {
                label: "Max processing:", name: "max_processing_time", sorttype: "int", width: 150, type: "text",
                tailed: "true", mandatory: true, mask: "\\d*", defaultValue: 10,
            },
            {
                label: "", name: "max_processing_units", width: 120, type: "datacombo", scope: "edit",
                definition: unitsDataComboDefinition, defaultValue: "minutes",
            },
            {
                label: "Average transfer:", name: "average_transfer_time", sorttype: "int", width: 150, type: "text",
                tailed: "true", mask: "\\d*", defaultValue: 10,
            },
            {
                label: "", name: "average_transfer_units", width: 120, type: "datacombo", scope: "edit",
                definition: unitsDataComboDefinition, defaultValue: "minutes",
            },
            {
                label: "Max transfer:", name: "max_transfer_time", sorttype: "int", width: 150, type: "text",
                tailed: "true", mask: "\\d*", defaultValue: 10,
            },
            {
                label: "", name: "max_transfer_units", width: 120, type: "datacombo", scope: "edit",
                definition: unitsDataComboDefinition, defaultValue: "minutes",
            },
        ],
    },
];

export const userActionHistoryView = [
    {
        buttons: ["Refresh"],
        filters: [
            {
                label: "User:",
                name: "user_name",
                width: 150,
                type: "datacombo",
                definition: {
                    entityName: "user_role",
                    entityMethod: "list",
                    valueField: "user_name",
                    labelField: "user_name",
                },
            },
        ],
        title: "User actions",
        entityName: "user_action",
        entityMethod: "list",
        viewColumns: [
            {label: "Date:", name: "performed", width: 120, type: "text"},
            {label: "User Name:", name: "user_name", width: 80, type: "text"},
            {label: "Operation:", name: "operation", width: 150, type: "text"},
            {label: "Parameters:", name: "parameters", width: 200, type: "text"},
            {label: "Duration (ms):", name: "duration_ms", width: 100, type: "text"},
        ],
    },
];

