export const studyLessonColumns = [
    {label: "study_lesson_id", name: "study_lesson_id", width: 60, hidden: true, type: "hidden"},
    {label: "lesson_name", name: 'lesson_name', width: 200, type: "text", sortable: false, disabled: true},
    {label: "assigned", name: 'assigned', width: 80, type: "option", sortable: false},
    {label: "user_id", name: "user_id", width: 60, hidden: true, type: "hidden"},
];

export const protisUsers = [
    {
        buttons: ["Add", "Edit", "Remove", "Refresh"],
        filters: [],
        title: "System Users",
        entityName: "user",
        entityMethod: "list",
        viewColumns: [
            {label: "Id", name: "id", width: 60, sorttype: "int", hidden: true, type: "hidden"},
            {label: "First Name:", name: "first_name", width: 200, type: "text"},
            {label: "Last Name:", name: "last_name", width: 200, type: "text"},
            {label: "Phone:", name: "phone", width: 150, type: "text"},
            {label: "E-mail:", name: "email", width: 200, type: "text", mandatory: true},
            {label: "User Name:", name: "user_name", width: 100, type: "text", mandatory: true},
            {
                label: "Password:",
                name: "user_pass",
                width: 100,
                type: "password",
                scope: "edit",
                mandatory: true,
                verification: true,
            },
            {label: "Active:", name: "active", width: 100, type: "checkbox", scope: "edit"},
            {label: "Can create projects:", name: "can_create_project", width: 100, type: "checkbox", scope: "edit"},
            {label: "Can edit users:", name: "can_edit_users", width: 100, type: "checkbox", scope: "edit"},
            {
                label: "Can edit translations:",
                name: "can_edit_translation",
                width: 100,
                type: "checkbox",
                scope: "edit",
            },
            {label: "Is a superuser:", name: "is_superuser", width: 100, type: "checkbox", scope: "edit"},
            {label: "Maximum projects:", name: "max_projects", width: 120, type: "text", scope: "view"},
            {
                label: "Maximum Projects:",
                name: "max_projects",
                width: 50,
                type: "number",
                default: "3",
                scope: "edit",
                mandatory: true,
            },
            {label: "Enable Study:", name: "enable_study", width: 100, type: "checkbox", scope: "edit", disabled: true},
            {
                label: "available lessons",
                name: "study_assignments",
                width: 350,
                height: 200,
                type: "datagrid",
                scope: "edit",
                definition: {
                    entityName: "user_available_study_assignment", entityMethod: "list",
                    requestParameters: {user_id: "${id}", available_only: "true"},
                    columns: studyLessonColumns,
                    buttons: [],
                    extern: true,
                    wsCompatible: true,
                },
            },
        ],
    },
];
