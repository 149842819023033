export default class CoreConfig {
    static ServiceProtocol = "http";

    // This is the test server (visible from outside) settings
    static ServiceHost = "protis.hopto.org";
    static ServiceRoot = "/api-debug";

    // This is localhost server (not visible from outside) settings
    //static ServiceHost = "localhost";
    //static ServiceRoot = "/protis-react/api-debug";

    static ServiceURL = CoreConfig.ServiceProtocol + "://" + CoreConfig.ServiceHost + CoreConfig.ServiceRoot;
}
