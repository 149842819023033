import GenericScreen from "../GenericScreen";
import DocumentRequest from "../../../ProTIS/DocumentRequest";

class GenericDocuments extends GenericScreen {
    constructor(props, pageDefinitions) {
        super(props, pageDefinitions);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    handleDocumentImport = async (document_data, document_filename, document_language_code, document_language_full_name) => {
        let base64TagRemovedData = this.parseDataString(document_data, true);
        let dataType = this.parseImportDocumentType(document_data);

        if (dataType !== "converted") {
            let convertedDocument;

            if (dataType === "html") {
                convertedDocument = Buffer.from(base64TagRemovedData, 'base64').toString();
            } else {
                let conversionRequest = new DocumentRequest(this.core);
                let result = await conversionRequest.convertDocumentToHTML(base64TagRemovedData, dataType);
                convertedDocument = result ? result.document_data_html : console.error("Document Import Failed: Result is null");
            }

            let languageCode = document_language_code ? document_language_code : "en";
            let languageFullName = document_language_full_name ? document_language_full_name : "English";

            let resultString = {
                title: document_filename,
                content: convertedDocument,
                modified: new Date("2021-01-01 00:00:01"),
                content_language: languageCode,
                content_language_full_name: languageFullName,
                translationLanguages: [
                    {lang: languageCode, title: languageFullName},
                ],
            };

            return JSON.stringify(resultString);
        } else {
            return document_data;
        }

    };

    myPopupSubmitted = async (popupFieldData) => {
        popupFieldData.document_format_id = await this.getDataType(popupFieldData.document_data);

        let currentDate = new Date();

        popupFieldData.project_id = this.core.dbInfo.projectId;
        if (popupFieldData.registered === "") {
            popupFieldData.registered = currentDate.toISOString();
        }
        if (popupFieldData.updated === "") {
            popupFieldData.updated = currentDate.toISOString();
        }

        if (!popupFieldData.document_language_id || popupFieldData.document_language_id === "") {
            popupFieldData.document_language_id = 13;
        }

        if (popupFieldData.document_data && popupFieldData.document_data !== "") {
            popupFieldData.document_data = await this.handleDocumentImport(
                popupFieldData.document_data,
                popupFieldData.document_filename,
                popupFieldData.document_language_code,
                popupFieldData.document_language_full_name);
            delete popupFieldData.document_language_code;
            delete popupFieldData.document_language_full_name;
        } else if (popupFieldData.document_data && popupFieldData.document_data_uploader) {
            return null;
        }
        await this.myPopupSubmittedHandler(popupFieldData);
    };

    getDataType = async (document) => {
        if (!document) {
            return 1;
        } else {
            let documentSection = document.substr(0, 100);
            if (documentSection.substr(0, 52) === "data:application/vnd.oasis.opendocument.text;base64,") {
                return 2;
            } else if (documentSection.substr(0, 84) === "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,") {
                return 2;
            } else if (documentSection.substr(0, 23) === "data:text/plain;base64,") {
                return 2;
            } else if (documentSection.substr(0, 28) === "data:application/pdf;base64,") {
                return 3;
            } else if (documentSection.substr(0, 22) === "data:image/png;base64,") {
                return 4;
            } else if (documentSection.substr(0, 23) === "data:image/jpeg;base64,") {
                return 5;
            } else {
                console.error("Defaulting to 1");
                return 1;
            }
        }
    };

    async handleButtonClick(buttonName) {
        switch (buttonName) {
            case "view":
                await this.handleViewButton();
                break;
            case "print_barcode":
                await this.handlePrintBarcode();
                break;
            case "receive":
                await this.handleReceive();
                break;
            case "dispatch":
                await this.handleDispatch();
                break;
            case "return":
                await this.handleReturn();
                break;
            default:
                await super.handleButtonClick(buttonName);
                break;
        }
    }

    formGridDesignation = (gridIndex) => {
        if (gridIndex !== undefined) {
            return this.pageDefinitions[gridIndex].entityName + "/" + this.pageDefinitions[gridIndex].entityMethod;
        }
    };

    handleViewButton = async () => {
        let requestedData = await this.genericScreenController.buttonDataRequest(
            this.state.selectedRowData[this.formGridDesignation(0)],
            "view",
        );

        requestedData.document_format_id = this.state.selectedRowData[this.formGridDesignation(0)].document_format_id;
        requestedData.themeName = this.props.themeName;
        requestedData.languageCode = this.props.languageCode;

        this.props.history.push("/main/documentEditor", requestedData);
    };

    handlePrintBarcode = async () => {
        return this.genericScreenController.buttonDataRequest(
            this.state.selectedRowData[this.formGridDesignation(0)],
            "barcode",
            this.core,
        );
    };

    handleReceive = async () => {
        await this.genericScreenController.buttonDataRequest(
            this.state.selectedRowData[this.formGridDesignation(0)],
            "receive",
        );
        await this.updateEntityList();
    };

    handleDispatch = async () => {
        await this.genericScreenController.buttonDataRequest(
            this.state.selectedRowData[this.formGridDesignation(0)],
            "dispatch",
        );
        await this.updateEntityList();

    };

    handleReturn = async () => {
        await this.genericScreenController.buttonDataRequest(
            this.state.selectedRowData[this.formGridDesignation(0)],
            "return",
        );

        await this.updateEntityList();

    };

}


export default GenericDocuments;