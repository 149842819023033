import React from "react";
import EntityRequest from "../../ProTIS/EntityRequest";
import PrepareColumnsFromDefinitions from "../ReusableFunctions/PrepareColumnsFromDefinitions";
import DataGrid_Function from "../Tables/DataGrid_Function";
import UniversalPopupComponent from "../PopupAndComponents/UniversalPopupComponent";
import ButtonGenerator from "../ButtonGenerator";
import ScreenTranslation from "../../ProTIS/ScreenTranslation";
import GenericScreenFilter from "./Filters/GenericScreenFilter";
import GenericScreenController from "./GenericScreenController";
import SideButtonsAndGrids from "../../StyleConfigurations/SideButtonsAndGrids";
import MuiCustomPrompt from "../PopupAndComponents/MuiCustomPrompt";

class GenericScreen extends React.Component {
    state = {
        popupOpen: false,
        dialogOpen: false,
        filterValue: [],
        topGridName: "",
        selectedRowId: [],
        selectedRowData: [],
        translationRequest: [],
        translationId: "en:ScreenName",
    };


    constructor(props, pageDefinitions) {
        super(props);
        this.handleButtonClick = this.handleButtonClick.bind(this);

        this.core = props.core;

        this.pageDefinitions = pageDefinitions;

        this.translationData = [];
        this.currentTranslationId = "";
        this.translatedLabels = {};


        for (let translationLabels of pageDefinitions) {
            this.generateStaticLabelsForTranslation();
            this.getLabelsFromDefinition(translationLabels);
            this.getButtonLabels(translationLabels);
            this.translationData.push(translationLabels.title);
        }

        this.entityData = [];

        this.topGridEntity = this.pageDefinitions[0].entityName;
        this.topGridEntityMethod = this.pageDefinitions[0].entityMethod;

        this.genericScreenController = new GenericScreenController(
            this.core,
            this.topGridEntity,
            this.topGridEntityMethod,
            this.pageDefinitions,
        );

        this.promptActionTracker = "";
        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
        this.promptCancelOnly = true;

    }

    getButtonLabels(translationLabels) {
        if (translationLabels.buttons) {
            for (let buttonLabels of translationLabels.buttons) {
                this.translationData.push(buttonLabels);
            }
        }
    }

    getLabelsFromDefinition(translationLabels) {
        if (translationLabels.filters) {
            for (let filterLabels of translationLabels.filters) {
                this.translationData.push(filterLabels.label);
            }
        }
        if (translationLabels.viewColumns) {
            for (let viewColumnLabels of translationLabels.viewColumns) {
                if (viewColumnLabels.type !== "hidden") {
                    this.translationData.push(viewColumnLabels.label);
                }
            }
        }
    }

    generateStaticLabelsForTranslation() {
        let staticLabels = ["Remove data", "Do you want to delete selected data for "];
        for (let label of staticLabels) {
            this.translationData.push(label);
        }
    }

    updateEntityDataRender = (newEntityData) => {
        this.entityData = newEntityData;

        const date = new Date();
        let updated = date.getHours() + ":" + date.getMinutes() + ":" + date.getMilliseconds();
        this.setState({updatedState: updated});
    };

    labelTranslation = (label) => {
        if (this.translatedLabels.hasOwnProperty(label)) {
            return this.translatedLabels[label];
        }
        return label;
    };

    removeItemName() {
        return "";
    }

    gridProperties() {
        return {};
    }

    myPopupCancelled = () => {
        this.setState({
            popupOpen: false,
        });
    };

    formGridDesignation = (gridIndex) => {
        if (gridIndex !== undefined) {
            return this.pageDefinitions[gridIndex].entityName + "/" + this.pageDefinitions[gridIndex].entityMethod;
        }
    };

    async componentDidMount() {
        if (this.pageDefinitions) {
            if (Array.isArray(this.pageDefinitions) === true) {

                let refIndex = 0;
                for (let contentsOfpageDefinitions of this.pageDefinitions) {

                    let allStateChanges = {};

                    let index = refIndex;

                    if (index === 0) {
                        let firstGridName = contentsOfpageDefinitions.entityName;
                        this.setState({topGridName: firstGridName});
                    }

                    if (contentsOfpageDefinitions.filters) {

                        let newEntityData = {...this.entityData};


                        let ignoreFilterStatus = !(this.state.filterValue && index === 0);
                        let entityDataRequest = await this.requestEntityData(
                            contentsOfpageDefinitions.entityName,
                            contentsOfpageDefinitions.entityMethod,
                            ignoreFilterStatus,
                        );

                        if (index !== 0) {
                            entityDataRequest = await this.getDataForBottomGrids(contentsOfpageDefinitions);
                        }

                        newEntityData[this.formGridDesignation(index)] = entityDataRequest.records
                            ? entityDataRequest.records
                            : entityDataRequest;

                        this.updateEntityDataRender(newEntityData);

                        if (newEntityData[this.formGridDesignation(index)]
                            && newEntityData[this.formGridDesignation(index)].length > 0) {
                            this.setExtraGridRowData(index, newEntityData, allStateChanges);

                        }

                        this.setState(allStateChanges);
                    }

                    refIndex++;
                }

            }
        }
    }

    setExtraGridRowData(index, newEntityData, allStateChanges) {
        let newSelectedRowEntityId = {...this.state.selectedRowId};
        let newSelectedRowEntityData = {...this.state.selectedRowData};

        newSelectedRowEntityId[this.formGridDesignation(index)] = newEntityData[this.formGridDesignation(index)][0].id;
        newSelectedRowEntityData[this.formGridDesignation(index)] = newEntityData[this.formGridDesignation(index)][0];

        allStateChanges.selectedRowId = newSelectedRowEntityId;
        allStateChanges.selectedRowData = newSelectedRowEntityData;
    }

    async getDataForBottomGrids(contentsOfpageDefinitions) {
        if (this.state.selectedRowId[this.formGridDesignation(0)] !== undefined) {
            return this.genericScreenController.initialDataRequest(
                contentsOfpageDefinitions.entityName,
                this.state.selectedRowId[this.formGridDesignation(0)],
                this.pageDefinitions[1].entityMethod,
            );
        } else {
            return [];
        }
    }

    async componentDidUpdate(_prevProps, prevState, _snapshot) {
        const newObjectClass = "view:" + this.pageDefinitions[0].title;
        const newTranslationId = this.props.languageCode + ":" + newObjectClass;
        if (this.currentTranslationId !== newTranslationId) {
            this.currentTranslationId = newTranslationId;
            this.translatedLabels = await ScreenTranslation.translateLabels(
                "en",
                this.props.languageCode,
                newObjectClass,
                this.translationData);
            this.setState({translationId: newTranslationId});
        }
        if (prevState.filterValue !== this.state.filterValue) {
            await this.updateEntityList();
        }
    }


    async multiGridInitialRowSelection(topGrid, updatedEntityData) {
        /** Check if top grid data received is empty or contains data with rows **/

        let rowSelectData = updatedEntityData[this.formGridDesignation(0)][0]
            ? updatedEntityData[this.formGridDesignation(0)][0]
            : "empty";

        /** Check index and contents of top grid, selecting the first **/
        await this.handleRowSelect(rowSelectData, topGrid, 0);

        /** If screen contains multiple grids, request data for selected row, to display in bottom grid **/
        if (this.pageDefinitions.length > 1 && rowSelectData !== "empty") {
            let bottomGridData = await this.genericScreenController.bottomGridDataRequest(rowSelectData);

            updatedEntityData[this.formGridDesignation(1)] = bottomGridData.records;
        }
        /** If bottom grid has no data, assign an empty reference point. **/
        else if (this.pageDefinitions.length > 1 && rowSelectData === "empty") {
            updatedEntityData[this.formGridDesignation(1)] = [];
        }


        this.updateEntityDataRender(updatedEntityData);
    }

    handleRowSelect = async (selectedRow, parentEntity, index) => {
        let newSelectedRowId = {...this.state.selectedRowId};
        let newSelectedRowData = {...this.state.selectedRowData};

        let updatedGridData = {};

        if (selectedRow !== "empty") {
            let selectedRowData = selectedRow.row ? selectedRow.row : selectedRow;

            if (this.pageDefinitions.length > 1 && index === 0) {
                if (this.pageDefinitions[0].entityName === parentEntity.entityName) {
                    let bottomGridData = await this.genericScreenController.handleRowSelect(selectedRowData, parentEntity.entityName);

                    let newGridData = {...this.entityData};

                    newGridData[this.formGridDesignation(1)] =
                        bottomGridData ? bottomGridData.records
                            : console.error("Bottom Grid Data not found: Returned value is NULL");

                    this.updateEntityDataRender(newGridData);
                }
            }


            newSelectedRowId[this.formGridDesignation(index)] = selectedRowData.id;
            newSelectedRowData[this.formGridDesignation(index)] = selectedRowData;
        } else {
            newSelectedRowId[this.formGridDesignation(index)] = 1;
            newSelectedRowData[this.formGridDesignation(index)] = "";
        }

        updatedGridData.selectedRowData = newSelectedRowData;
        updatedGridData.selectedRowId = newSelectedRowId;

        this.setState(
            updatedGridData,
        );
    };

    async handleButtonClick(buttonName) {
        let topGridEntity = this.formGridDesignation(0);
        switch (buttonName) {
            case "add":
            case "edit":
                let rowUpdate = {};
                let updatedRowData = await this.getUpdatedRowData(this.state.selectedRowId[topGridEntity]);
                rowUpdate[this.formGridDesignation(0)] = updatedRowData;
                this.setState({
                    popupEditState: buttonName,
                    popupOpen: true,
                    selectedRowData: rowUpdate,
                });
                break;
            case "remove":
                this.handleRemovePrompt();
                break;
            case "refresh":
                await this.updateEntityList();
                break;
            default:
                console.error("Invalid button:", buttonName);
        }
    }

    generateDataGrid = () => {
        /** Use the designated Definition Section (viewColumns for static in this case))
         *  within pageDefinitions to convert incoming server data into usable columns for the datagrid component.
         * **/
        let renderedGrids = [];

        let generatorIndex = 0;

        let buttonsDisabled = this.determineDisabledButtons();

        for (let singleGridSpecifications of this.pageDefinitions) {
            let usableColumns = PrepareColumnsFromDefinitions(singleGridSpecifications.viewColumns, this.translatedLabels);

            let entityData = this.entityData ?
                this.entityData[this.formGridDesignation(generatorIndex)] : [];

            if (this.entityData && this.entityData[this.formGridDesignation(generatorIndex)]) {
                let generatedButtons = [];
                if (generatorIndex === 0) {
                    generatedButtons = singleGridSpecifications.buttons;
                }

                if (generatorIndex !== 0) {
                    entityData = this.entityData[this.formGridDesignation(generatorIndex)];
                }

                if (entityData.length > 0) {
                    this.generateWorkingGridView(generatorIndex, renderedGrids, entityData, usableColumns, generatedButtons, buttonsDisabled);
                } else {
                    this.generateFallbackGridView(generatorIndex, renderedGrids, entityData, usableColumns, generatedButtons, buttonsDisabled);
                }
                generatorIndex++;

            }
        }
        return renderedGrids;
    };

    generateWorkingGridView(generatorIndex, renderedGrids, entityData, usableColumns, generatedButtons, buttonsDisabled) {
        let gridIndex = generatorIndex;
        let parentEntity = this.pageDefinitions[generatorIndex];
        const gridProperties = this.gridProperties();
        let gridCssStyle = this.pageDefinitions.length > 1 ? "MultiGridElements" : "DataGridElement";
        let minHeight = gridCssStyle === "DataGridElement" ? "80vh" : "35vh";

        let gridDivStyle = {
            display: "grid",
            gridTemplateRows: "1fr",
            width: gridProperties.gridWidth,
            minHeight: minHeight,
        };

        renderedGrids.push(
            <div id={gridCssStyle} key={generatorIndex} style={SideButtonsAndGrids.GridViewportStyle}>
                <div style={gridDivStyle}>
                    <DataGrid_Function
                        sourceData={entityData}
                        displayColumns={usableColumns}
                        onRowClick={(selectedRow) => this.handleRowSelect(selectedRow, parentEntity, gridIndex)}
                        hideFooter={true}
                        firstSelection={this.state.selectedRowId[this.formGridDesignation(gridIndex)]}
                        headerHeight={gridProperties.rowHeight}
                        rowHeight={gridProperties.rowHeight}
                    />
                </div>
                <div>
                    <ButtonGenerator
                        buttonsToGenerate={generatedButtons}
                        disabledButtons={buttonsDisabled}
                        buttonStyle={SideButtonsAndGrids.IndividualButtonStyle}
                        buttonTheme={this.props.themeName}
                        divParameters={SideButtonsAndGrids.ButtonDivStyle}
                        onClickEvent={(buttonName) => this.handleButtonClick(buttonName)}
                        buttonTextTranslation={this.translatedLabels}
                    />
                </div>
            </div>,
        );
    }

    generateFallbackGridView(generatorIndex, renderedGrids, entityData, usableColumns, generatedButtons, buttonsDisabled) {
        let gridIndex = generatorIndex;
        let parentEntity = this.pageDefinitions[generatorIndex];
        const gridProperties = this.gridProperties();
        let gridCssStyle = this.pageDefinitions.length > 1 ? "MultiGridElements" : "DataGridElement";
        let minHeight = gridCssStyle === "DataGridElement" ? "80vh" : "35vh";

        let gridDivStyle = {
            display: "grid",
            gridTemplateRows: "1fr",
            width: gridProperties.gridWidth,
            minHeight: minHeight,
        };

        renderedGrids.push(
            <div key={generatorIndex} style={SideButtonsAndGrids.GridViewportStyle}>
                <div style={gridDivStyle}>
                    <DataGrid_Function
                        sourceData={entityData}
                        displayColumns={usableColumns}
                        onRowClick={(selectedRow) => this.handleRowSelect(selectedRow, parentEntity, gridIndex)}
                        hideFooter={true}
                        firstSelection={this.state.selectedRowId[this.formGridDesignation(gridIndex)]}
                        headerHeight={gridProperties.rowHeight}
                        rowHeight={gridProperties.rowHeight}
                    />
                </div>
                <div>
                    <ButtonGenerator
                        buttonsToGenerate={generatedButtons}
                        disabledButtons={buttonsDisabled}
                        buttonStyle={SideButtonsAndGrids.IndividualButtonStyle}
                        buttonTheme={this.props.themeName}
                        divParameters={SideButtonsAndGrids.ButtonDivStyle}
                        onClickEvent={(buttonName) => this.handleButtonClick(buttonName)}
                        buttonTextTranslation={this.translatedLabels}
                    />
                </div>
            </div>,
        );
    }

    determineDisabledButtons() {
        let buttonStatusReference = this.state.selectedRowData[this.formGridDesignation(0)]
            ? this.state.selectedRowData[this.formGridDesignation(0)].document_state_name
            : "";
        let buttonsDisabled = [];

        if (buttonStatusReference === "Processing") {
            buttonsDisabled = ["Receive"];
        }
        if (buttonStatusReference === "Transfer") {
            buttonsDisabled = ["Dispatch", "Return"];
        }
        return buttonsDisabled;
    }

    handleDialogClose = (confirmation) => {
        this.setState({dialogOpen: confirmation});

        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
        this.requireInputField = false;
        this.dialogSubmittedInput = "";
    };

    handleDialogSubmit = async (response) => {
        if (response === true) {
            if (this.promptActionTracker === "remove_data") {
                await this.removeRowData();
            }
        } else {
            return null;
        }
    };

    removeRowData = async () => {
        let updatedEntityData = await this.genericScreenController.removeEntityFromList(
            this.state.selectedRowId[this.formGridDesignation(0)],
            this.state.filterValue,
        );
        await this.multiGridInitialRowSelection(this.topGridEntity, updatedEntityData);
        this.setState({dialogOpen: false});

        this.promptActionTracker = "";
        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
    };


    handleRemovePrompt() {
        this.promptActionTracker = "remove_data";
        this.dialogTitle = "Remove data";
        this.dialogText = this.labelTranslation("Do you want to delete selected data for ") + this.removeItemName() + " ?";
        this.errorType = "warning";
        this.promptCancelOnly = false;

        this.setState({dialogOpen: true});
    }

    requestEntityData = async () => {

        let receivedEntityData = await this.genericScreenController.updateEntityList(this.state.filterValue);

        return receivedEntityData[this.formGridDesignation(0)];
    };

    updateEntityList = async () => {

        let updatedEntityData = await this.genericScreenController.updateEntityList(this.state.filterValue);

        await this.multiGridInitialRowSelection(this.topGridEntity, updatedEntityData);
    };

    myPopupSubmitted = async (popupFieldData) => {
        await this.myPopupSubmittedHandler(popupFieldData);
    };

    convertDocumentData = (document_data, document_format_id) => {
        switch (document_format_id) {
            case 1:
                return document_data;
            case 2:
            case 3:
            case 4:
            case 5:
                let base64position = document_data.substr(0, 100).search(";base64,");
                return document_data.substr(base64position + 8);
            default:
                return document_data;
        }

    };

    /** Document Processing section, to prepare for proper display. */

    parseDataString = (documentData, isDocument) => {
        let base64Position = isDocument ? documentData.substr(0, 100).search(";base64,") :
            documentData.substr(0, 30).search(";base64,");
        if (base64Position !== -1) {
            return documentData.substr(base64Position + 8);
        } else {
            return documentData;
        }
    };

    parseImportDocumentType = (documentData) => {
        if (documentData.substring(0, 52) === "data:application/vnd.oasis.opendocument.text;base64,") {
            return "odt";
        } else if (documentData.substring(0, 84) === "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,") {
            return "docx";
        } else if (documentData.substring(0, 22) === "data:text/html;base64,") {
            return "html";
        } else {
            return "converted";
        }
    };

    triggerPlaceholderError = (requestError) => {
        if (requestError === null) {
            requestError = "Invalid or empty input";
        }
        this.promptActionTracker = "generic_error";
        this.dialogTitle = "Placeholder";
        this.dialogText = requestError;
        this.errorType = "warning";
        this.promptCancelOnly = true;

        this.setState({dialogOpen: true});
    };

    screenSpecificSubmitInfo = () => {
        return {};
    };

    myPopupSubmittedHandler = async (popupFieldData) => {
        let topMenuEntity = this.pageDefinitions[0].entityName;
        let topMenuEntityMethod = this.pageDefinitions[0].entityMethod;

        const entityRequest = new EntityRequest(this.core, topMenuEntity);

        let screenSpecificData = this.screenSpecificSubmitInfo();
        let record = {...popupFieldData, ...screenSpecificData};
        let result;

        let requestError = null;
        if (this.state.popupEditState === 'add') {
            delete record['id'];
            result = await entityRequest.create(record,
                (error) => {
                    requestError = error;
                });
            if (result !== null) {
                record["id"] = result ? result.result.new_record_id : console.error("ID in record failed: Returned value is NULL");
            }
        } else if (this.state.popupEditState === 'edit') {
            result = await entityRequest.update(record,
                (error) => {
                    requestError = error;
                });
        } else {
            console.error("Could not find function", this.state.popupEditState);
        }

        if (result === null) {
            this.triggerPlaceholderError(requestError);
        } else {
            await this.continueSubmissionProcess(topMenuEntity, topMenuEntityMethod, record);
        }
    };

    async continueSubmissionProcess(topMenuEntity, topMenuEntityMethod, record) {
        let updatedEntityData = {...this.entityData};

        let entityData = await this.requestEntityData(topMenuEntity, topMenuEntityMethod);

        updatedEntityData[this.formGridDesignation(0)] = entityData;

        let updatedSelectedRowData = {...this.state.selectedRowData};

        let allStateUpdates = [];

        if (record.id) {
            let updateSelectedRowId = {...this.state.selectedRowId};
            updateSelectedRowId[topMenuEntity] = record.id;

            for (let recordData of entityData) {
                if (record.id === recordData.id) {
                    updatedSelectedRowData[this.formGridDesignation(0)] = recordData;
                }
            }
            allStateUpdates.selectedRowId = updateSelectedRowId;

        } else {
            updatedSelectedRowData[this.formGridDesignation(0)] = record;
        }

        this.updateEntityDataRender(updatedEntityData);

        allStateUpdates.selectedRowData = updatedSelectedRowData;
        allStateUpdates.popupOpen = false;

        this.setState(allStateUpdates);


        if (record.id) {
            await this.handleRowSelect(updatedSelectedRowData[this.formGridDesignation(0)], topMenuEntity, 0);
        }
    }

    getUpdatedRowData = async (rowID) => {
        let updatedRowRequest = new EntityRequest(this.core, this.pageDefinitions[0].entityName);
        let rowData = await updatedRowRequest.getList({id: rowID});
        return rowData ? rowData.records[0] : console.error("Row Data failed: Returned value is NULL");
    };

    render() {

        const popupPageDefinitions = {
            popupWindow: {
                title: this.pageDefinitions[0].title,
                height: "400px",
                width: "600px",
            },
            gridColumns: this.pageDefinitions[0].viewColumns,
        };

        const topDivStyle = {
            display: 'grid',
            gridTemplateRows: "1fr",
            margin: " 0px 10px 0px 10px",
        };

        let selectedRowData = this.state.selectedRowData[this.formGridDesignation(0)];

        const dataGridComponent = this.generateDataGrid();
        const popupRowData = this.state.popupEditState === "add" ? null : selectedRowData;
        const filterData = this.pageDefinitions[0].filters ? this.pageDefinitions[0].filters : {};
        const filterGridReference = {
            entityName: this.pageDefinitions[0].entityName,
            entityMethod: this.pageDefinitions[0].entityMethod,
        };

        let dialogFallback = this.state.dialogOpen === undefined ? false : this.state.dialogOpen;

        return (

            <div style={topDivStyle}>
                <UniversalPopupComponent
                    core={this.core}
                    popupDefinition={popupPageDefinitions}
                    popupValues={popupRowData}
                    onSubmit={this.myPopupSubmitted}
                    onCancel={this.myPopupCancelled}
                    open={this.state.popupOpen}
                    buttonTheme={this.props.themeName}
                    translatedLabels={this.translatedLabels}
                    languageCode={this.props.languageCode}
                />
                <div style={{marginTop: "10px"}}>
                    {this.labelTranslation(this.pageDefinitions[0].title)}
                </div>
                <div style={{padding: "20px", outline: "black", backgroundColor: "lightgrey"}}>
                    <GenericScreenFilter
                        core={this.core}
                        onNewFilterValue={(filterValue) => {
                            this.setState({filterValue: filterValue});
                        }}
                        translatedLabels={this.translatedLabels}
                        filterData={filterData}
                        topGridEntityRef={filterGridReference}
                        languageCode={this.props.languageCode}
                    />
                </div>
                <div>
                    {dataGridComponent}
                    <div>
                        {/*placeholder for possible error messages*/}
                    </div>
                </div>

                <MuiCustomPrompt
                    open={dialogFallback}
                    title={this.labelTranslation(this.dialogTitle)}
                    displayedText={this.labelTranslation(this.dialogText)}
                    buttonTheme={this.props.themeName}
                    cancelOnly={this.promptCancelOnly}
                    showContextImage={this.errorType}
                    onCancel={(confirmation) => this.handleDialogClose(confirmation)}
                    onSubmit={(response) => this.handleDialogSubmit(response)}
                />
            </div>
        );
    }

}

export default GenericScreen;