import React from 'react';

/**
 * Flexible combo-box with multiple input parameters
 **/

class DataCombobox extends React.Component {

    defaultValue = {};

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        if (!this.props.value) {
            this.assignDefaultValue();
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (!this.props.value) {
            this.assignDefaultValue();
        }
        if (prevProps.value !== this.props.value && this.props.value !== undefined) {
            let newValueObject = this.updateValueObject(this.props.value, this.props.valueField);
            this.selectOnChangeType(newValueObject);
        }
    }

    updateValueObject = (value, valueField) => {
        for (let object of this.props.dropdownOptions) {
            if (object[valueField] === value) {
                return object;
            } else {
                console.error("No object matches:", value, "for", valueField);
            }
        }
    };

    selectOnChangeType = (object) => {
        if (object) {
            if (object.hasOwnProperty("value")) {
                this.props.onChange(object["value"]);
            } else {
                this.props.onChange(object);
            }
        }
    };

    assignDefaultValue = () => {
        if (this.props.dropdownOptions.length > 0) {
            let newSelectedOption = this.props.dropdownOptions[0];
            if (JSON.stringify(this.defaultValue) !== JSON.stringify(newSelectedOption)) {
                this.defaultValue = newSelectedOption;
                this.selectOnChangeType(newSelectedOption);
            }
        }
    };

    handleChange = (params) => {
        const newSelectedIndex = params.target.options.selectedIndex;
        const newSelectedOption = this.props.dropdownOptions[newSelectedIndex];

        if (newSelectedOption.hasOwnProperty("value")) {
            this.props.onChange(newSelectedOption["value"]);
        } else {
            this.props.onChange(newSelectedOption);
        }
    };

    render() {
        /**
         * Combobox requires:
         * valueField to be used as Key and Value.
         * textField to be used as the text to display within the combobox.
         **/

        let index = 0;

        let defaultValue = this.props.value ? this.props.value : this.props.dropdownOptions[0];

        const renderedOptions = this.props.dropdownOptions.map(option => {
            if (this.props.valueField === '' && this.props.textField === '') {
                index++;
                return <option
                    key={index}
                    value={option}
                >
                    {option}
                </option>;
            } else {
                index++;
                return <option
                    key={index}
                    value={option[this.props.valueField]}>
                    {option[this.props.textField]}
                </option>;
            }
        });

        return (
            <select
                title={this.props.title}
                value={defaultValue}
                onChange={this.handleChange}>
                {renderedOptions}
            </select>
        );
    }
}

export default DataCombobox;