import TranslationEditor from "./TranslationEditor";

class OptimizeDocument {
    static removeUnusedTranslations = (sourceDocument) => {
        // Assign our document to innerHTML
        const element = document.getElementById("HtmlParser");
        element.innerHTML = sourceDocument.content;

        // Make an array of all md5s used in document
        let usedMD5s = [];
        for (let node of element.querySelectorAll("P,LI,TD")) {
            if (node.textContent !== "") {
                let md5 = TranslationEditor.textElementMd5(node.innerHTML);
                usedMD5s.push(md5);
            }
        }

        // Remove all translations that are not in usedTranslations
        let existingTranslations = sourceDocument.translations;
        for (let existingMD5 in existingTranslations) {
            if (usedMD5s.indexOf(existingMD5) === -1) {
                delete existingTranslations[existingMD5];
            }
        }
    };

}

export default OptimizeDocument;