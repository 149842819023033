import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/menuProject';

const document_set_data = constDefinitions.clientDocumentSetsView;

class DocumentSetData extends GenericScreen {
    constructor(props) {
        super(props, document_set_data);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        return this.state.selectedRowData["document_set/list"].document_set_name;
    }

    screenSpecificSubmitInfo = () => {
        if (this.state.filterValue && this.state.filterValue.client_id) {
            return {
                client_id: this.state.filterValue.client_id,
            };
        } else {
            return {};
        }

    };

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default DocumentSetData;
