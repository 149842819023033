import React from 'react';
import TextField from '@mui/material/TextField';
import {DateTimePicker} from '@mui/x-date-pickers';

export default function DateTimePickerComponent(props) {

    const [value, setValue] = React.useState(props.initialValue);
    const onDatePicked = (event) => {
        setValue(event);
        let onlyDate = event.$d.toISOString();

        props.onChange(onlyDate);
    };

    React.useEffect(() => {
        if (typeof value === "undefined") {
            let currentDate = new Date();
            setValue(currentDate);
        }

        if (value !== 0 && value === null) {
            setValue(props.initialValue);
        }
    }, [props.initialValue, value, setValue]);

    return (
        <DateTimePicker
            label={props.label}
            value={value}
            onChange={onDatePicked}
            renderInput={(params) => <TextField {...params} />}
            InputProps={{style: {height: "1.4em"}}}
        />
    );
}