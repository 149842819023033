import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/SystemUsers.js';

const systemUsersView = constDefinitions.protisUsers;

class SystemUsers extends GenericScreen {
    constructor(props) {
        super(props, systemUsersView);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        let item1 = this.state.selectedRowData["user/list"].first_name;
        let item2 = this.state.selectedRowData["user/list"].last_name;
        return item1 + " " + item2;
    }

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default SystemUsers;