import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/menuProject';

const user_action_data = constDefinitions.userActionHistoryView;

class UserActionData extends GenericScreen {
    constructor(props) {
        super(props, user_action_data);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        return this.state.selectedRowData["user_action/list"].user_name;
    }

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default UserActionData;
