export default class SideButtonsAndGrids {

    static ButtonDivStyle = {
        width: '55px',
        marginBottom: '40px',
        marginLeft: "5px",
        marginRight: "5px",
    };

    static IndividualButtonStyle = {
        width: '100px',
        margin: '5px',
        height: '38px',
        display: 'flex',
        alignItems: "center",
        justifyContent: "left",
    };

    static GridViewportStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 125px',
        gridGap: '5px 20px 5px 0px',
        gridTemplateRows: "1fr 20px",
        height: "1fr",
    };

    static EditorGridViewportStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 125px',
        gridGap: '5px 20px 5px 0px',
        gridTemplateRows: "1fr 20px",
        height: "1fr",
        marginRight: "10px",
    };
}