import React from "react";
import {useGridApiRef} from "@mui/x-data-grid";
import {DataGridPro, LicenseInfo} from '@mui/x-data-grid-pro';
import "../../index.css";

LicenseInfo.setLicenseKey('ab85337002a5e9db7bed4dc028f04882T1JERVI6Mjk4NzksRVhQSVJZPTE2NjQwNTI0NDkwMDAsS0VZVkVSU0lPTj0x');

function DataGrid_Function(props) {
    const apiRef = useGridApiRef();
    const [selectedRow, newSelectedRow] = React.useState(props.firstSelection);
    const [rows, setRows] = React.useState({rows: []});
    const [columns, setColumns] = React.useState({columns: props.displayColumns});
    const rowSelectionData = (selectedRowData) => {
        newSelectedRow({selectedRow: selectedRowData.row.id});
        props.onRowClick(selectedRowData);
    };
    let rowHeight;
    if (props.rowHeight === "hidden") {
        rowHeight = 0;
    } else if (props.rowHeight === "default") {
        rowHeight = 56;
    } else {
        rowHeight = props.rowHeight;
    }
    let headerHeight;
    if (props.headerHeight === "hidden") {
        headerHeight = 0;
    } else if (props.headerHeight === "default") {
        headerHeight = 56;
    } else {
        headerHeight = props.headerHeight;
    }


    React.useEffect(() => {
        if (selectedRow !== "") {
            if (rows.rows.length > 0) {
                apiRef.current.selectRow(props.firstSelection, true, false);

            }
        } else {
            /** If for some reason firstSelection comes late and the <empty string> check leads down here
             * we will force set firstSelection as selected row, re-triggering this Effect and rendering
             * the first selected row forcibly.**/


            newSelectedRow(props.firstSelection);
        }
    }, [rows, selectedRow, apiRef, props.firstSelection]);

    React.useEffect(() => {
        if (props.sourceData !== 0) {
            setColumns({columns: props.displayColumns});
            setRows({rows: props.sourceData});
        } else {
            setColumns({columns: props.displayColumns});
        }
    }, [props.sourceData, props.displayColumns]);

    return <DataGridPro
        apiRef={apiRef}
        rows={rows.rows}
        columns={columns.columns}
        onRowClick={rowSelectionData}
        hideFooter={props.hideFooter}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        sx={{
            color: '#fff',
        }}
    />;


}

export default DataGrid_Function;