/**
 * ProTIS Core owner-related requests
 *
 * Owner database record includes:
 * id		                    int (omit if creating or unknown)
 * owner_id		                int (reference to owner ID)
 * title		                string
 * connect		                string (type of connection or protocol to invoke for server)
 * schema		                string (top level root directory, only/top most, cannot contain any inside it)
 */
import EntityRequest from "./EntityRequest";

export default class OwnerDatabaseRequest extends EntityRequest {
    constructor (coreRequest) {
        super(coreRequest, "owner_database", true);
    }
}