export const popupFileUploader =
    {
        title: "Import File",
        viewColumns: [
            {label: "Title", name: "title", type: "text"},
            {
                label: "Language:",
                name: "source_language",
                type: "datacombo",
                definition: {
                    entityName: "language", entityMethod: "list",
                    requestParameters: {},
                    valueField: "language_code",
                    labelField: "language_full_name",
                },
            },
            {label: "Document:", name: "document_data", type: "file"},
        ],
    };

export const popupChangeSourceLanguage =
    {
        title: "Change Original Document Language",
        viewColumns: [
            {
                label: "Language:",
                name: "source_language",
                type: "datacombo",
                definition: {
                    entityName: "language", entityMethod: "list",
                    requestParameters: {},
                    valueField: "language_code",
                    labelField: "language_full_name",
                    defaultValue: "",
                },
            },
        ],
    };

export const popupAddNewTranslationLanguage =
    {
        title: "Add new translation language to document",
        viewColumns: [
            {
                label: "Language:",
                name: "source_language",
                type: "datacombo",
                definition: {
                    entityName: "language", entityMethod: "list",
                    requestParameters: {},
                    valueField: "language_code",
                    labelField: "language_full_name",
                },
            },
        ],
    };