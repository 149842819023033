import React from 'react';
import JoditEditor from "jodit-pro-react";
import MD5 from "./md5";
import DataCombobox from "../DataCombobox";
import TransformDocument from "./TransformDocument";
import ScreenTranslation from "../../ProTIS/ScreenTranslation";
import LanguageRequest from "../../ProTIS/LanguageRequest";
import CoreRequest from "../../ProTIS/CoreRequest";
import UniversalPopupComponent from "../PopupAndComponents/UniversalPopupComponent";
import * as popupTypeDefinitions from "../LocalDefinitions/individualPopupDefinitions";

export default class TranslationEditor extends React.Component {
    jodit = null;
    editor = {};
    joditPreviewer = null;
    previewEditor = {};
    joditTranslator = null;

    currentTextElementNode = null;
    currentContent = "";
    translationPreview = "";
    currentTextElementText = null;
    currentTextElementProperties = {};
    fullLanguageList = [];
    newTranslationLanguage = {};
    temporaryImageHolder = [];
    selectedTranslationLanguage = "en";
    documentChanged = this.props.documentChanged;
    defaultTextTracker = false;

    state = {
        content: '',
        translatedContentPreview: '',
        currentParagraph: '',
        translationLanguage: 'en',
        translations: {},
        languageCombobox: "",
        defaultTranslation: false,
        documentTranslationLanguages: [],
        popupOpen: false,
        paragraph: "",
    };

    constructor(props) {
        super(props);

        this.state.content = props.document.content;
        if (Array.isArray(props.languages) && props.languages[0].hasOwnProperty("lang")) {
            this.selectedTranslationLanguage = props.languages[0].lang;
        }

        this.core = CoreRequest.Instance;
        this.popupTypeDefinitions = popupTypeDefinitions;
        this.currentPopupDefinition = {
            popupWindow: {
                title: "initializing",
                height: "200px",
                width: "400px",
            },
            gridColumns: [
                {label: "example", name: "example", type: "hidden"},
            ],
        };

    }

    get currentTextElement() {
        if (this.jodit.selection.range.startContainer.parentNode === null) {
            return '';
        }

        let parentNode = this.jodit.selection.range.startContainer;
        const matchTopTextElementNames = /^(P|LI|TD|H\d+)$/;
        if (parentNode.nodeName !== null) {
            while (!matchTopTextElementNames.test(parentNode.nodeName)) {
                if (!parentNode.parentNode || !parentNode.parentNode.nodeName) {
                    break;
                }
                parentNode = parentNode.parentNode;
            }
        }

        return parentNode;
    }

    triggerRerender = () => {
        const date = new Date();
        let updated = date.getHours() + ":" + date.getMinutes() + ":" + date.getMilliseconds();
        this.setState({updated: updated});
    };

    componentDidMount = async () => {
        let languages = new LanguageRequest(CoreRequest.Instance);
        let languageList = await languages.getList();
        this.fullLanguageList = languageList.records;
        this.props.fullLanguageList(languageList.records);

        let documentContent = "";
        let documentTranslations = {};
        if (this.props.document) {
            if (this.props.document.content) {
                documentContent = this.props.document.content;
                documentTranslations = this.props.document.translations;
            }
        }
        this.translationPreview = this.props.translationLabels["Click Preview to translate document."];
        this.setState({
            documentTranslationLanguages: this.props.languages,
            content: documentContent,
            translations: documentTranslations,
            currentParagraph: this.props.translationLabels["Click editor above to start translating paragraphs"],
        });
        this.defaultTextTracker = true;
    };

    async componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.translationLabels !== this.props.translationLabels) {
            if (this.defaultTextTracker === true) {
                this.translationPreview = this.props.translationLabels["Click Preview to translate document."];
                this.setState({
                    currentParagraph: this.props.translationLabels["Click editor above to start translating paragraphs"],
                });
            }

        }
        if (prevProps.document !== this.props.document
            && prevProps.importDateCheck !== this.props.importDateCheck) {
            if (this.props.document && this.props.document.content) {
                this.state.content = this.props.document.content;
            }
            this.translationPreview = this.props.translationLabels["Click Preview to translate document."];
            this.joditTranslator.setReadOnly(true);
            this.setState({
                documentTranslationLanguages: this.props.document.translationLanguages,
                currentParagraph: this.props.translationLabels["Click editor above to start translating paragraphs"],
                translations: {},
                translationLanguage: this.props.document.translationLanguages[0].lang,
            });
            this.defaultTextTracker = true;
            this.triggerRerender();
        }
        if (this.props.defaultTranslation === true) {
            await this.translateText();
            this.props.defaultTranslationOff(false);
        }
        if (this.props.translationPreview === true) {
            let content = this.state.content;
            await this.generateTranslationPreview(content);
            this.props.enablePreview(false);
        }
        if (this.props.document !== prevProps.document
            // && this.props.document.documentChanged
            // && this.props.document.documentChanged !== false
        ) {
            this.documentChanged = false;
        }
    }

    disableDefaultTextOverride = () => {
        if (this.defaultTextTracker === true) {
            this.defaultTextTracker = false;
        }
    };


    inputControl = async (event) => {
        let inputValue = event.target ? event.target.value : event;

        if (this.documentChanged !== true) {
            this.props.documentChanged(true);
        }

        this.disableDefaultTextOverride();


        this.setState({
            paragraph: inputValue,
            currentParagraph: inputValue,
        });


        await this.onModifyTranslatedText(
            this.currentTextElementProperties.md5,
            this.currentTextElementProperties.language,
            inputValue);
    };

    /**
     * Compute MD5 of the HTML text by stripping all HTML tags and then computing MD5 of the plain text
     * @param textElementHtml
     * @returns {string}
     */
    static textElementMd5(textElementHtml) {
        const cleanText = textElementHtml.trim();
        return MD5.hex_md5(cleanText);
    }

    removeImageFromText = (textWithImages) => {
        /**
         * must match tag with | <img src="..." ... "> | Must end with "> for each img tag.
         * some cases have src=\"..."
         */
        let imageFreeText = textWithImages;
        const matchImgTag = new RegExp(/<img src=[^>]+>/i);
        let imageCounter = 0;

        let found;
        this.temporaryImageHolder = [];
        while ((found = imageFreeText.match(matchImgTag))) {
            this.temporaryImageHolder.push(found);
            imageFreeText = imageFreeText.replace(found, "<img id='data" + imageCounter + "'>");
            imageCounter++;
        }

        this.placeImageBackIntoText(imageFreeText);
        return imageFreeText;

    };

    placeImageBackIntoText = (textWithoutImage) => {

        // Debugging only
        if (textWithoutImage === undefined) {
            return "ERROR";
        }

        let textWithImages = textWithoutImage;
        let imageCounter = 0;
        let matchReplacedImage = new RegExp(/<img id='data[^>]+>/i);

        let found;
        while ((found = textWithImages.match(matchReplacedImage))) {
            textWithImages = textWithImages.replace(found, this.temporaryImageHolder[imageCounter]);
            imageCounter++;
        }
        return textWithImages;
    };

    fetchTranslation = (md5) => {
        if (this.state.translations) {
            let translationMd5 = this.state.translations[md5];
            if (translationMd5 && translationMd5[this.selectedTranslationLanguage]) {
                return translationMd5[this.selectedTranslationLanguage];
            }
        }
        return null;
    };


    onModifyTranslatedText = async (md5, language, newText) => {
        // Modify existing translation
        let newTranslations = {...this.state.translations};
        let md5Translation = newTranslations[md5];
        md5Translation[language] = newText;

        this.setState({
            translations: newTranslations,
        });
    };

    compareMD5Translation = async (textContent, disableServerTranslation) => {
        let md5 = TranslationEditor.textElementMd5(textContent);
        let previewText;
        let translationLanguage = this.selectedTranslationLanguage;
        if (this.state.translations && this.state.translations[md5]) {
            const existingTranslation = this.state.translations[md5][translationLanguage];
            if (existingTranslation && existingTranslation.length > 0) {
                return existingTranslation;
            }
        } else if (!disableServerTranslation) {
            previewText = await this.translateParagraph(translationLanguage, textContent);
            return previewText;
        }
        return null;
    };

    removeJoditSpanTag = (paragraph) => {
        if (paragraph === undefined) {
            return undefined;
        }

        let regex = /<span id="jodit-[^>]+>([^<]*)<\/span>/;
        return paragraph.replace(regex, "$1");
    };

    translateParagraph = async (language, text, requestOriginalTranslation) => {
        let reqOrgTranslation = requestOriginalTranslation ? requestOriginalTranslation : false;
        if (text === undefined) {
            return text;
        }

        let joditSpanRemovedText = this.removeJoditSpanTag(text).replace(/[\n\r\t]+/g, "");

        let md5 = TranslationEditor.textElementMd5(joditSpanRemovedText);

        let translatedText = "";

        this.currentTextElementProperties.md5 = md5;
        this.currentTextElementProperties.language = language;

        if (joditSpanRemovedText && md5) {
            translatedText = this.fetchTranslation(md5);
        }

        let translationMD5 = {...this.state.translations};
        let imageFreeText = await this.removeImageFromText(joditSpanRemovedText);
        let finalTranslationText = translationMD5[md5];
        if (finalTranslationText === undefined) {
            translationMD5[md5] = {};
            finalTranslationText = translationMD5[md5];
        }


        if (reqOrgTranslation) {
            translatedText = await this.props.onTranslate(language, imageFreeText);
            finalTranslationText[language] = await this.placeImageBackIntoText(translatedText);
            translationMD5[md5] = finalTranslationText;

            this.setState({
                translations: translationMD5,
            });
        }

        if (md5 && translatedText === null && this.props.onTranslate) {
            // Existing translation not found in the state.translations
            translatedText = await this.props.onTranslate(language, imageFreeText);
            finalTranslationText[language] = await this.placeImageBackIntoText(translatedText);
            translationMD5[md5] = finalTranslationText;

            this.setState({
                translations: translationMD5,
            });
        }

        this.props.appendNewTranslationData(this.state, "translations", this.currentContent);

        return this.placeImageBackIntoText(translatedText);
    };

    generateTranslationPreview = async (sourceDocument) => {
        // Assign our document to innerHTML
        const element = document.getElementById("HtmlParser");
        element.innerHTML = sourceDocument;

        let nodesSendForTranslation = [];
        let textSentForTranslation = [];

        const transformDocument = new TransformDocument(element);
        transformDocument.encodeForTranslation();

        const translateNodeNames = "P,LI,TD,H1,H2,H3,H4,H5";
        for (let node of element.querySelectorAll(translateNodeNames)) {
            if (node.parentNode && translateNodeNames.indexOf(node.parentNode.nodeName) !== -1) {
                continue;
            }

            const cleanedContent = node.innerHTML.replace(/[\n\r\t]+/g, "");
            if (node.textContent.replace(/\s+/g, "") !== "") {
                let matchingTranslation = await this.compareMD5Translation(cleanedContent, true);
                if (matchingTranslation === null) {
                    nodesSendForTranslation.push(node);
                    textSentForTranslation.push(cleanedContent);
                } else {
                    node.innerHTML = matchingTranslation;
                }
            }
        }

        let translationLanguage = this.selectedTranslationLanguage;
        let translation = await ScreenTranslation.translateTextParagraphs(
            this.props.sourceLanguage,
            translationLanguage,
            textSentForTranslation);

        for (let node of nodesSendForTranslation) {
            const cleanedContent = node.innerHTML.replace(/[\n\r\t]+/g, "");
            node.innerHTML = translation[cleanedContent];
        }

        transformDocument.decodeAfterTranslation();

        this.translationPreview = element.innerHTML;
        this.disableDefaultTextOverride();
    };

    onKeyPress = async (oldTextElementNode, newTextElementNode) => {
        if (this.props.onTextElementChange) {
            this.props.onTextElementChange(oldTextElementNode, newTextElementNode);
        }
        const translatedText = await this.translateParagraph(
            this.selectedTranslationLanguage,
            newTextElementNode.outerHTML);
        this.setState({currentParagraph: translatedText});
        this.disableDefaultTextOverride();
    };

    onTextElementDataChange = async (_source) => {
        if (this.state.currentParagraph !== this.currentTextElement.outerHTML) {
            const translatedText = await this.translateParagraph(
                this.selectedTranslationLanguage,
                this.currentTextElement.outerHTML);
            this.setState({currentParagraph: translatedText});
            this.disableDefaultTextOverride();
        }

    };

    config = {
        license: '1717Q-2VI42-ARLML-RKMEY',
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        autofocus: true,
        uploader: {
            "insertImageAsBase64URI": true,
        },
        toolbarButtonSize: "small",
        toolbarSticky: false,
        height: "65vh",
        buttons: "cut,copy,paste,paragraph,bold,italic,underline,ul,ol,indent,outdent,left,right,center,justify,font,fontsize,table,image,video,file,print,export",
        events: {
            afterInit: (instance) => {
                this.jodit = instance;
                this.jodit.e.on(this.jodit.editor, "selectionchange changeSelection selectionStart keyup mouseup", (_event) => {
                    this.joditTranslator.setReadOnly(false);
                    let textElementNode = this.currentTextElement;
                    if (this.currentTextElement !== this.currentTextElementNode) {
                        this.onKeyPress(this.currentTextElementNode, textElementNode);
                    }
                    this.currentTextElementNode = textElementNode;
                    this.currentTextElementText = this.removeJoditSpanTag(textElementNode.innerHTML);
                });
            },
        },
        style: {
            backgroundColor: "#fff",
        },
    };

    translationPreviewerConfig = {
        license: '1717Q-2VI42-ARLML-RKMEY',
        readonly: true, // all options from https://xdsoft.net/jodit/doc/
        autofocus: false,
        uploader: {
            "insertImageAsBase64URI": true,
        },
        toolbarButtonSize: "small",
        toolbarSticky: false,
        height: "65vh",
        buttons: "print",
        events: {
            afterInit: (instance) => {
                this.joditPreviewer = instance;
            },
        },
        style: {
            backgroundColor: "#fff",
        },

    };

    translationEditorConfig = {
        license: '1717Q-2VI42-ARLML-RKMEY',
        readonly: true, // all options from https://xdsoft.net/jodit/doc/
        autofocus: false,
        uploader: {
            "insertImageAsBase64URI": true,
        },
        toolbarButtonSize: "small",
        toolbarSticky: false,
        height: "10vh",
        buttons: "", //"print,export",
        events: {
            afterInit: (instance) => {
                this.joditTranslator = instance;
            },
        },
        style: {
            backgroundColor: "#fff",
        },

    };

    handleTranslationLanguageChange = async (value) => {
        this.selectedTranslationLanguage = value.lang;
        this.setState({
            translationLanguage: value.lang,
        });

        await this.viewExistingTranslation();

        this.triggerRerender();
    };

    renderTranslationLanguagesSelect = () => {
        return (
            <DataCombobox
                key="ExistingLanguages"
                dropdownOptions={this.state.documentTranslationLanguages}
                value={this.state.translationLanguage}
                valueField={"lang"}
                textField={"title"}
                onChange={(value) => this.handleTranslationLanguageChange(value)}
            />
        );
    };

    translateText = async () => {
        if (this.selectedTranslationLanguage && this.currentTextElementText) {
            const translatedText = await this.translateParagraph(
                this.selectedTranslationLanguage,
                this.currentTextElementText,
                true);
            this.setState({currentParagraph: translatedText});
            this.disableDefaultTextOverride();
        }
    };

    viewExistingTranslation = async () => {
        if (this.selectedTranslationLanguage && this.currentTextElementText) {
            const translatedText = await this.translateParagraph(
                this.selectedTranslationLanguage,
                this.currentTextElementText,
                false);
            this.setState({currentParagraph: translatedText});
            this.disableDefaultTextOverride();
        }
    };

    addLanguage = async () => {
        let languageExists = false;
        for (let language of this.state.documentTranslationLanguages) {
            if (language.lang === this.newTranslationLanguage.lang) {
                languageExists = true;
                this.selectedTranslationLanguage = language.lang;
                this.setState({
                    translationLanguage: language.lang,
                });
                break;
            }
        }
        if (!languageExists) {
            let newList = [...this.state.documentTranslationLanguages,
                this.newTranslationLanguage];
            this.selectedTranslationLanguage = this.newTranslationLanguage.lang;
            this.props.translationLanguageList(newList);

            this.setState({
                documentTranslationLanguages: newList,
                translationLanguage: this.selectedTranslationLanguage,
            });
            this.triggerRerender();
        }

    };

    prepareNewTranslationLanguage = (code, full_name) => {
        let languageDesignation = {};
        languageDesignation.lang = code;
        languageDesignation.title = full_name;

        this.newTranslationLanguage = languageDesignation;
        this.selectedTranslationLanguage = code;
    };

    handleButtonClick = async (buttonName) => {
        if (buttonName === "new_translation_language") {
            await this.determinePopupType(buttonName);
            return;
        }

        console.error("ERROR:", "Button '", buttonName, "' has no handler");
    };

    determinePopupType = async (buttonName) => {
        if (buttonName === "new_translation_language") {
            this.currentPopupDefinition = {
                popupWindow: {
                    title: this.popupTypeDefinitions.popupAddNewTranslationLanguage.title,
                    height: "50px",
                    width: "600px",
                },
                gridColumns: this.popupTypeDefinitions.popupAddNewTranslationLanguage.viewColumns,
            };
            this.setState({popupOpen: true});
        } else {
            console.error("ERROR: No popup type found:", buttonName);
        }
    };

    myPopupSubmitted = async (popupFieldData) => {
        if (popupFieldData.source_language && popupFieldData.source_language !== "") {
            this.prepareNewTranslationLanguage(
                popupFieldData.source_language,
                popupFieldData.source_language_full_name,
            );
            await this.addLanguage();
        }

        this.setState({
            popupOpen: false,
        });
    };

    myPopupCancelled = () => {
        this.setState({
            popupOpen: false,
        });
    };

    determinePopupData = () => {
        return null;
    };


    render() {
        const editorSideBySide = {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            height: "65vh",
        };

        let selectedPopupData = this.determinePopupData();

        return (
            <div id="EditorElement">
                <div style={editorSideBySide}>
                    <JoditEditor
                        ref={this.editor}
                        value={this.props.document.content}
                        config={this.config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => this.setState({content: newContent})} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => {
                            this.currentContent = newContent;
                            if (this.documentChanged !== true) {
                                this.props.documentChanged(true);
                            }
                        }}
                    />
                    <JoditEditor
                        ref={this.previewEditor}
                        value={this.translationPreview}
                        config={this.translationPreviewerConfig}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => this.setState({translatedContentPreview: newContent})} // preferred to use only this option to update the content for performance reasons
                    />

                </div>
                <label style={{marginLeft: "10px"}}>
                    {this.props.translationLabels["Translation Language:"]}
                    <span style={{marginLeft: "10px"}}>
                    {this.renderTranslationLanguagesSelect()}
                        </span>
                    <button
                        style={{margin: "0 0 0 10px"}}
                        onClick={() => this.handleButtonClick("new_translation_language")}
                    >
                        {this.props.translationLabels["Add"]}
                    </button>
                    <button
                        style={{marginLeft: "10px"}}
                        onClick={this.translateText}
                    >
                        {this.props.translationLabels["Reset Translation"]}
                    </button>
                </label>
                <div>
                    <JoditEditor
                        ref={this.previewEditor}
                        value={this.state.currentParagraph}
                        config={this.translationEditorConfig}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => this.inputControl(newContent)}
                    />
                </div>
                <UniversalPopupComponent
                    core={this.core}
                    popupDefinition={this.currentPopupDefinition}
                    popupValues={selectedPopupData}
                    onSubmit={this.myPopupSubmitted}
                    onCancel={this.myPopupCancelled}
                    open={this.state.popupOpen}
                    buttonTheme={this.props.themeName}
                />
                <div id={"HtmlParser"} name={"HtmlParser"} hidden={true}></div>

            </div>
        );
    }

}