import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/menuProject';

const projectUserData = constDefinitions.usersView;

class ProjectUserScreen extends GenericScreen {
    constructor(props) {
        super(props, projectUserData);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        let item1 = this.state.selectedRowData["user_role/list"].first_name;
        let item2 = this.state.selectedRowData["user_role/list"].last_name;
        return item1 + " " + item2;
    }

    screenSpecificSubmitInfo = () => {
        return {
            project_id: this.core.dbInfo.projectId,
        };
    };

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default ProjectUserScreen;
