import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

/** Props used:
 *  open - Regulates open state
 *  title - string - Displayed title of popup box
 *  displayedText - string - error message text
 *  buttonTheme - theme regulator for image buttons
 *  cancelOnly - boolean - display a Cancel button or Cancel and Confirm
 *  onCancel - callback to close popup
 *  onConfirm - callback for confirmation
 *  showContextImage - string - display error type. Possible types:
 *          warning
 *          failure
 *          information
 *  requireInputField - boolean - should there be a text input or not?
 *  inputObjectValue - value to be passed for inputBox to show
 *
 * */

class MuiCustomPrompt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputObjectValue: "",
        };
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (this.props.open !== prevProps.open && this.props.inputObjectValue === "") {
            this.setState({inputObjectValue: ""});
        }
        if (this.props.inputObjectValue !== prevProps.inputObjectValue) {
            this.setState({inputObjectValue: this.props.inputObjectValue});
        }

    }

    handleClickCancel = () => {
        this.props.onCancel(false);
    };

    handleClickSubmit = () => {
        let response;
        if (this.props.requireInputField === true) {
            if (this.state.inputObjectValue.length === 0) {
                response = "";
            } else {
                response = this.state.inputObjectValue;
            }
        } else {
            response = true;
        }
        this.props.onSubmit(response);
    };

    renderOkButton = (buttonTheme) => {
        return (
            <div>
                <button key={"ok"} style={this.props.buttonStyle} onClick={this.handleClickSubmit}>
                    <img
                        src={require(`../../ImageResources/${buttonTheme}/dialog_buttons/ok.png`).default}
                        alt={"ok"}
                    />
                </button>
                {this.renderCancelButton(buttonTheme)}
            </div>
        );
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;

        this.setState({inputObjectValue: value});
    };

    generateInputBox = () => {
        if (this.props.requireInputField) {
            return (
                <input
                    name={"input"}
                    type={"text"}
                    onChange={this.handleChange}
                    value={this.state.inputObjectValue}
                    style={{width: "100%", height: "16px"}}
                />
            );
        }
    };

    renderCancelButton = (buttonTheme) => {
        return (
            <button key={"cancel"} style={this.props.buttonStyle} onClick={this.handleClickCancel}>
                <img
                    src={require(`../../ImageResources/${buttonTheme}/dialog_buttons/cancel.png`).default}
                    alt="Close"
                />
            </button>
        );
    };

    generateContextImage = (theme) => {
        if (this.props.showContextImage) {
            switch (this.props.showContextImage) {
                case "failure":
                    return (<img
                        src={require(`../../ImageResources/${theme}/images.protis/failure.png`).default}
                        alt="Failure"
                    />);
                case "warning":
                    return (<img
                        src={require(`../../ImageResources/${theme}/images.protis/warning.png`).default}
                        alt="warning"
                    />);
                case "information":
                    return (<img
                        src={require(`../../ImageResources/${theme}/images.protis/warning.png`).default}
                        alt="information"
                    />);
                default:
                    return null;
            }

        }
    };

    finalizeTextContent = (theme) => {
        let divStyling;
        let contextImage;
        if (this.props.showContextImage) {
            divStyling = {
                display: 'grid',
                gridTemplateColumns: "60px 1fr",
                gridGap: "10px 10px",
                margin: "5px 0px",
            };
            contextImage = this.generateContextImage(theme);
        }

        if (this.props.requireInputField) {
            return (
                <div style={divStyling}>
                    {contextImage}
                    <div>
                        <div>
                            {this.props.displayedText}
                        </div>
                        <div style={{marginTop: "20px", align: "center"}}>
                            {this.generateInputBox()}
                        </div>
                    </div>

                </div>
            );
        } else {
            return (
                <div style={divStyling}>
                    {contextImage}
                    <div>
                        {this.props.displayedText}
                    </div>
                </div>
            );
        }
    };


    render() {
        let buttonTheme;
        if (this.props.buttonTheme) {
            if (this.props.buttonTheme === "theme_protis") {
                buttonTheme = 'protis';
            } else if (this.props.buttonTheme === "theme_black_n_white") {
                buttonTheme = "black-n-white";
            }
        } else {
            buttonTheme = 'protis';
        }

        let dialogButtons = this.props.cancelOnly
            ? this.renderCancelButton(buttonTheme)
            : this.renderOkButton(buttonTheme);

        return (
            <div>
                <Dialog
                    open={this.props.open}
                >
                    <DialogTitle>
                        {this.props.title}
                    </DialogTitle>
                    <DialogContent
                        style={{
                            minHeight: "100px",
                            minWidth: "200px",
                        }}
                    >
                        {this.finalizeTextContent(buttonTheme)}
                    </DialogContent>
                    <DialogActions>
                        {dialogButtons}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default MuiCustomPrompt;