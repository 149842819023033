import React from "react";
import TranslationEditor from "./TranslationEditor";
import ScreenTranslation from "../../ProTIS/ScreenTranslation";
import EntityRequest from "../../ProTIS/EntityRequest";
import CoreRequest from "../../ProTIS/CoreRequest";
import ButtonGenerator from "../ButtonGenerator";
import SideButtonsAndGrids from "../../StyleConfigurations/SideButtonsAndGrids";
import OptimizeDocument from "./OptimizeDocument";
import DocumentRequest from "../../ProTIS/DocumentRequest";
import UniversalPopupComponent from "../PopupAndComponents/UniversalPopupComponent";
import * as popupTypeDefinitions from "../LocalDefinitions/individualPopupDefinitions";
import MuiCustomPrompt from "../PopupAndComponents/MuiCustomPrompt";
import DocumentUploadDialog from "../PopupAndComponents/DocumentUploadDialog";


class DocumentEditor extends React.Component {
    locationState = this.props.location.state;

    constructor(props) {
        super(props);
        this.state = {
            fileUploaderOpen: false,
            dialogOpen: false,
            triggerDefaultTranslation: false,
            popupOpen: false,
            popupType: "",
            translationId: "en:ScreenName",
            documentTitle: "No title present",
        };
        this.core = CoreRequest.Instance;
        this.document = {};
        this.fullLanguageList = [];
        this.newContentLanguage = "";
        this.documentImportData = "";
        this.popupTypeDefinitions = popupTypeDefinitions;
        this.currentPopupDefinition = {
            popupWindow: {
                title: "initializing",
                height: "200px",
                width: "400px",
            },
            gridColumns: [
                {label: "example", name: "example", type: "hidden"},
            ],
        };
        this.importDateCheck = "";
        this.currentPopupData = "";

        this.translationData = [];
        this.currentTranslationId = "";
        this.translatedLabels = {};

        this.upload = {
            DocumentFileName: "",
            DocumentTitle: "",
            File: "",
            DocumentLanguage: "",
            DocumentLanguageFullName: "",
            DocumentLanguageID: null,
        };

        if (this.locationState.document_format_id) {
            let locationRef = this.locationState.records[0];
            let parsedDocument;

            if (locationRef.document_data && locationRef.document_data !== "") {
                if (locationRef.document_data.substr(0, 15) === "data:text/plain") {
                    parsedDocument = Buffer.from(locationRef.document_data, "base64").toString();
                } else if (locationRef.document_data.substr(0, 1) === "{") {
                    parsedDocument = JSON.parse(locationRef.document_data);
                } else {
                    parsedDocument = locationRef.document_data;
                }

                this.handleButtonClick = this.handleButtonClick.bind(this);

                if (parsedDocument.hasOwnProperty("bands")) {
                    this.document = {
                        title: "Test Document",
                        modified: new Date("2021-01-01 00:00:01"),
                        content: "<p>Old protis document. No longer supported</p>",
                        content_language: "en",
                        translations: {},
                        translationLanguages: [
                            {lang: "en", title: "English"},
                            {lang: "ru", title: "Russian (Русский)"},
                        ],
                    };
                } else {
                    this.document = parsedDocument;
                }
            } else if (this.locationState.document_format_id === 1) {
                this.document = {
                    title: "Test Document",
                    modified: new Date("2021-01-01 00:00:01"),
                    content: "<p>Unsupported or Empty document.</p>",
                    content_language: "en",
                    translations: {},
                    translationLanguages: [
                        {lang: "en", title: "English"},
                        {lang: "ru", title: "Russian (Русский)"},
                    ],
                };
            }
        }


        this.translationLanguages = this.document.translationLanguages ? this.document.translationLanguages :
            [
                {lang: "en", title: "English"},
                {lang: "ru", title: "Russian (Русский)"},
            ];

        this.translationPreview = false;
        this.documentChanged = false;

        this.triggerDefaultTranslation = false;

        this.promptActionTracker = "";
        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
        this.dialogCancelOnly = false;
    }

    handleReturnPreviousPage = async () => {
        this.props.history.goBack();
    };

    requestTranslationLabels = async (triggerPopup, type) => {
        this.translationData = [
            "Document Title",
            "Language",
            "Document",
            "Change",
            "Translation Language:",
            "Please attach a file to import",
            "Error",
            "Add",
            "Reset Translation",
            "Do you wish to save document data?",
            "Exiting Component",
            "Click editor above to start translating paragraphs",
            "Click Preview to translate document.",
        ];
        this.getLabelsFromDefinitions();
        this.getIndividualButtonLabels();
        let newState = {};
        let stateHasChanged = false;
        stateHasChanged = await this.performLabelTranslations(newState, stateHasChanged);
        if (triggerPopup && !type) {
            newState.popupOpen = true;
        } else if (triggerPopup && type) {
            if (type === "file") {
                newState.fileUploaderOpen = true;
            } else {
                return null;
            }
        }
        if (triggerPopup || stateHasChanged) {
            this.setState(newState);
        }
    };

    async performLabelTranslations(newState, stateHasChanged) {
        const newObjectClass = "view:" + this.currentPopupDefinition.popupWindow.title;
        const newTranslationId = this.props.languageCode + ":" + newObjectClass;
        if (this.currentTranslationId !== newTranslationId) {
            this.currentTranslationId = newTranslationId;
            this.translatedLabels = await ScreenTranslation.translateLabels(
                "en",
                this.props.languageCode,
                newObjectClass,
                this.translationData);
            newState.translationId = newTranslationId;
            stateHasChanged = true;
        }
        return stateHasChanged;
    }

    getIndividualButtonLabels() {
        if (this.buttonLabels) {
            for (let buttonLabels of this.buttonLabels) {
                this.translationData.push(buttonLabels);
            }
        } else {
            return null;
        }

    }

    getLabelsFromDefinitions() {
        if (this.currentPopupDefinition) {
            if (this.currentPopupDefinition.gridColumns) {
                for (let viewColumnLabels of this.currentPopupDefinition.gridColumns) {
                    if (viewColumnLabels.type !== "hidden") {
                        this.translationData.push(viewColumnLabels.label);
                    }
                }
                this.translationData.push(this.currentPopupDefinition.popupWindow.title);
            }
        } else {
            return null;
        }
    }

    componentDidMount = async () => {
        await this.requestTranslationLabels();
        if (this.document && this.document.title) {
            this.setState({
                documentTitle: this.document.title,
            });
        }
    };

    componentDidUpdate = async (prevProps, _prevState, _snapshot) => {
        if (prevProps.languageCode !== this.props.languageCode) {
            await this.requestTranslationLabels();
        }
    };

    componentWillUnmount = async () => {
        if (this.documentChanged !== false) {
            if (this.locationState.document_format_id === 2 || this.locationState.document_format_id === 1) {
                if (window.confirm('Do you wish to save document data?')) {
                    await this.submitDocumentData();
                }
            }
        }
    };

    translateLabel = (label) => {
        if (this.props.languageCode !== "en") {
            if (this.translatedLabels.hasOwnProperty(label)) {
                return this.translatedLabels[label];
            }
        }
        return label;
    };

    onTranslateEditorLanguage = async (translationLanguage, text) => {
        let translation = await ScreenTranslation.translateTextParagraphs(this.document.content_language, translationLanguage, [text]);
        return translation[text];
    };


    submitDocumentData = async () => {

        if (this.documentChanged !== false) {
            this.documentChangedCheck(false);
        }

        if (this.document && this.document.content
            && this.document.content_language
            && this.document.translations) {
            OptimizeDocument.removeUnusedTranslations(this.document);
        }

        this.applyNewTitle();
        let updateDocument = new EntityRequest(this.core, "document");
        let newDocumentData = {...this.locationState.records[0]};
        newDocumentData.document_data = JSON.stringify(this.document);
        newDocumentData.document_format_id = this.locationState.document_format_id === 1
            ? 2
            : this.locationState.document_format_id;

        this.setState({dialogOpen: false});
        return updateDocument.update(newDocumentData);
    };

    triggerRerender = () => {
        const date = new Date();
        let updated = date.getHours() + ":" + date.getMinutes() + ":" + date.getMilliseconds();
        this.setState({updated: updated});
    };

    resetToDefaultTranslation = (boolean) => {
        this.triggerDefaultTranslation = boolean;
        this.triggerRerender();
    };

    translationPreviewControl = (boolean) => {
        this.translationPreview = boolean;
        this.triggerRerender();
    };

    documentChangedCheck = (boolean) => {
        console.log("Document Changed:", boolean);
        this.documentChanged = boolean;
    };

    appendDocumentData = (state, segment, updatedContent) => {
        let appendedData = {...this.document};
        if (updatedContent) {
            appendedData.content = updatedContent;
        }
        appendedData[segment] = state[segment];

        this.document = appendedData;
    };

    handleTextInput = (event) => {
        const target = event.target;
        this.setState({
            documentTitle: target.value,
        });
    };

    applyNewTitle = (title) => {
        if (title) {
            this.document.title = title;
        } else {
            this.document.title = this.state.documentTitle;
        }

        this.setState({
            documentTitle: this.document.title,
        });
    };

    async handleButtonClick(buttonName) {
        switch (buttonName) {
            case "save":
                await this.submitDocumentData();
                break;
            case "default_translation":
                this.resetToDefaultTranslation(true);
                break;
            case "preview":
                this.translationPreviewControl(true);
                break;
            case "exit":
                await this.handleReturnPreviousPage();
                break;
            case "print":
                this.printDocument();
                break;
            case "import":
                await this.determinePopupType(buttonName);
                break;
            case "change_source_lang":
                await this.determinePopupType(buttonName);
                break;
            default:
                console.log("Button missing function:", buttonName);
                break;
        }
    }

    handleDocumentImport = async () => {
        console.log("Data recorded:", this.documentImportData);
        let base64TagRemovedData = this.parseDataString(this.documentImportData, true);
        let dataType = this.parseImportDocumentType(this.documentImportData);

        let convertedDocument;

        console.log("Datatype:", dataType);
        if (dataType === "html") {
            console.log("HTML found");
            convertedDocument = Buffer.from(base64TagRemovedData, 'base64').toString();
            console.log("Converted HTML:", convertedDocument);
        } else {
            console.log("ODT or DocX");
            let conversionRequest = new DocumentRequest(this.core);
            let result = await conversionRequest.convertDocumentToHTML(base64TagRemovedData, dataType);
            convertedDocument = result ? result.document_data_html : console.error("Conversion Error: Returned value is NULL");
        }

        let source_language;
        let source_language_full_name;
        if (this.upload.newUpload === false) {
            source_language = this.document.content_language ? this.document.content_language : "en";
            source_language_full_name = this.document.content_language_full_name ? this.document.content_language_full_name : "English";
        } else if (this.upload.newUpload === true) {
            source_language = this.upload.DocumentLanguage;
            source_language_full_name = this.upload.DocumentLanguageFullName;
        }
        let date = new Date();

        this.document = {
            content: convertedDocument,
            modified: date,
            content_language: source_language,
            content_language_full_name: source_language_full_name,
            translations: {},
            translationLanguages: [
                {lang: source_language, title: source_language_full_name},
            ],
        };
        this.importDateCheck = date;

        this.triggerRerender();

    };

    generateUploadPrompt = () => {
        return (
            <input
                type={"file"}
                onChange={this.onFileChange}
            />
        );
    };

    onFileChange = event => {
        console.log("File Uploader triggered", event);
        const self = this;
        const file = event.target.files[0];
        let reader = new FileReader();

        /** Closure to capture the file information. */
        reader.onload = (_theFile => {
            return function (e) {
                self.documentImportData = e.target.result;
            };
        })(file);

        /** Read in the image file as a data URL. */
        reader.readAsDataURL(file);
        console.log(event.target.files[0]);

    };

    renderSelectionOfButtons = (buttons) => {
        this.buttonLabels = buttons;
        return (
            <div>
                <ButtonGenerator
                    buttonsToGenerate={buttons}
                    buttonStyle={SideButtonsAndGrids.IndividualButtonStyle}
                    buttonTheme={this.props.themeName}
                    divParameters={SideButtonsAndGrids.ButtonDivStyle}
                    onClickEvent={(buttonName) => this.handleButtonClick(buttonName)}
                    buttonTextTranslation={this.translatedLabels}
                />
            </div>
        );
    };

    parseDataString = (documentData, isDocument) => {
        let base64Position = isDocument ? documentData.substring(0, 100).search(";base64,") :
            documentData.substring(0, 30).search(";base64,");
        if (base64Position !== -1) {
            return documentData.substring(base64Position + 8);
        } else {
            return documentData;
        }
    };

    parseImportDocumentType = (documentData) => {
        if (documentData.substr(0, 52) ===
            "data:application/vnd.oasis.opendocument.text;base64,") {
            console.log("odt");
            return "odt";
        } else if (documentData.substr(0, 84) ===
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,") {
            console.log("docx");
            return "docx";
        } else if (documentData.substr(0, 22) === "data:text/html;base64,") {
            return "html";
        }
    };

    convertSavedDocumentToHTML = () => {
        let locationRef = this.locationState.records[0];
        let parsedText;
        const date = new Date();
        if (locationRef.document_data.substr(0, 15) === "data:text/plain") {
            parsedText = Buffer.from(this.locationState.records[0].document_data.substr(23), "base64").toString();
            let textParagraphs = parsedText.split(/[\n\r]+/);

            let finalHTML = "<p>" + textParagraphs.join("</p><p>") + "</p>";

            this.document = {};
            this.document.content = finalHTML;
            this.document.title = "Converted Plaintext File";
            this.document.modified = date;
            this.document.content_language = "en";
            this.document.translations = {};

        }

    };

    convertToProtisFormat = (dataString) => {
        console.log("Data String for parse:", dataString);
        const date = new Date();
        this.document = {};
        this.document.content = dataString;
        this.document.title = "Converted File";
        this.document.modified = date;

    };

    updateLanguageList = (newList) => {
        this.document.translationLanguages = newList;
        this.triggerRerender();
    };

    receiveFullLanguageList = (langList) => {
        this.fullLanguageList = langList;
        this.triggerRerender();
    };

    renderDocumentData = () => {
        let mimeType;
        switch (this.locationState.document_format_id) {
            case 1:
            case 2:
                mimeType = "";
                break;
            case 3:
                mimeType = "application/pdf";
                break;
            case 4:
                mimeType = "image/png";
                break;
            case 5:
                mimeType = "image/jpeg";
                break;
            default:
                return null;

        }

        let buttonsEditor = ["Save", "Import", "Preview", "Exit"];
        let buttonsOther = ["Save", "Exit"];
        let formatID;
        let base64Parse;
        let dataString;

        if (this.locationState.records) {
            let documentSource = this.document.content ? this.document.content : this.locationState.records[0].document_data;
            base64Parse = this.parseDataString(documentSource);
            dataString = 'data:' + mimeType + ";base64," + base64Parse;
        } else {
            dataString = [];
        }

        if (this.locationState && this.locationState.records[0] && this.locationState.records[0].document_format_id) {
            formatID = this.locationState.records[0].document_format_id;
        } else if (this.locationState && this.locationState.document_format_id) {
            formatID = this.locationState.document_format_id;
        }

        switch (formatID) {
            case 1:
            case 2: /** Render HTML document */
                return this.renderDocumentEditor(buttonsEditor);
            case 3: /** Render PDF */
                return this.renderPDFPage(base64Parse, buttonsOther, dataString);
            case 4: /** Render Image PNG */
                return this.renderPNGImage(base64Parse, buttonsOther, dataString);
            case 5: /** Render Image Jpeg */
                return this.renderJPEGImage(base64Parse, buttonsOther, dataString);
            default :
                console.log("Switch in Editor Errored out");
                return (
                    <div id="FullScreenElement" style={SideButtonsAndGrids.EditorGridViewportStyle}>
                        Content Error
                        {this.renderSelectionOfButtons(buttonsOther)}
                    </div>);

        }

    };

    renderJPEGImage(base64Parse, buttonsOther, dataString) {
        if (this.document && !this.document.content) {
            console.log("Triggering Conversion of Jpeg");
            this.convertToProtisFormat(base64Parse);
        }
        this.buttonLabels = buttonsOther;
        return (
            <div id="FullScreenElement" style={SideButtonsAndGrids.EditorGridViewportStyle}>
                <img
                    id="FullScreenElement"
                    style={{maxHeight: "90vh"}}
                    src={dataString}
                    alt="error"
                />
                {this.renderSelectionOfButtons(buttonsOther)}
            </div>
        );
    }

    renderPNGImage(base64Parse, buttonsOther, dataString) {
        if (this.document && !this.document.content) {
            console.log("Triggering Conversion of PNG");
            this.convertToProtisFormat(base64Parse);
        }
        this.buttonLabels = buttonsOther;
        return (
            <div id="FullScreenElement" style={SideButtonsAndGrids.EditorGridViewportStyle}>
                <img
                    id="FullScreenElement"
                    style={{maxHeight: "90vh"}}
                    src={dataString}
                    alt="error"
                />
                {this.renderSelectionOfButtons(buttonsOther)}
            </div>
        );
    }

    renderPDFPage(base64Parse, buttonsOther, dataString) {
        if (this.document && !this.document.content) {
            console.log("Triggering Conversion of PDF");
            this.convertToProtisFormat(base64Parse);
        }
        this.buttonLabels = buttonsOther;
        return (
            <div id="FullScreenElement" style={SideButtonsAndGrids.EditorGridViewportStyle}>
                <embed
                    id="FullScreenElement"
                    style={{width: "100%", height: "90vh"}}
                    src={dataString}
                />
                {this.renderSelectionOfButtons(buttonsOther)}
            </div>
        );
    }

    renderDocumentEditor(buttonsEditor) {
        if (this.document && !this.document.content) {
            console.log("Triggering Conversion of plaintext.");
            this.convertSavedDocumentToHTML();
        }
        this.buttonLabels = buttonsEditor;
        return (
            <div id="FullScreenElement" style={SideButtonsAndGrids.EditorGridViewportStyle}>
                <TranslationEditor
                    document={this.document}
                    languages={this.translationLanguages}
                    translationLanguageList={(newList) => this.updateLanguageList(newList)}
                    onTranslate={(language, text) => this.onTranslateEditorLanguage(language, text)}
                    appendNewTranslationData={(state, segment, updatedContent) =>
                        this.appendDocumentData(state, segment, updatedContent)}
                    defaultTranslation={this.triggerDefaultTranslation}
                    defaultTranslationOff={(boolean) => this.resetToDefaultTranslation(boolean)}
                    translationPreview={this.translationPreview}
                    enablePreview={(boolean) => this.translationPreviewControl(boolean)}
                    fullLanguageList={(langList) => this.receiveFullLanguageList(langList)}
                    sourceLanguage={this.document.content_language}
                    importDateCheck={this.importDateCheck}
                    documentChanged={(boolean) => this.documentChangedCheck(boolean)}
                    translationLabels={this.translatedLabels}
                />
                {this.renderSelectionOfButtons(buttonsEditor)}
            </div>
        );
    }

    documentContentLanguage = () => {
        let formatId = this.locationState.document_format_id;
        let language;
        if (this.document
            && this.document.content_language
            && (formatId === 1 || formatId === 2)) {
            for (let contentLanguage of this.fullLanguageList) {
                if (this.document.content_language === contentLanguage.language_code) {
                    language = contentLanguage.language_full_name;
                    break;
                }
            }
            let changeText = this.translatedLabels["Change"] ? this.translatedLabels["Change"] : "Change";
            console.log("Change button:", this.translatedLabels["Change"]);

            return (
                <span>
                    <span>
                        {this.translatedLabels["Language:"]} {language}
                    </span>
                    <span style={{paddingLeft: "10px"}}>
                        <button
                            style={{width: "65px"}}
                            onClick={() => this.handleButtonClick("change_source_lang")}
                        >
                            {changeText}
                        </button>
                    </span>

                </span>
            );
        }


    };

    applyNewContentLanguage = () => {
        this.document.content_language = this.newContentLanguage;
        this.triggerRerender();
    };

    prepareContentLanguageChange = (value) => {
        console.log("Source Language Change:", value);
        this.newContentLanguage = value;
    };

    determinePopupType = async (buttonName) => {
        switch (buttonName) {
            case "import":
                await this.requestTranslationLabels(true, "file");
                break;
            case "change_source_lang":
                this.currentPopupDefinition = {
                    popupWindow: {
                        title: this.popupTypeDefinitions.popupChangeSourceLanguage.title,
                        height: "50px",
                        width: "600px",
                    },
                    gridColumns: this.popupTypeDefinitions.popupChangeSourceLanguage.viewColumns,
                };
                this.currentPopupData = {
                    source_language: this.document.content_language,
                };
                await this.requestTranslationLabels(true);
                break;
            default:
                console.log("Placeholder", buttonName);
                return null;
        }
    };

    myPopupSubmitted = async (popupFieldData) => {
        if (popupFieldData.source_language) {
            this.prepareContentLanguageChange(popupFieldData.source_language);
            this.applyNewContentLanguage();
        }

        if (popupFieldData.title && popupFieldData.title !== "") {
            this.applyNewTitle(popupFieldData.title);
        }

        if (popupFieldData.document_data) {
            this.documentImportData = popupFieldData.document_data;
            await this.handleDocumentImport();
        }

        this.setState({
            popupOpen: false,
        });
    };

    myPopupCancelled = () => {
        this.setState({
            popupOpen: false,
        });
    };

    handleDialogClose = (confirmation) => {
        this.setState({dialogOpen: confirmation});

        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
        this.dialogCancelOnly = false;
    };

    handleDialogSubmit = async (response) => {
        if (response === true) {
            if (this.promptActionTracker === "component_exit") {
                await this.submitDocumentData();
            } else {
                return null;
            }
        }
    };

    handleFileUploaderState = (state) => {
        this.setState({
            fileUploaderOpen: state,
        });
    };

    submitUploadedDocument = async (data) => {
        this.upload = {
            DocumentFileName: data.document_filename,
            DocumentTitle: data.document_title,
            File: data.presentation,
            DocumentLanguage: data.source_language,
            DocumentLanguageFullName: data.source_language_full_name,
            DocumentLanguageID: data.source_language_id,
            newUpload: true,
        };

        if (!this.upload.File) {
            this.dialogTitle = "Error";
            this.dialogText = "Please attach a file to import";
            this.errorType = "failure";
            this.dialogCancelOnly = true;

            this.setState({dialogOpen: true});
        } else {
            let title = data.document_title !== "" ? data.document_title : data.document_filename;
            this.documentImportData = this.upload.File;

            await this.handleDocumentImport();
            this.applyNewTitle(title);
            this.handleFileUploaderState(false);
        }
    };

    render() {
        const inputStyle = {
            width: "250px",
            marginRight: "10px",
            marginLeft: "10px",
        };

        let selectedPopupData = this.currentPopupData !== "" ? this.currentPopupData : null;

        const generateEditorData = this.renderDocumentData();
        return (
            <div>
                <UniversalPopupComponent
                    core={this.core}
                    popupDefinition={this.currentPopupDefinition}
                    popupValues={selectedPopupData}
                    onSubmit={this.myPopupSubmitted}
                    onCancel={this.myPopupCancelled}
                    open={this.state.popupOpen}
                    buttonTheme={this.props.themeName}
                    translatedLabels={this.translatedLabels}
                />
                <div>
                    <div style={{display: "row", justifyContent: "left", marginLeft: "10px"}}>
                        <span>
                            {this.translatedLabels["Document Title:"]}
                            <input type="text"
                                   value={this.state.documentTitle}
                                   onChange={this.handleTextInput}
                                   style={inputStyle}
                            />
                            {this.documentContentLanguage()}
                        </span>

                    </div>
                </div>
                <div id="EditorElement">
                    {generateEditorData}
                </div>

                <MuiCustomPrompt
                    open={this.state.dialogOpen}
                    title={this.translateLabel(this.dialogTitle)}
                    displayedText={this.translateLabel(this.dialogText)}
                    buttonTheme={this.props.themeName}
                    cancelOnly={this.dialogCancelOnly}
                    showContextImage={this.errorType}
                    onCancel={(confirmation) => this.handleDialogClose(confirmation)}
                    onSubmit={(response) => this.handleDialogSubmit(response)}
                />
                <DocumentUploadDialog
                    open={this.state.fileUploaderOpen}
                    buttonTheme={this.props.themeName}
                    onCancel={() => this.handleFileUploaderState(false)}
                    onSubmit={(data) => this.submitUploadedDocument(data)}
                    translatedLabels={this.translatedLabels}
                    title={"Import File"}
                    uiLanguage={this.props.languageCode}
                    document_language={null}
                />
            </div>
        );
    }
}

export default DocumentEditor;