import React from "react";
import {Link} from "react-router-dom";
import DataCombobox from "../DataCombobox";
import ProjectRequest from "../../ProTIS/ProjectRequest";
import homeButton from "../../ImageResources/protis/buttons/home.png";
import previousButton from "../../ImageResources/protis/buttons/prior.png";
import nextButton from "../../ImageResources/protis/buttons/next.png";
import submitButton from "../../ImageResources/protis/buttons/ok.png";
import addButton from "../../ImageResources/protis/buttons/add.png";
import removeButton from "../../ImageResources/protis/buttons/remove.png";
import editButton from "../../ImageResources/protis/buttons/edit.png";
import OwnerRequest from "../../ProTIS/OwnerRequest";
import OwnerDatabaseRequest from "../../ProTIS/OwnerDatabaseRequest";
import ConnectString from "../../ProTIS/UsefulFunctions/connectParse";
import UniversalPopupComponent from "../PopupAndComponents/UniversalPopupComponent";
import DataGrid_Function from "../Tables/DataGrid_Function";
import ScreenTranslation from "../../ProTIS/ScreenTranslation";
import MuiCustomPrompt from "../PopupAndComponents/MuiCustomPrompt";


const columns = [
    {field: 'title', headerName: 'Title', width: 400},
];

const gridColumns = [
    {label: "popupSize", name: "popupSize", width: 600, height: 400, hidden: true, type: "popupSize"},
    {label: "id", name: "id", width: 60, hidden: true, type: "hidden"},
    {label: "owner_id", name: "owner_id", width: 150, type: "hidden", mandatory: true},
    {label: "Database Title:", name: "title", width: 400, type: "text", mandatory: true},
    {label: "Database Name:", name: "dbname", width: 300, type: "text"},

    {
        label: "Database Type:",
        name: "dbtype",
        width: 200,
        type: "datacombo",
        mandatory: true,
        valueField: "value",
        textField: "label",
        items: [
            {value: "mysql", label: "MySQL"},
            {value: "oracle", label: "Oracle"},
            {value: "postgresql", label: "PostgreSQL"},
            {value: "mssql", label: "MS SQL"}],
    },

    {label: "Database Server:", name: "dbhost", width: 250, type: "text", mandatory: true},
    {label: "Port:", name: "port", width: 75, type: "port", mandatory: true},

    {label: "Schema Name:", name: "schema", width: 300, type: "text", mandatory: true},
    {label: "User Name:", name: "username", width: 170, type: "text", mandatory: true},
    {label: "Password:", name: "password_db", width: 150, type: "password", mandatory: true},

];

const ownerDatabaseView = {
    popupWindow: {
        title: "Owner Database",
        height: "400px",
        width: "600px",
    },
    gridColumns: gridColumns,
};

class LoginDatabaseSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ownerID: 0,
            ownerName: '',
            databaseID: this.props.databaseID,
            databaseRowData: {},
            popupOpen: false,
            databaseEditState: null,
            ownerData: this.props.ownerData,
            connectStringContainer: {},
            newConnectString: {},
            newOwner: false,
            newDatabase: false,
            currentTranslationCode: "en",
            open: false,
            openLayer2: false,
        }
        ;
        this.core = props.core;

        /** Checking if there is content in database **/
        let databaseExists = false;
        this.props.ownerData.map((owner) => {
            owner.database.map((database) => {
                if (database.id === this.state.databaseID) {
                    databaseExists = true;
                }
                return database;
            });
            return owner;
        });

        /** Get the ID of the first owner on list, and then the id of the first entry of database **/
        if (!databaseExists && this.props.ownerData.length > 0) {
            let firstSelectedOwner = this.props.ownerData[0];
            this.state.databaseID = firstSelectedOwner.database[0].id;
            this.state.databaseRowData = firstSelectedOwner.database[0];
            this.state.ownerID = firstSelectedOwner.id;
        }

        this.core = props.core;
        this.myPopupSubmitted = this.myPopupSubmitted.bind(this);

        this.translatedLabels = [];

        this.dialogTitle = " ";
        this.dialogText = " ";
        this.errorType = " ";
        this.requireInputField = false;
        this.dialogSubmittedInput = "";


        this.dialogTitleLayer2 = "";
        this.dialogTextLayer2 = "";
        this.errorTypeLayer2 = "";

        this.promptActionTracker = "";
    }

    translateLabel = (label) => {
        if (this.props.translationCode !== "en") {
            if (this.translatedLabels.hasOwnProperty(label)) {
                return this.translatedLabels[label];
            }
        }
        return label;
    };

    async getTranslatedLabels() {
        this.translatedLabels = await ScreenTranslation.translateLoginScreenLabels(this.props.translationCode);
        this.setState({currentTranslationCode: this.props.translationCode});

    }

    async componentDidMount() {
        await this.getTranslatedLabels();
    }

    async componentDidUpdate(prevProps, prevState) {

        if (this.props.translationCode !== this.state.currentTranslationCode) {
            await this.getTranslatedLabels();
        }

        if (prevProps.ownerData !== this.props.ownerData) {
            if (this.state.newOwner === true) {
                this.setState({
                    ownerData: this.props.ownerData,
                    ownerID: this.state.newOwnerId,
                    newOwner: false,
                });

            } else {
                this.setState({
                    ownerData: this.props.ownerData,
                    ownerID: this.props.ownerData[0].id,
                });
            }
        }

        if (prevState.ownerData !== this.state.ownerData) {
            if (this.props.ownerData.length > 0 && this.state.newDatabase !== true) {
                /** Update if owner data is different from previous **/

                this.selectInitialDatabase();
            }
        }

        if (prevState.ownerID !== this.state.ownerID) {
            if (this.props.ownerData.length > 0) {

                /** Compare the ID of newSelectedOwner in OwnerData,
                 *  going by index of Owner Data, but without counting the index
                 * Once appropriate entry of OwnerID is found, find first database entry and set it as selected.
                 * **/

                this.selectInitialDatabase();
            }
        }
    }

    selectInitialDatabase = () => {
        for (let newSelectedOwner of this.props.ownerData) {
            if (newSelectedOwner.id === this.state.ownerID) {
                if (newSelectedOwner.database.length > 0) {
                    this.setState({databaseID: newSelectedOwner.database[0].id});
                    this.setState({databaseRowData: newSelectedOwner.database[0]});
                    this.setState({ownerID: newSelectedOwner.id});
                    break;
                }
            }
        }
    };


    returnToLogin = () => {
        this.props.history.push("/login/user");
    };

    handleOwnerSelect(id, title, newOwner) {
        let newOwnerCheck = newOwner === true ? newOwner : false;
        if (newOwnerCheck === true) {
            this.setState({
                newOwner: true,
                newOwnerId: id,
                newOwnerTitle: title,
            });
        } else {
            /** Set selected owner from combobox, using internal id and owner name **/
            this.setState({
                ownerID: id,
                ownerName: title,
            });
        }
    }

    handleDatabaseSelect(selectedRow) {
        /** Recieves selectedRow from DataGrid and registers the ID and Data cotnained within. **/
        let newSelectedRow = selectedRow.row ? selectedRow.row : selectedRow;

        this.setState({
            databaseID: newSelectedRow.id,
            databaseRowData: newSelectedRow,
        });
        this.props.onChange(this.state.databaseID);
    }

    menuGenProcess() {
        /** Generate the menu and content based on input data**/
        return (
            <div>
                <div style={{display: 'flex', width: '500px', marginBottom: '20px'}}>
                    <span style={{
                        width: '75px',
                        textAlign: 'right',
                        paddingRight: '25px',
                    }}> {this.translateLabel("Owner:")}</span>
                    <span>
                            <DataCombobox dropdownOptions={this.state.ownerData}
                                          value={this.state.ownerID}
                                          valueField={'id'}
                                          textField={'title'}
                                          onChange={(value) => this.handleOwnerSelect(value.id, value.title)}
                            />
                    </span>
                </div>
                <div style={{display: 'flex', width: '800px', marginBottom: '40px'}}>
                    <span style={{width: '70px', textAlign: 'left'}}>
                        {this.translateLabel("Database Title:")}
                    </span>
                    <span style={{width: '450px', height: "400"}}>
                        {this.generateTableOptions()}
                    </span>
                </div>
            </div>
        );
    }

    generateTableOptions() {
        /** Grid generation based on data from:
         * selectedOwner : Data contained within the Owner's data
         * displayColumns :
         *      field (internal name),
         *      headerName (custom name for internal name),
         *      width (width of the column for display)
         **/
        let usableColumns = columns;
        let selectedOwner = null;
        let selectedOwnerId = this.state.ownerID;
        return this.state.ownerData.map((owner) => {
            if (owner.id === selectedOwnerId) {
                selectedOwner = owner.database;
                return (
                    <div key={owner.id} style={{height: 250, width: '100%'}}>
                        <DataGrid_Function
                            sourceData={selectedOwner}
                            displayColumns={usableColumns}
                            onRowClick={(selectedRow) => this.handleDatabaseSelect(selectedRow)}
                            hideFooter={true}
                            firstSelection={this.state.databaseID}
                            headerHeight="hidden"
                            rowHeight={30}
                        />
                    </div>
                );
            }
            return null;
        });
    }

    databaseSelected = async () => {
        /** Prepare selected database data for use of project select **/
        this.core.useOwnerDatabase(this.state.databaseID);
        this.core.setCurrentProjectId(0);

        const projectRequest = new ProjectRequest(this.core);
        let resultProject = await projectRequest.getList();

        let result = resultProject ? resultProject : console.error("Database select error: Returned value is NULL");
        this.props.onProjectDataReceived(result.records);

        this.props.history.push("/login/project");
    };

    handleDialogClose = (confirmation) => {
        this.setState({open: confirmation});

        this.dialogTitle = "";
        this.dialogText = "";
        this.errorType = "";
        this.requireInputField = false;
        this.dialogSubmittedInput = "";
    };


    handleDialogSubmit = async (response) => {
        if (response === true) {
            switch (this.promptActionTracker) {
                case "remove_owner":
                    this.handleRemoveOwner();
                    break;
                case "remove_database":
                    await this.handleRemoveDatabase();
                    break;
                default:
                    return null;
            }

        } else if (response !== "") {
            switch (this.promptActionTracker) {
                case "new_owner":
                    await this.handleNewOwner(response);
                    break;
                case "edit_owner":
                    await this.handleEditOwner(response);
                    break;
                default:
                    return null;
            }

        } else {
            this.activateLayer2Error();
        }
    };

    activateLayer2Error = () => {
        this.dialogTitleLayer2 = "Error";
        this.dialogTextLayer2 = "Invalid or empty input";
        this.errorTypeLayer2 = "failure";
        this.setState({openLayer2: true});
    };

    handleDialogCloseLayer2 = () => {
        this.dialogTitleLayer2 = "";
        this.dialogTextLayer2 = "";
        this.errorTypeLayer2 = "";
        this.setState({openLayer2: false});
    };

    openPrompt = () => {
        if (this.state.open === false) {
            this.setState({open: true});
        }
    };

    closePrompt = () => {
        if (this.state.open === true) {
            this.dialogTitle = "";
            this.dialogText = "";
            this.errorType = "";
            this.requireInputField = false;
            this.dialogSubmittedInput = "";
            this.setState({open: false});
        }
    };

    generatePromptMessage = (type) => {
        this.promptActionTracker = type;
        switch (type) {
            case "new_owner":
                this.dialogTitle = "New Owner";
                this.dialogText = "Enter the name of the new owner";
                this.errorType = "information";
                this.requireInputField = true;
                this.openPrompt();
                break;
            case "edit_owner":
                this.dialogTitle = "Edit Owner";
                this.dialogText = "Would you like to rename the current owner?";
                this.errorType = "information";
                this.requireInputField = true;
                this.dialogSubmittedInput = this.state.ownerName;
                this.openPrompt();
                break;
            case "remove_owner":
                this.dialogTitle = "Remove Owner";
                this.dialogText = "Do you want to delete the currently selected owner?";
                this.errorType = "warning";
                this.requireInputField = false;
                this.openPrompt();
                break;
            case "remove_database":
                this.dialogTitle = "Remove Database";
                this.dialogText = "Do you want to delete the currently selected Database?";
                this.errorType = "warning";
                this.requireInputField = false;
                this.openPrompt();
                break;
            default:
                return null;
        }

    };

    handleNewOwner = async (response) => {
        let newOwner = response;
        newOwner = newOwner.trim();

        const internalName = newOwner.toLowerCase().replace(/ /g, "_");

        let requestError = null;

        const ownerRequest = new OwnerRequest(this.core);

        let resultOwner = await ownerRequest.create({name: internalName, title: newOwner},
            (error) => {
                requestError = error;
            },
        );
        let result = resultOwner ? resultOwner : console.error("New Owner error:  Returned value is NULL");

        if (result === null) {
            if (requestError === null) {
                requestError = "Invalid or empty input";
            }
            this.dialogTitleLayer2 = "Error";
            this.dialogTextLayer2 = requestError;
            this.errorTypeLayer2 = "failure";
            this.setState({openLayer2: true});
        } else {

            this.handleOwnerSelect(result.result.new_record_id, newOwner, true);

            this.props.onUpdateOwner('created');
            this.closePrompt();
        }
    };

    handleEditOwner = async (response) => {
        if (response !== this.state.ownerName) {
            let editOwner = response;
            editOwner = editOwner.trim();

            const internalNameUpdate = editOwner.toLowerCase().replace(/ /g, "_");

            const ownerRequest = new OwnerRequest(this.core);
            await ownerRequest.update(
                {
                    id: this.state.ownerID,
                    name: internalNameUpdate,
                    title: editOwner,
                });
            this.props.onUpdateOwner('created', this.state.ownerID);
        }
        this.closePrompt();
    };


    /** Owner Delete Section for review. **/
    handleRemoveOwner = () => {
        const ownerRequest = new OwnerRequest(this.core);
        ownerRequest.remove(this.state.ownerID);

        this.props.onUpdateOwner('deleted', this.state.ownerID);
        this.closePrompt();
    };

    handleNewDatabase() {
        /** Prep to designate new database **/
        this.setState({
            popupOpen: true,
            newDatabase: true,
            databaseEditState: 'newDatabase',
        });
    }

    handleEditDatabase = () => {
        /** Parser for the connect string, preparing to edit an existing database **/
        let myConnectStr = this.state.databaseRowData.connect;
        let connect = ConnectString.parseConnectString(myConnectStr);
        let newRowData = {...this.state.databaseRowData, ...connect};

        this.setState({
            popupOpen: true,
            databaseEditState: 'editDatabase',
            databaseRowData: newRowData,
        });
    };

    handleRemoveDatabase = async () => {
        const ownerDBRequest = new OwnerDatabaseRequest(this.core);
        await ownerDBRequest.remove(this.state.databaseID);

        const ownerRequest = new OwnerRequest(this.core);
        let newOwnerData = await ownerRequest.getList();

        if (newOwnerData) {
            this.setState({
                newDatabase: false,
                ownerData: newOwnerData.records,
            });
        } else {
            console.error("Remove Database failed: Returned value is NULL");
        }

        this.closePrompt();
    };

    connectBuilder = (popupFieldData) => {
        /** Use row data to create a new connect string based on new field inputs **/
        let connectSnippet = {...popupFieldData};

        let dbtype = connectSnippet.dbtype;
        /** combobox select type */
        let username = connectSnippet.username;
        let password = connectSnippet.password_db;
        let host = connectSnippet.dbhost;
        /** server name */
        let port = connectSnippet.port;
        /** port */
        let dbname = connectSnippet.dbname; /** database name */

        if (dbtype) {
            let connect = dbtype + "://";
            if (username !== null) {
                connect += username;
                if (password) {
                    connect += ":" + password;
                }
                connect += "@";
            }
            if (host) {
                connect += host;
            } else {
                connect += "localhost";
            }
            if (port) {
                connect += ":" + port;
            }
            if (dbname) {
                connect += "/" + dbname;
            }

            return connect;
        }


        return null;
    };

    finalizePopupAndClose = async () => {
        /** Once edit,create,or removal, of database happens,
         *  a new server request is executed to get updated data.
         *  New data is then written into state, and database edit prep is reset.
         *  **/

        const ownerRequest = new OwnerRequest(this.core);
        let newOwnerData = await ownerRequest.getList();

        if (newOwnerData) {
            this.setState({
                databaseEditState: null,
                popupOpen: false,
                ownerData: newOwnerData.records,
            });
        } else {
            console.error("Remove Database failed: Returned value is NULL");
        }
    };

    myPopupSubmitted = async (popupFieldData) => {
        /** Main function to create new, edit, or remove, databases, based on prep from before **/
        const ownerDBRequest = new OwnerDatabaseRequest(this.core);

        let connect = this.connectBuilder(popupFieldData);

        let record = {...popupFieldData};
        record.connect = connect;

        if (this.state.databaseEditState === 'newDatabase') {

            delete record["id"];
            record["owner_id"] = this.state.ownerID;

            let requestError = null;
            let result = await ownerDBRequest.create(record,
                (error) => {
                    requestError = error;
                });
            if (result === null) {
                if (requestError == null) {
                    requestError = "Invalid data. Make sure all fields are properly filled out or selected.";
                }
                this.dialogTitleLayer2 = "Error";
                this.dialogTextLayer2 = requestError;
                this.errorTypeLayer2 = "failure";
                this.setState({openLayer2: true});
            } else if (result) {
                record.id = result.result.new_record_id;
                this.handleDatabaseSelect(record);
                await this.finalizePopupAndClose();
            } else {
                console.error("Submit failed: Returned value is NULL");
            }

        } else if (this.state.databaseEditState === 'editDatabase') {
            let result = await ownerDBRequest.update(record);
            console.log("Edit Db result:", result);
            await this.finalizePopupAndClose();
        } else {
            return null;
        }
    };

    myPopupCancelled = () => {
        this.setState({
            popupOpen: false,
        });
    };

    render() {
        const buttonStyling = {
            width: '50px',
            margin: '1%',
            height: '38px',
        };

        const mainElementStyling = {
            display: 'grid',
            gridTemplateColumns: '560px 1fr',
            gridGap: '0 20px 0 0px',
            height: '350px',
            marginLeft: "20px",
        };

        const navPosition = {
            display: 'flex',
            width: '700px',
            justifyContent: 'center',
        };

        const navButtons = {
            width: '100px',
            height: '34px',
            margin: '0 5px 0 5px',
        };

        const right6MenuButtons = {
            width: '160px',
            marginBottom: '40px',
            marginTop: '20px',
        };

        const topDivStyle = {
            display: 'grid',
            gridTemplateRows: "1fr",
        };

        const generateOwnerMenu = this.menuGenProcess();

        /** Popup prep, passing Row Data from the grid component, for generating the popup. **/
        let databaseRowData = this.state.databaseEditState === 'newDatabase' ? null : this.state.databaseRowData;

        return (
            <div style={topDivStyle}>

                <span style={{display: 'grid', margin: '10px 0 10px 80px'}}>
                    {this.translateLabel("Select Database Owner and Database")}
                </span>
                <div style={mainElementStyling}>
                    <div style={{marginTop: '20px'}}>
                        {generateOwnerMenu}
                    </div>
                    <div style={right6MenuButtons}>
                        <button
                            style={buttonStyling}
                            onClick={() => this.generatePromptMessage("new_owner")}
                        >
                            <img src={addButton} alt="new owner"/>
                        </button>
                        <button
                            style={buttonStyling}
                            onClick={() => this.generatePromptMessage("edit_owner")}
                        >
                            <img src={editButton} alt="edit owner"/>
                        </button>
                        <button
                            style={buttonStyling}
                            onClick={() => this.generatePromptMessage("remove_owner")}
                        >
                            <img src={removeButton} alt="remove owner"/>
                        </button>

                        <button
                            style={buttonStyling}
                            onClick={this.handleNewDatabase.bind(this)}
                        >
                            <img src={addButton} alt="new database"/>
                        </button>
                        <button
                            style={buttonStyling}
                            onClick={this.handleEditDatabase.bind(this)}
                        >
                            <img src={editButton} alt="edit database"/>
                        </button>
                        <button
                            style={buttonStyling}
                            onClick={() => this.generatePromptMessage("remove_database")}
                        >
                            <img src={removeButton} alt="remove database"/>
                        </button>
                    </div>
                </div>

                <nav style={navPosition}>
                    <Link to="/login/user">
                        <button style={navButtons}>
                            <img src={homeButton} alt="Home Button"/>
                        </button>
                    </Link>
                    <button
                        style={navButtons}
                        onClick={this.returnToLogin}
                    >
                        <img src={previousButton} alt="Previous Button"/>
                    </button>
                    <button
                        style={navButtons}
                        onClick={this.databaseSelected}
                    >
                        <img src={nextButton} alt="Next Button"/>
                    </button>
                    <button style={navButtons}>
                        <img src={submitButton} alt="Submit Button"/>
                    </button>

                    <UniversalPopupComponent
                        core={this.core}
                        popupDefinition={ownerDatabaseView}
                        popupValues={databaseRowData}
                        onSubmit={this.myPopupSubmitted}
                        onCancel={this.myPopupCancelled}
                        open={this.state.popupOpen}
                        translatedLabels={this.translatedLabels}
                        buttonTheme={this.props.themeName}
                    />

                    <MuiCustomPrompt
                        open={this.state.open}
                        title={this.translateLabel(this.dialogTitle)}
                        displayedText={this.translateLabel(this.dialogText)}
                        buttonTheme={this.props.themeName}
                        cancelOnly={false}
                        showContextImage={this.errorType}
                        requireInputField={this.requireInputField}
                        inputObjectValue={this.dialogSubmittedInput}
                        onCancel={(confirmation) => this.handleDialogClose(confirmation)}
                        onSubmit={(response) => this.handleDialogSubmit(response)}
                    />
                    <MuiCustomPrompt
                        open={this.state.openLayer2}
                        title={this.translateLabel(this.dialogTitleLayer2)}
                        displayedText={this.translateLabel(this.dialogTextLayer2)}
                        buttonTheme={this.props.themeName}
                        cancelOnly={true}
                        showContextImage={this.errorTypeLayer2}
                        onCancel={(confirmation) => this.handleDialogCloseLayer2(confirmation)}
                    />
                </nav>
            </div>
        );
    }
}

export default LoginDatabaseSelect;