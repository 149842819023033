/**
 * Transform document to simplify and optimize translation,
 * and restore it to original format after translation
 */
class TransformDocument {

    /**
     * Constructor
     * @param htmlDocument      document as an HTML object
     */
    constructor(htmlDocument) {
        this.htmlDocumentData = htmlDocument;
        this.imageData = [];
    }

    /**
     * Encode HTML document for translation
     */
    encodeForTranslation = () => {
        this.tableIndex = 1;
        this._encodeImagesForTranslation();
        this._encodeTablesForTranslation();
        return this.htmlDocumentData.innerHTML;
    };

    /**
     * Decode HTML document after translation
     */
    decodeAfterTranslation = () => {
        this._decodeTablesAfterTranslation();
        this._decodeImagesAfterTranslation();
        return this.htmlDocumentData.innerHTML;
    };

    _encodeImagesForTranslation = () => {
        /** Compact <img> tags by removing src attributes and storing it internally in imageData array */
        for (let imageNode of this.htmlDocumentData.querySelectorAll("img")) {
            this.imageData.push(imageNode.getAttribute("src"));
            imageNode.removeAttribute("src");
        }
    };

    _decodeImagesAfterTranslation = () => {
        /** Restore <img> tags by adding src attributes from stored in imageData array */
        let index = 0;
        for (let imageNode of this.htmlDocumentData.querySelectorAll("img")) {
            this.imageData.push(imageNode.getAttribute("src"));
            imageNode.setAttribute("src", this.imageData[index]);
            index++;
        }
    };

    _encodeTablesForTranslation() {
        for (let rootTableNode of this.htmlDocumentData.querySelectorAll("#HtmlParser > table")) {
            this._parseTable(rootTableNode);
        }
    }

    _decodeTablesAfterTranslation() {
        const relocatedTables = this.htmlDocumentData.querySelectorAll("table.relocated-table");
        for (let relocatedTable of relocatedTables) {
            const sourceTableId = relocatedTable.getAttribute("id").replace(/^r/, "t");
            const sourceTable = this.htmlDocumentData.querySelector("#" + sourceTableId);
            sourceTable.innerHTML = relocatedTable.innerHTML;
            this.htmlDocumentData.removeChild(relocatedTable);
        }
    }

    _parseTable(tableNode) {
        let tableNodeId = "t" + this.tableIndex;
        this.tableIndex++;
        tableNode.setAttribute("id", tableNodeId);

        const subTablesSelector = "#" + tableNodeId + " > tbody > tr > td > table";
        const immediateSubTables = tableNode.querySelectorAll(subTablesSelector);
        for (let subTableNode of immediateSubTables) {
            this._parseTable(subTableNode);

            /** Create a new table at the end of the document, referring to sub-table id */
            const relocatedTable = document.createElement("table");
            const relocatedTableId = subTableNode.getAttribute("id").replace(/^t/, "r");
            relocatedTable.setAttribute("id", relocatedTableId);
            relocatedTable.setAttribute("class", "relocated-table");

            /** Move content of sub-table to the new table, and clean the content of the sub-table */
            this.htmlDocumentData.appendChild(relocatedTable);
            relocatedTable.innerHTML = subTableNode.innerHTML;
            subTableNode.innerHTML = "";
        }
    }
}

export default TransformDocument;