import React from 'react';

class SmartInput extends React.Component {

    state = {
        text: "",
    };

    constructor(props) {
        super(props);
        if (this.props.mask) {
            this.matcher = new RegExp(this.props.mask);
        }
        this.inputTimer = null;
        if (props.inputDelay) {
            this.inputDelay = parseInt(props.inputDelay);
        } else {
            this.inputDelay = 0;
        }

        if (this.props.value !== undefined)
        {
            this.state.text = this.props.value;
        }
    }

    onTimeout = () => {
        this.inputTimer = null;
        this.props.onChange(this.state.text);
    };

    onChange = (event) => {
        let newValue = event.target.value;
        if (this.matcher !== undefined) {
            if (!this.matcher.test(newValue))
            {
                // Mask is defined, but value didn't match:
                // not changing state.text
                return;
            }
        }
        this.setState({text: newValue});
        this.handleChange(newValue);
    };

    handleChange(newValue)
    {
        if (this.props.onChange)
        {
            if (this.inputDelay === 0)
            {
                this.props.onChange(newValue);
            }
            else
            {
                if (this.inputTimer)
                {
                    clearTimeout(this.inputTimer);
                }
                this.inputTimer = setTimeout(this.onTimeout, this.inputDelay);
            }
        }
    }

    render() {
        return <input name={this.props.name}
                      type={this.props.type}
                      value={this.state.text}
                      onChange={this.onChange}
                      placeholder={this.props.placeholder}
                      style={this.props.style}
        />;
    }
}

export default SmartInput;