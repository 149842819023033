export default class CoreRequest {

    /*
        ╔══════════════════════════════════════════════════════════════════════════════╗
        ║                           Public methods                                     ║
        ╚══════════════════════════════════════════════════════════════════════════════╝
    */

    /**
     * Constructor
     * @param url               ProTIS REST service URL. The default is US-based server. Use CoreConfig.ServiceURL for debugging.
     */
    constructor(url = "https://protis.us/protis/api") {
        this.url = url;
        CoreRequest.Instance = this;
    }

    /**
     * Minimal alert method
     * @param errorMessage
     * @param onAfterAlert
     */
    alert = (errorMessage, onAfterAlert) => {
        alert(errorMessage);

        if (onAfterAlert) {
            onAfterAlert();
        }
    };

    /**
     * Login to ProTIS
     * @param username          Username
     * @param password          Password
     * @returns {Promise<{}>}
     */
    async login(username, password) {
        let self = this;
        this.dbInfo.userName = username;
        this.dbInfo.userPassword = password;
        let result = {};
        this.userInfo = {};
        await this.send(false, "system/login", {},
            (jsonData) => {
                if (jsonData.result.success) {
                    this.userInfo = jsonData.user;
                    this.userInfo.jwt = jsonData.jwt;
                }
                result = jsonData.result;
                if (result.success === true) {
                    CoreRequest.ConnectedCore = self;
                }
            },
            null);
        return result;
    }

    /**
     * Logout from ProTIS
     *
     * Resets user authentication information
     */
    logout() {
        this.userInfo = {};
    }

    /**
     * Set Owner Database id for future requests
     *
     * @param ownerDatabaseId   Owner database id, integer
     */
    useOwnerDatabase(ownerDatabaseId) {
        this.dbInfo.ownerDatabaseId = ownerDatabaseId;
    }

    /**
     * Set Project id for future requests
     *
     * @param projectId   Owner project id, integer
     */
    setCurrentProjectId(projectId) {
        this.dbInfo.projectId = projectId;
    }

    getCurrentProjectId() {
        return this.dbInfo.projectId;
    }

    isLoggedIn() {
        if (this.dbInfo && !this.dbInfo.projectId) {
            this.alert(
                "Session terminated. To continue working, please log in again.",
                function () { /* end Session */
                },
            );
            return false;
        }
        return true;
    }

    /*
        ╔══════════════════════════════════════════════════════════════════════════════╗
        ║                       Internal variables and methods                         ║
        ╟──────────────────────────────────────────────────────────────────────────────╢
        ║  Do not access these variables or methods directly. Use supplied methods     ║
        ║  defined above.                                                              ║
        ╚══════════════════════════════════════════════════════════════════════════════╝
    */

    static isDateTimeRE = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)/;
    static isIntegerRE = /^[+-]?\d+$/;
    static isDoubleRE = /^[+-]?\d+(\.\d*)?(e[+-]?\d+)?$/i;
    static isBooleanRE = /^(true|false)$/i;

    static requestSerial = 1;

    userInfo = {
        jwt: '',
        first_name: '',
        last_name: '',
    };

    // Temporary simulating login to project
    dbInfo = {
        projectId: null, //53,
        ownerDatabaseId: 17,
        ownerId: 1,
        userId: 1,
        theme: "protis",
        dbName: "",
        languageCode: "en",
    };

    static requestLog = [];

    /**
     * Internal: Make a filter '=' condition out of field and value
     * @param field
     * @param value
     * @returns {{field: *, type: string, operation: string, value: string}}
     */
    makeCondition(field, value) {
        let type = "string";

        const fieldNameAndType = field.split(":");
        const fieldName = fieldNameAndType[0];
        if (fieldNameAndType.length > 1 && fieldNameAndType[1] === "text") {
            return {
                field: fieldName,
                operation: "=",
                value: "" + value,
                type: type,
            };
        }

        if (CoreRequest.isIntegerRE.test(value)) {
            value = parseInt(value);
            type = "int";
        } else if (CoreRequest.isDoubleRE.test(value)) {
            value = parseFloat(value);
            type = "double";
        } else if (CoreRequest.isDateTimeRE.test(value)) {
            value = Date.parse(value);
            type = "date";
        } else if (CoreRequest.isBooleanRE.test(value)) {
            value = value === "true";
            type = "boolean";
        }

        return {
            field: field,
            operation: "=",
            value: value,
            type: type.toLowerCase(),
        };
    }

    autoPopulateKnownLoginFields(fullRequest) {
        fullRequest["login"] = {};
        if (this.dbInfo.hasOwnProperty("userName") && this.dbInfo.hasOwnProperty("userPassword")) {
            fullRequest.login = {
                user: {
                    user_name: this.dbInfo.userName,
                    user_pass: this.dbInfo.userPassword,
                },
            };
        }

        if (this.dbInfo.hasOwnProperty("ownerDatabaseId")) {
            fullRequest.login["owner_database_id"] = this.dbInfo.ownerDatabaseId;
        }

        if (this.dbInfo.hasOwnProperty("projectId")) {
            fullRequest.login["project_id"] = this.dbInfo.projectId;
        }
    }

    /**
     * Internal: Make full JSON request ready to be sent
     * @param anonymous         Flag: if true then request is sent without user authentication
     * @param requestData       Request data AKA input parameters
     * @returns full request ready to be sent as JS object
     */
    makeFullRequest(anonymous, requestData) {
        let fullRequest = requestData ? requestData : {};

        let conditions = fullRequest["filter"];
        if (conditions) {
            let includeEmptyObject = conditions["include_empty_object"];
            if (includeEmptyObject != null)
                delete conditions["include_empty_object"];

            fullRequest["filter"] = {};
            fullRequest.filter["conditions"] = this.makeConditions(conditions);
            if (includeEmptyObject != null)
                fullRequest.filter["include_empty_object"] = includeEmptyObject;
        }

        if (!anonymous) {
            this.autoPopulateKnownLoginFields(fullRequest);
        }


        return fullRequest;
    }

    makeConditions(conditions) {
        let finalConditions = [];
        if (!Array.isArray(conditions)) {
            conditions = [conditions];
        }
        for (let condition of conditions) {
            for (const name in condition) {
                if (!condition.hasOwnProperty(name)) {
                    continue;
                }
                finalConditions.push(this.makeCondition(name, condition[name]));
            }
        }

        return finalConditions;
    }

    /**
     * Internal: Send request asynchronously
     * @param anonymous         Flag: if true then request is sent without user authentication
     * @param requestType       Request type, i.e. 'project/list'
     * @param requestData       Request data AKA input parameters
     * @param responseCallback  Optional response callback function
     * @param errorCallback     Optional error callback function
     * @returns {Promise<void>}
     */
    async send(anonymous, requestType, requestData, responseCallback, errorCallback) {
        let url = this.url + "/" + requestType;

        let requestDate = new Date();

        let fullRequest = this.makeFullRequest(anonymous, requestData);

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        };

        if (!anonymous && this.userInfo.hasOwnProperty('jwt') && this.userInfo.jwt !== '') {
            headers['Authorization'] = 'Bearer ' + this.userInfo.jwt;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(fullRequest),
        };

        let responseData = '';
        try {
            const response = await fetch(url + ".php", requestOptions);
            if (response.ok) {
                responseData = await response.json();
            } else {
                this.alert("ProTIS is Offline");
                if (errorCallback)
                    errorCallback("ProTIS is Offline");
            }
            this.processResponse(requestType, requestDate, requestData, responseData, responseCallback, errorCallback);
        } catch (error) {
            responseData.result = {success: false, return: error};
            if (responseCallback) {
                responseCallback(responseData);
            }
        }
    }

    /**
     * Internal: Process request results
     * @param requestType       Request type, i.e. 'project/list'
     * @param requestDate       Date and time request was sent
     * @param requestData       Request data AKA input parameters
     * @param responseData      Request response
     * @param responseCallback  Optional response callback function
     * @param errorCallback     Optional error callback function
     */
    processResponse = (requestType, requestDate, requestData, responseData, responseCallback, errorCallback) => {
        let requestDateStr = requestDate.toISOString().replace('T', ' ').replace(/Z.*/, "");

        let responseDate = new Date();
        let requestDuration = responseDate.getTime() - requestDate.getTime();
        let requestLogItem = {
            id: CoreRequest.requestSerial,
            timestamp: requestDateStr,
            requestType: requestType,
            request: JSON.stringify(requestData),
            response: JSON.stringify(responseData),
            duration: requestDuration,
        };

        CoreRequest.requestSerial++;
        CoreRequest.requestLog.push(requestLogItem);

        let resultNode;
        if (responseData.hasOwnProperty("result")) {
            resultNode = responseData["result"];
        } else {
            this.alert("Unexpected server response: " + JSON.stringify(responseData));
            return;
        }

        if (resultNode.success === false || resultNode.success === "false") {
            this.handleRequestError(resultNode, errorCallback, requestType);
            return;
        }

        if (responseData.hasOwnProperty("records")) {
            if (!Array.isArray(responseData.records)) {
                responseData.records = [responseData.records];
            }
        }

        if (responseCallback)
            responseCallback(responseData);
    };

    handleRequestError(resultNode, errorCallback, requestType) {
        let error = resultNode.return;
        if (error === "Session expired") {
            this.alert(
                error + ". To continue working, please log in again.",
                function () { /* end Session */
                },
            );
        } else {
            if (errorCallback) {
                errorCallback(error);
            } else {
                console.log("Request ", requestType, " error:", error);
            }
        }
    }

    static Instance;
}
