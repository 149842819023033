import React from 'react';
import GenericScreen from "../GenericScreen";
import * as constDefinitions from '../../LocalDefinitions/menuProject';


const role_data = constDefinitions.rolesView;


class RoleData extends GenericScreen {
    constructor(props) {
        super(props, role_data);

        this.state = {
            entityData: [],
            selectedRowId: "",
            selectedRowData: [],
            popupOpen: false,
            popupEditState: null,
        };

    }

    removeItemName() {
        return this.state.selectedRowData["role/list"].role_name;

    }

    screenSpecificSubmitInfo = () => {
        return {
            project_id: this.core.dbInfo.projectId,
        };
    };

    gridProperties() {
        return {
            headerHeight: "default",
            rowHeight: 30,
            gridHeight: '100%',
            gridWidth: "100%",
        };
    }
}

export default RoleData;
